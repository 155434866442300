<app-breadcrumb [title]="'Expenses'" active_item="Expenses"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div
                    class="p-2 d-flex justify-content-between flex-column flex-lg-row align-items-lg-center"
                >
                    <div
                        class="theme-form flex-column flex-md-row align-items-start align-items-md-stretch"
                        style="display: flex; height: 40px; gap: 10px"
                    >
                        <div class="input-group">
                            <div class="input-group-text">From</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="Start Date"
                                name="startDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #startDatePicker="ngbDatepicker"
                                (click)="startDatePicker.toggle()"
                                [(ngModel)]="startDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">To</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="End Date"
                                name="endDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #endDatePicker="ngbDatepicker"
                                (click)="endDatePicker.toggle()"
                                [(ngModel)]="endDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="d-grid d-md-block w-100">
                            <button
                                class="btn btn-primary-gradien h-100"
                                (click)="rerender()"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    <div class="expense-title d-flex mt-md-2 p-1 pt-2">
                        <h5>
                            Total Expense: {{ total[0]?.totalExpenses || 0 }}
                        </h5>
                    </div>
                    <div class="px-2 py-lg-2">
                        <button
                            class="btn btn-primary"
                            style="float: right"
                            (click)="openModal()"
                        >
                            Add Expense
                        </button>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Paid By</th>
                                        <th>Paid Via</th>
                                        <th>Remarks</th>
                                        <th>Receipt Id</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="statement?.length != 0">
                                    <tr *ngFor="let item of statement">
                                        <td>
                                            {{
                                                item?.date
                                                    | date : "d, MMM, y, EEEE"
                                            }}
                                        </td>
                                        <td>{{ item?.type }}</td>
                                        <td>{{ item?.paidBy }}</td>
                                        <td>{{ item?.paidVia }}</td>
                                        <td>{{ item?.remarks }}</td>
                                        <td>
                                            <span
                                                (click)="
                                                    showReceiptModal(item?.reciept?.driveId)
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 384 512"
                                                >
                                                    <path
                                                        d="M14 2.2C22.5-1.7 32.5-.3 39.6 5.8L80 40.4 120.4 5.8c9-7.7 22.3-7.7 31.2 0L192 40.4 232.4 5.8c9-7.7 22.3-7.7 31.2 0L304 40.4 344.4 5.8c7.1-6.1 17.1-7.5 25.6-3.6s14 12.4 14 21.8V488c0 9.4-5.5 17.9-14 21.8s-18.5 2.5-25.6-3.6L304 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L192 471.6l-40.4 34.6c-9 7.7-22.3 7.7-31.2 0L80 471.6 39.6 506.2c-7.1 6.1-17.1 7.5-25.6 3.6S0 497.4 0 488V24C0 14.6 5.5 6.1 14 2.2zM96 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96zM80 352c0 8.8 7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16zM96 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H288c8.8 0 16-7.2 16-16s-7.2-16-16-16H96z"
                                                    />
                                                </svg>
                                            </span>
                                        </td>
                                        <td class="text-danger fw-medium">
                                            {{ item?.amount }}
                                        </td>
                                        <td>
                                            <button
                                                class="btn btn-pill btn-primary-gradien"
                                                type="button"
                                                data-bs-original-title=""
                                                title=""
                                                (click)="editExpense(item)"
                                            >
                                                Edit
                                            </button>
                                        </td>
                                        <td
                                            *ngIf="
                                                currentUser.role === 'admin' ||
                                                currentUser.role === 'super' ||
                                                currentUser.role === 'manager'
                                            "
                                        >
                                            <button
                                                class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                                type="button"
                                                data-bs-original-title=""
                                                title=""
                                                (click)="
                                                    deleteExpense(item._id)
                                                "
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="statement?.length == 0">
                                    <tr>
                                        <td
                                            [colSpan]="
                                                dtOptions.columns?.length
                                            "
                                            class="no-data-available text-center"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #receipt>
    <div class="receipt-modal bg-white">
        <div class="heading d-flex align-items-center justify-content-between">
            <h3 class="m-0">Receipt</h3>
            <span class="fs-3" (click)="closeModal()">&times;</span>
        </div>

        <div class="receipt-img">
            <img
                [src]="currentReceipt"
                onerror="this.onerror=null; this.src='/assets/images/image-not-found.jpeg';"
                class="w-100"
                alt="receipt"
                loading="lazy"
            />
        </div>
    </div>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modal_title }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="expenseForm" (ngSubmit)="onSubmit()" class="row g-3">
            <div class="col-md-6">
                <div class="mb-3">
                    <label for="date" class="form-label">Date</label>
                    <input
                        class="form-control"
                        placeholder="date"
                        id="date"
                        name="date"
                        formControlName="date"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        (click)="d.toggle()"
                    />
                    <div
                        *ngIf="
                            expenseForm.get('date').invalid &&
                            expenseForm.get('date').touched
                        "
                        class="text-danger"
                    >
                        Date is required
                    </div>
                </div>

                <div class="mb-3">
                    <label for="type" class="form-label">Expense Type</label>
                    <select
                        class="form-select"
                        id="type"
                        formControlName="type"
                        required
                    >
                        <option value="">Select Expense Type</option>
                        <option value="FUEL">Fuel</option>
                        <option value="SALARY">Salary</option>
                        <option value="INTERNET">Internet</option>
                        <option value="VEHICLE_MAINTENANCE">
                            Vehicle Maintenance
                        </option>
                        <option value="OTHER">Other</option>
                    </select>
                    <div
                        *ngIf="
                            expenseForm.get('type').invalid &&
                            expenseForm.get('type').touched
                        "
                        class="text-danger"
                    >
                        Expense Type is required
                    </div>
                </div>

                <div class="mb-3">
                    <label for="remarks" class="form-label">Remarks</label>
                    <textarea
                        class="form-control"
                        id="remarks"
                        formControlName="remarks"
                        placeholder="Enter Remarks"
                        required
                    ></textarea>
                    <div
                        *ngIf="
                            expenseForm.get('remarks').invalid &&
                            expenseForm.get('remarks').touched
                        "
                        class="text-danger"
                    >
                        Remarks are required
                    </div>
                </div>

                <div class="mb-3">
                    <label for="amount" class="form-label">Amount</label>
                    <input
                        type="number"
                        class="form-control"
                        id="amount"
                        formControlName="amount"
                        placeholder="Enter Amount"
                        required
                    />
                    <div
                        *ngIf="
                            expenseForm.get('amount').invalid &&
                            expenseForm.get('amount').touched
                        "
                        class="text-danger"
                    >
                        Amount is required and must be a positive number
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="mb-3">
                    <label for="paidBy" class="form-label">Paid By</label>
                    <input
                        type="text"
                        class="form-control"
                        id="paidBy"
                        formControlName="paidBy"
                        placeholder="Enter Paid By"
                        required
                    />
                    <div
                        *ngIf="
                            expenseForm.get('paidBy').invalid &&
                            expenseForm.get('paidBy').touched
                        "
                        class="text-danger"
                    >
                        Paid By is required
                    </div>
                </div>

                <div class="mb-3">
                    <label for="receiptFile" class="form-label"
                        >Receipt ID</label
                    >
                    <input
                        type="file"
                        class="form-control"
                        id="receiptFile"
                        placeholder="Enter Receipt ID"
                        (change)="onReceiptFileChange($event)"
                        formControlName="receiptFile"
                    />
                    <input type="hidden" formControlName="receiptId" />
                    <div
                        *ngIf="
                            expenseForm.get('receiptFile').errors?.fileSize &&
                            expenseForm.get('receiptFile').touched
                        "
                        class="text-danger"
                    >
                        File size must not exceed 3MB
                    </div>
                </div>

                <div class="mb-3">
                    <label for="paidVia" class="form-label">Paid Via</label>
                    <input
                        type="text"
                        class="form-control"
                        id="paidVia"
                        formControlName="paidVia"
                        placeholder="Enter Paid Via"
                        required
                    />
                    <div
                        *ngIf="
                            expenseForm.get('paidVia').invalid &&
                            expenseForm.get('paidVia').touched
                        "
                        class="text-danger"
                    >
                        Paid Via is required
                    </div>
                </div>
            </div>

            <div class="col-12">
                <button
                    type="submit"
                    class="btn btn-primary d-flex gap-2 align-items-center"
                    [disabled]="expenseForm.invalid || expenseLoading"
                >
                    @if (expenseLoading) {
                    <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                    } @else {
                    <span>{{ modal_button }}</span>
                    }
                </button>
            </div>
        </form>
    </div>
</ng-template>
