import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {CONFIG} from "../../../../config";

@Injectable({
    providedIn: "root",
})
export class BackendService {
    favIcon: HTMLLinkElement = document.querySelector('#appIcon') as HTMLLinkElement;
    private selectedNumbers: { [key: string]: number } = {};

    constructor(private http: HttpClient) {
    }

    // Dashboard
    getAllStats(): Observable<any> {
        return this.http.post(CONFIG.getAllStats, {});
    }

    // Theme config
    addUpdateTheme(payload: any): Observable<any> {
        return this.http.post(CONFIG.addUpdateThemeConfig, payload);
    }

    getTheme(req: any): Observable<any> {
        return this.http.post(CONFIG.getThemeConfig, req);
    }


    login(params: any): Observable<any> {
        return this.http.post(CONFIG.login, params);
    }

    forgotPassword(params: any): Observable<any> {
        return this.http.post(CONFIG.forgotPassword, params);
    }

    verifyOtp(params: any): Observable<any> {
        return this.http.post(CONFIG.verifyOtp, params);
    }

    createUser(req: any): Observable<any> {
        return this.http.post(CONFIG.addClient, req);
    }

    addCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.addCategory, req);
    }

    allCategories(): Observable<any> {
        return this.http.get(CONFIG.allCategories);
    }

    updateCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.updateCategory, req);
    }

    // Clients APIs
    allClients(): Observable<any> {
        return this.http.get(CONFIG.clientList);
    }

    depositWithdrawClient(req: any): Observable<any> {
        return this.http.post(CONFIG.clientDepositWithdraw, req);
    }

    addCompanyProfile(req: any): Observable<any> {
        return this.http.post(CONFIG.addCompanyProfile, req);
    }

    getCompanyProfile(req: any): Observable<any> {
        return this.http.post(CONFIG.getCompanyProfile, req);
    }

    updateClient(req: any): Observable<any> {
        return this.http.patch(CONFIG.updateClient, req);
    }

    // Client Subscriptions
    updateClientSubscriptionStatus(req: any): Observable<any> {
        return this.http.post(CONFIG.updateClientSubscriptionStatus, req);
    }

    getClientSubscriptionsByCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.getClientSubscriptionsByCategory, req);
    }

    addClientSubscription(req: any): Observable<any> {
        return this.http.post(CONFIG.addClientSubscription, req);
    }

    getClientSubscriptions(req: any): Observable<any> {
        return this.http.post(CONFIG.getClientSubscriptions, req);
    }

    addBooking(req: any): Observable<any> {
        return this.http.post(CONFIG.addBooking, req);
    }

    getActivity(req: any): Observable<any> {
        return this.http.post(CONFIG.loginActivity, req);
    }

    // Expenses
    addExpense(req: any): Observable<any> {
        return this.http.post(CONFIG.addExpense, req);
    }

    getThemeConfig(req: any) {
        this.http.post(CONFIG.getThemeConfig, req).subscribe((data: any) => {
            if (data?.data?.length) {
                // Modify the driveId by concatenating with CONFIG.getFile and store it in the local storage
                data.data[0].logo.driveId = CONFIG.getFile + data.data[0]?.logo?.driveId;
                data.data[0].logoSmall.driveId = CONFIG.getFile + data.data[0]?.logoSmall?.driveId;
                data.data[0].favicon.driveId = CONFIG.getFile + data.data[0]?.favicon?.driveId;
                data.data[0].letterHeadPad.driveId = CONFIG.getFile + data.data[0]?.letterHeadPad?.driveId;

                // Set the favicon href using the concatenated URL
                this.favIcon.href = data.data[0].favicon.driveId;

                // Store the modified theme configuration object in localStorage
                localStorage.setItem('themeConfig', JSON.stringify(data.data[0]));
                localStorage.setItem('primary_color', data.data[0].config['themeColor']);

                // Apply the theme color to the document's CSS variables
                document.documentElement.style.setProperty('--theme-deafult', data.data[0].config['themeColor']);
            }
        });
    }


    updateExpense(req: any): Observable<any> {
        return this.http.patch(CONFIG.updateExpense, req);
    }

    deleteExpense(expenseId: any): Observable<any> {
        return this.http.delete(CONFIG.deleteExpense + "/" + expenseId);
    }

    // Employees
    updateEmployee(req: any): Observable<any> {
        return this.http.patch(CONFIG.updateEmployee, req);
    }

    addEmployee(req: any): Observable<any> {
        return this.http.post(CONFIG.addEmployee, req);
    }

    employeeList(req: any): Observable<any> {
        return this.http.post(CONFIG.employeeList, req);
    }

    allEmployeeListByCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.getEmployeeDataByCategory, req);
    }

    employeeDepositWithdraw(req: any): Observable<any> {
        return this.http.post(CONFIG.employeeDepositWithdraw, req);
    }

    // Attendance
    getAllAttendance(req: any): Observable<any> {
        return this.http.post(CONFIG.getAllAttendance, req);
    }

    // Subscriptions
    allSubscriptions(): Observable<any> {
        return this.http.get(CONFIG.allSubscriptions);
    }

    addSubscription(reqBody: any): Observable<any> {
        return this.http.post(CONFIG.addSubscription, reqBody);
    }

    deleteSubscription(subscriptionId: any): Observable<any> {
        return this.http.delete(
            CONFIG.deleteSubscription + "/" + subscriptionId
        );
    }

    subscriptionByCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.subscriptionByCategory, req);
    }

    deleteCategory(categoryId: string): Observable<any> {
        return this.http.delete(CONFIG.deleteCategory + "/" + categoryId);
    }

    updateBooking(req: any): Observable<any> {
        return this.http.post(CONFIG.updateBooking, req);
    }

    uploadFile(req: any): Observable<any> {
        return this.http.post(CONFIG.uploadFile, req);
    }

    getClientsData(req: any): Observable<any> {
        return this.http.post(CONFIG.getClientsData, req);
    }

    getEmployeeData(req: any): Observable<any> {
        return this.http.post(CONFIG.getEmployeeData, req);
    }

    getAllBookings(req: any): Observable<any> {
        return this.http.post(CONFIG.getAllBookings, req);
    }

    getAllBookingsByCategory(req: any): Observable<any> {
        return this.http.post(CONFIG.getAllBookingsByCategory, req);
    }

    getProfitLoss(req: any): Observable<any> {
        return this.http.post(CONFIG.getProfitLoss, req);
    }

    // Cashflow APIs
    addCashFlow(req: any): Observable<any> {
        return this.http.post(CONFIG.addCashFlow, req);
    }

    getCashFlow(req: any): Observable<any> {
        return this.http.post(CONFIG.getCashFlow, req);
    }

    // Quotations
    addQuotation(req: any): Observable<any> {
        return this.http.post(CONFIG.addQuotation, req);
    }

    getQuotationById(req: any): Observable<any> {
        return this.http.post(CONFIG.getQuotationById, req);
    }

    updateQuotation(req: any): Observable<any> {
        return this.http.post(CONFIG.updateQuotation, req);
    }
}
