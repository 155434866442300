<app-breadcrumb
    [active_item]="'Employees'"
    [items]="['Settings']"
    [title]="'Employees'"
></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <button
                        (click)="openModal()"
                        class="btn btn-primary"
                        style="float: right"
                    >
                        Add Employee
                    </button>
                    <div *ngIf="selectedUserId">
                        <button
                            (click)="onNameClick(selectedUserId)"
                            class="btn btn-secondary"
                            style="
                                float: right;
                                margin-right: 4px;
                                cursor: pointer;
                            "
                        >
                            Reset
                        </button>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                                datatable
                                id="example"
                            >
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th class="suspense">Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Flat/Address</th>
                                    <th>Category</th>
                                    <th>Salary</th>
                                    <th class="actions">Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="persons?.length > 0">
                                <tr
                                    *ngFor="
                                            let person of users;
                                            let i = index
                                        "
                                >
                                    <td>{{ i + 1 }}</td>
                                    <td class="suspense">
                                        <button
                                            (click)="
                                                    onNameClick(person._id)
                                                "
                                            [disabled]="isActive"
                                            class="custom-btn-title"
                                        >
                                            {{ person?.name ?? "-" }}
                                        </button>
                                    </td>
                                    <td>{{ person?.email ?? "-" }}</td>
                                    <td>
                                        {{ person?.phoneNumber ?? "-" }}
                                    </td>
                                    <td>{{ person?.address ?? "-" }}</td>
                                    <td>
                                        {{
                                            person?.category
                                                ?.categoryName ?? "-"
                                        }}
                                    </td>
                                    <td>
                                        {{ person?.totalSalary ?? "-" }}
                                    </td>

                                    <td class="d-flex gap-1 actions">
                                        <button
                                            (click)="
                                                    openAccountModal(
                                                        person._id,
                                                        person.name
                                                    )
                                                "
                                            class="btn btn-pill btn-primary-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Account History
                                        </button>
                                        <button
                                            (click)="
                                                    openBookingModal(
                                                        person._id,
                                                        person.name
                                                    )
                                                "
                                            class="btn btn-pill btn-secondary-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Booking History
                                        </button>
                                        <button
                                            (click)="openDeposit(person)"
                                            class="btn btn-pill btn-info-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Deposit/Withdraw
                                        </button>
                                        <button
                                            (click)="openChangePass(person)"
                                            class="btn btn-pill btn-warning-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            <i
                                                class="icofont icofont-ui-password"
                                            ></i>
                                        </button>
                                        <div
                                            class="media-body text-end icon-state"
                                        >
                                            <label class="switch">
                                                <input
                                                    (change)="
                                                            updateStatus(
                                                                $event,
                                                                person
                                                            )
                                                        "
                                                    [checked]="
                                                            person.status ===
                                                            'active'
                                                                ? true
                                                                : false
                                                        "
                                                    data-bs-original-title=""
                                                    id="{{ i }}"
                                                    name="{{ i }}"
                                                    title=""
                                                    type="checkbox"
                                                /><span
                                                class="switch-state"
                                            ></span>
                                            </label>
                                        </div>

                                        <button
                                            (click)="
                                                    openEditEmployeeModal(
                                                        person
                                                    )
                                                "
                                            class="btn btn-pill btn-primary-gradien"
                                            data-bs-original-title=""
                                            title=""
                                            type="button"
                                        >
                                            Edit
                                        </button>

                                        <button (click)="navigateToAdminSettings(person._id)"
                                                *ngIf="currentUser === 'super' && !isActive"
                                                class="btn btn-pill btn-primary-gradien" type="button">
                                            Admin Themes
                                        </button>

                                    </td>
                                </tr>
                                <tr>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>{{ total?.totalSalary }}</td>
                                    <td>-</td>
                                </tr>
                                </tbody>
                                <tbody *ngIf="users?.length == 0">
                                <tr>
                                    <td
                                        [colSpan]="
                                                dtOptions?.columns.length + 2
                                            "
                                        class="no-data-available"
                                    >
                                        No data!
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modal_title }}</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="onSubmit()" [formGroup]="employeeForm">
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="name">Name</label>
                    <input
                        class="form-control"
                        formControlName="name"
                        id="name"
                        placeholder="Enter Name"
                        required
                        type="text"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('name').invalid &&
                            employeeForm.get('name').touched
                        "
                        class="text-danger"
                    >
                        Name is required
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="email">Email</label>
                    <input
                        class="form-control"
                        formControlName="email"
                        id="email"
                        placeholder="Enter Email"
                        required
                        type="email"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('email').invalid &&
                            employeeForm.get('email').touched
                        "
                        class="text-danger"
                    >
                        Valid Email is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="password">Password</label>
                    <input
                        class="form-control"
                        formControlName="password"
                        id="password"
                        placeholder="Enter Password"
                        required
                        type="password"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('password').invalid &&
                            employeeForm.get('password').touched
                        "
                        class="text-danger"
                    >
                        Password is required and must be at least 8 characters
                        long
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="totalSalary"
                    >Total Salary</label
                    >
                    <input
                        class="form-control"
                        formControlName="totalSalary"
                        id="totalSalary"
                        placeholder="Enter Total Salary"
                        required
                        type="number"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('totalSalary').invalid &&
                            employeeForm.get('totalSalary').touched
                        "
                        class="text-danger"
                    >
                        Total Salary is required and must be a positive number
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="emergencyContact"
                    >Emergency Contact</label
                    >
                    <input
                        class="form-control"
                        formControlName="emergencyContact"
                        id="emergencyContact"
                        placeholder="Enter Emergency Contact"
                        required
                        type="text"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('emergencyContact').invalid &&
                            employeeForm.get('emergencyContact').touched
                        "
                        class="text-danger"
                    >
                        Emergency Contact is required
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="role">Role</label>
                    <select
                        class="form-select"
                        formControlName="role"
                        id="role"
                        required
                    >
                        <option value="">Select Role</option>
                        <option *ngIf="currentUser === 'super'" value="ADMIN">
                            Admin
                        </option>
                        <ng-container *ngIf="currentUser === 'admin'">
                            <option value="MANAGER">Manager</option>
                            <option value="EMPLOYEE">Employee</option>
                            <option value="DRIVER">Driver</option>
                        </ng-container>
                    </select>
                    <div
                        *ngIf="
                            employeeForm.get('role').invalid &&
                            employeeForm.get('role').touched
                        "
                        class="text-danger"
                    >
                        Role is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="phoneNumber"
                    >Phone Number</label
                    >
                    <input
                        class="form-control"
                        formControlName="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter Phone Number"
                        required
                        type="text"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('phoneNumber').invalid &&
                            employeeForm.get('phoneNumber').touched
                        "
                        class="text-danger"
                    >
                        Valid Phone Number is required
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="address">Address</label>
                    <textarea
                        class="form-control"
                        formControlName="address"
                        id="address"
                        placeholder="Enter Address"
                        required
                        rows="3"
                    ></textarea>
                    <div
                        *ngIf="
                            employeeForm.get('address').invalid &&
                            employeeForm.get('address').touched
                        "
                        class="text-danger"
                    >
                        Address is required
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3">
                    <div class="form-group">
                        <label for="categoryId">Category</label>
                        <select
                            class="form-select"
                            formControlName="categoryId"
                            id="category"
                            required
                        >
                            <option
                                *ngFor="let cat of categories"
                                [value]="cat._id"
                            >
                                {{ cat?.categoryName }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="mb-3 col-md-6">
                    <label class="form-label" for="emiratesID"
                    >Emirates ID</label
                    >
                    <input
                        class="form-control"
                        formControlName="emiratesID"
                        id="emiratesID"
                        pattern="^\d{3}-\d{4}-\d{7}-\d{1}$"
                        placeholder="Enter Emirates ID"
                        required
                        type="text"
                    />
                    <div
                        *ngIf="
                            employeeForm.get('emiratesID').invalid &&
                            employeeForm.get('emiratesID').touched
                        "
                        class="text-danger"
                    >
                        Valid Emirates ID is required (Format:
                        123-1234-1234567-1)
                    </div>
                </div>
            </div>
            <button
                [disabled]="reqProcessing"
                class="btn btn-primary d-flex gap-2 align-items-center"
                type="submit"
            >
                @if (reqProcessing) {
                    <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                } @else {
                    <span>{{ modal_button }}</span>
                }
            </button>
        </form>
    </div>
</ng-template>

<ng-template #bookingHistoryContent let-modal>
    <app-employee-booking-history></app-employee-booking-history>
</ng-template>

<ng-template #accountHistoryContent let-modal>
    <app-employee-account-history></app-employee-account-history>
</ng-template>

<ng-template #depositContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Deposit - Withdraw</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <div
            class="client-info mb-1 d-flex justify-content-between align-items-center"
        >
            <div>
                <span class="d-inline-block me-2 px-2 py-1 bg-success rounded"
                >User</span
                >
                <span class="fw-bold">
                    {{ currentEmployee?.name }}
                </span>
            </div>
            <div>
                <span>Client Bal: </span>
                <span class="fw-bold">
                    {{ currentEmployee?.walletBallance }}
                </span>
            </div>
        </div>

        <form [formGroup]="transactionForm">
            <div class="mb-3">
                <label class="form-label" for="amount">Amount</label>
                <input
                    (click)="selectAmount($event)"
                    class="form-control"
                    formControlName="amount"
                    id="amount"
                    placeholder="Enter amount"
                    type="number"
                />
                <div
                    *ngIf="
                        transactionForm.get('amount').errors?.required &&
                        transactionForm.get('amount').touched
                    "
                    class="text-danger"
                >
                    Amount is required
                </div>
                <div
                    *ngIf="
                        transactionForm.get('amount').errors?.min &&
                        transactionForm.get('amount').touched
                    "
                    class="text-danger"
                >
                    Amount should be more than 0
                </div>
            </div>

            <div class="mb-3">
                <label class="form-label" for="remarks">Remarks</label>
                <input
                    class="form-control"
                    formControlName="remarks"
                    id="remarks"
                    placeholder="Enter remarks"
                    type="text"
                />
            </div>

            <div class="modal-footer d-flex gap-2">
                <button
                    (click)="onSubmitDw('D')"
                    [disabled]="transactionForm.invalid || reqProcessing"
                    class="btn btn-success d-flex gap-2 align-items-center"
                    type="button"
                >
                    @if (reqProcessing) {
                        <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                    } @else {
                        <span>Deposit</span>
                    }
                </button>
                <button
                    (click)="onSubmitDw('W')"
                    [disabled]="transactionForm.invalid || reqProcessing"
                    class="btn btn-danger d-flex gap-2 align-items-center"
                    type="button"
                >
                    @if (reqProcessing) {
                        <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                    } @else {
                        <span>Withdraw</span>
                    }
                </button>
            </div>
        </form>
    </div>
</ng-template>

<ng-template #passwordChangeContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
            Change Password - {{ currentEmployee?.name }}
        </h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="passwordChangeForm">
            <div class="mb-3">
                <label class="form-label" for="password">New Password</label>
                <input
                    class="form-control"
                    formControlName="newPassword"
                    id="password"
                    placeholder="Enter password"
                    type="password"
                />
                <div
                    *ngIf="
                        passwordChangeForm.get('newPassword').errors
                            ?.required &&
                        passwordChangeForm.get('newPassword').touched
                    "
                    class="text-danger"
                >
                    Password is required
                </div>
                <div
                    *ngIf="
                        passwordChangeForm.get('newPassword').errors
                            ?.minlength &&
                        passwordChangeForm.get('newPassword').dirty
                    "
                    class="text-danger"
                >
                    Password should be at least 8 characters long
                </div>
            </div>

            <div class="modal-footer d-flex gap-2">
                <button
                    (click)="onSubmitCp()"
                    [disabled]="passwordChangeForm.invalid || reqProcessing"
                    class="btn btn-primary d-flex gap-2 align-items-center"
                    type="button"
                >
                    @if (reqProcessing) {
                        <span
                            class="spinner-border spinner-border-sm"
                            aria-hidden="true"
                        ></span>
                        <span role="status">Loading...</span>
                    } @else {
                        <span>Change Password</span>
                    }
                </button>
            </div>
        </form>
    </div>
</ng-template>
