import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BackendService} from "../../shared/services/backend.service";

@Component({
    selector: 'app-company-profile',
    templateUrl: './company-profile.component.html',
    styleUrls: ['./company-profile.component.scss']
})
export class CompanyProfileComponent implements OnInit {
    companyProfileForm: FormGroup;
    companyProfileData: any;

    constructor(private fb: FormBuilder, private backend: BackendService) {
        this.companyProfileForm = this.fb.group({
            companyName: ['', [Validators.required, Validators.minLength(3)]],
            phoneNumber: ['', [Validators.required]],
            whatsappNumber: ['', [Validators.required]],
            address: ['', [Validators.required, Validators.minLength(10)]],
            POBox: ['', [Validators.required, Validators.pattern(/^[0-9]{1,6}$/)]],
            companyEmail: ['', [Validators.required, Validators.email]],
            taxNumber: ['', [Validators.required, Validators.pattern(/^[0-9]{1,15}$/)]] // Added Tax Number with validation
        });
    }

    // Form control getters
    get companyName() {
        return this.companyProfileForm.get('companyName');
    }

    get phoneNumber() {
        return this.companyProfileForm.get('phoneNumber');
    }

    get whatsappNumber() {
        return this.companyProfileForm.get('whatsappNumber');
    }

    get address() {
        return this.companyProfileForm.get('address');
    }

    get POBox() {
        return this.companyProfileForm.get('POBox');
    }

    get companyEmail() {
        return this.companyProfileForm.get('companyEmail');
    }

    get taxNumber() {
        return this.companyProfileForm.get('taxNumber');
    }

    ngOnInit() {
        this.getCompanyProfile();
    }

    // Handling form submission and sending the data
    onSubmit() {
        if (this.companyProfileForm.valid) {
            console.log('Form Submitted!', this.companyProfileForm.value);

            // Sending form data, including taxNumber
            this.backend.addCompanyProfile(this.companyProfileForm.value).subscribe({
                next: (response) => {
                    console.log('Company profile updated successfully', response);
                },
                error: (error) => {
                    console.error('Error updating company profile', error);
                }
            });
        } else {
            console.log('Form is invalid');
        }
    }

    // Fetching company profile data and patching it into the form
    getCompanyProfile() {
        this.backend.getCompanyProfile('').subscribe({
            next: (response) => {
                this.companyProfileForm.patchValue({
                    companyName: response.data.companyName,
                    phoneNumber: response.data.phoneNumber,
                    whatsappNumber: response.data.whatsappNumber,
                    address: response.data.address,
                    POBox: response.data.POBox,
                    companyEmail: response.data.companyEmail,
                    taxNumber: response.data.taxNumber
                });
            },
            error: (error) => {
                console.error('Error fetching company profile', error);
            }
        });
    }
}
