<app-breadcrumb active_item="Settings" title="Settings"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card p-4">
                <!-- File Uploads -->
                <div class="row mb-3">
                    <ng-container
                        *ngFor="let fileType of ['logo', 'logoSmall', 'favicon', 'letterHeadPad']; let i = index">
                        <div class="col-md-6 col-sm-12 mb-3">
                            <label [for]="fileType + 'Upload'"
                                   class="form-label">Upload {{ getFileLabel(fileType) }}</label>
                            <div class="custom-file-upload">
                                <input (change)="onFileSelected($event, fileType)" [id]="fileType + 'Upload'"
                                       class="file-input" type="file"/>
                                <span *ngIf="!details[fileType].fileName">Choose File</span>
                                <span *ngIf="details[fileType].fileName">{{ details[fileType].fileName }}</span>
                                <button class="upload-btn">Browse</button>
                            </div>
                            <div *ngIf="details[fileType].url" class="mt-2">
                                <img [alt]="getFileLabel(fileType)" [src]="details[fileType].url" height="100"
                                     style="object-fit: contain" width="100px">
                            </div>
                        </div>
                    </ng-container>
                </div>

                <!-- Theme Color Selection -->
                <div class="row mb-3">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <label class="form-label" for="primaryColor">Main Color</label>
                        <input [(ngModel)]="themeColors.primaryColor"
                               class="form-control form-control-sm custom-color-picker"
                               id="primaryColor" type="color"/>
                    </div>
                </div>

                <!-- Save Settings Button -->
                <div class="row">
                    <div class="col-12 text-end">
                        <button (click)="saveSettings()" class="btn btn-primary">Save Settings</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
