import {ChangePasswordComponent} from "./auth/change-password/change-password.component";
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./auth/login/login.component";
import {ContentComponent} from "./shared/components/layout/content/content.component";
import {content} from "./shared/routes/routes";
import {AuthGuard} from "./shared/services/auth.guard";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";

const routes: Routes = [
    // {
    //   path: '',
    //   redirectTo: 'dashboard/ecommerce',
    //   pathMatch: 'full'
    // },
    {
        path: "",
        redirectTo: "simple-page/first-page",
        pathMatch: "full",
    },
    {
        path: "bookings",
        redirectTo: "single-page",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "listView",
        redirectTo: "listView",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "employees",
        redirectTo: "employees",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "clients",
        redirectTo: "clients",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "profit-loss",
        redirectTo: "profit-loss",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "subscriptions",
        redirectTo: "subscriptions",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "expenses",
        redirectTo: "expenses",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "reports",
        redirectTo: "reports",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "category",
        redirectTo: "category",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "activity-Logs",
        redirectTo: "activity-Logs",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: 'settings',
        redirectTo: "settings",
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: 'settings/:adminId',
        redirectTo: "settings/:adminId",
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    {
        path: "auth/login",
        component: LoginComponent,
    },
    {
        path: "auth/forgot-password",
        component: ForgotPasswordComponent,
    },

    {
        path: "auth/change-password",
        component: ChangePasswordComponent,
    },
    {
        path: "cashflow",
        redirectTo: "cashflow",
        pathMatch: "full",
        canActivate: [AuthGuard],
    },
    {
        path: "",
        component: ContentComponent,
        children: content,
        canActivate: [AuthGuard],
    },
    {
        path: "**",
        redirectTo: "",
    },
];

@NgModule({
    imports: [
        [
            RouterModule.forRoot(routes, {
                anchorScrolling: "enabled",
                scrollPositionRestoration: "enabled",
            }),
        ],
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
