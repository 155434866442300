<div class="animated fadeIn divider-top">
    <div class="card card-accent-primary">
      <div class="game-heading">
        <span class="card-header-title">Activity Log</span>
      </div>
      <div class="card-body account-detail-body table-responsive">
        <table id="example" class="display nowrap table table-striped table-bordered" style="width: 100%;">
          <thead>
            <tr role="row">
              <th role="columnheader" scope="col" aria-colindex="1" class="text-center">Login&nbsp;Date &amp; Time</th>
              <th role="columnheader" scope="col" aria-colindex="2" class="text-center">Login Status </th>
              <th role="columnheader" scope="col" aria-colindex="3" class="text-center">IP Address </th>
              <th role="columnheader" scope="col" aria-colindex="4" class="text-center">ISP </th>
              <th role="columnheader" scope="col" aria-colindex="5" class="text-center"> City/State/Country</th>
            </tr>
          </thead>
          <tbody class="table-responsive">
            <tr *ngFor="let item of data">
              <td class="text-center" >{{item?.createdAt | date:'medium'}} </td>
              <td class="text-center">
                <span >
                  <b>{{item?.status}}</b>
                </span>
              </td>
              <td class="text-center">{{item?.ipInfo?.ip}}</td>
              <td class="text-center">{{item?.ipInfo?.org}}</td>
              <td class="text-center">
                <span *ngIf="item.ipInfo?.city">{{item.ipInfo?.city}}</span>
                <span *ngIf="item.ipInfo?.region">/{{item.ipInfo?.region}}</span>
                <span *ngIf="item.ipInfo?.country">/{{item.ipInfo?.country}}</span>

              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
