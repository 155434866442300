<app-breadcrumb
    [title]="'Profit & Loss'"
    active_item="Profit & Loss"
></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <div
                        class="theme-form flex-column flex-md-row align-items-start align-items-md-stretch"
                        style="display: flex; gap: 10px"
                    >
                        <div class="input-group">
                            <div class="input-group-text">From</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="Start Date"
                                name="startDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #startDatePicker="ngbDatepicker"
                                (click)="startDatePicker.toggle()"
                                [(ngModel)]="startDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">To</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="End Date"
                                name="endDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #endDatePicker="ngbDatepicker"
                                (click)="endDatePicker.toggle()"
                                [(ngModel)]="endDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="d-grid d-md-block w-100">
                            <button
                                class="btn btn-primary-gradien h-100"
                                (click)="rerender()"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <tr class="center">
                                        <th>Date</th>
                                        <th>Expense</th>
                                        <th>Sale</th>
                                        <th>Profit/Loss</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="statement?.length > 0">
                                    <tr *ngFor="let item of statement">
                                        <td>
                                            {{ item?.date | date : "d, MMM, y, EEEE" }}
                                        </td>
                                        <td class="text-danger">
                                            {{
                                                item?.expenses
                                                    | number : "1.0-2"
                                            }}
                                        </td>
                                        <td class="text-success">
                                            {{ item?.sales | number : "1.0-2" }}
                                        </td>
                                        <td
                                            [ngClass]="
                                                item?.totalPl > 0
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            "
                                        >
                                            {{
                                                item?.totalPl | number : "1.0-2"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                item?.updatedAt
                                                    | date
                                                        : "EEEE, MMMM d, y, h:mm a"
                                            }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="totalPl">-</td>
                                        <td class="text-danger">
                                            {{
                                                totalPl.totalExpenses
                                                    | number : "1.0-2"
                                            }}
                                        </td>
                                        <td class="text-success">
                                            {{
                                                totalPl.totalSales
                                                    | number : "1.0-2"
                                            }}
                                        </td>
                                        <td
                                            [ngClass]="
                                                totalPl?.totalPl > 0
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            "
                                        >
                                            {{
                                                totalPl.totalPl
                                                    | number : "1.0-2"
                                            }}
                                        </td>
                                        <td>-</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="statement?.length == 0">
                                    <tr>
                                        <td
                                            colspan="5"
                                            class="no-data-available"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
