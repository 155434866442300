import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { NgbDateParserFormatter, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
// // for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
// // for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
// // for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";

import { CookieService } from "ngx-cookie-service";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./auth/login/login.component";

import { OverlayModule } from "@angular/cdk/overlay";
import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { NgbDateCustomParserFormatter } from "./shared/services/date-format.service";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CompanyProfileComponent } from "./components/company-profile/company-profile.component";
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { LoginActivityComponent } from './components/login-activity/login-activity.component';
import { SettingsComponent } from './components/settings/settings.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ChangePasswordComponent,
        CompanyProfileComponent,
        ForgotPasswordComponent,
        LoginActivityComponent,
        SettingsComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        OverlayModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgMultiSelectDropDownModule.forRoot(),

        // for HttpClient use:
        LoadingBarHttpClientModule,
        // for Router use:
        LoadingBarRouterModule,
        // for Core use:
        LoadingBarModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    providers: [
        DatePipe,
        CookieService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateCustomParserFormatter,
        },
        CurrencyPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
