import {
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
} from "@angular/core";

@Component({
    selector: "app-view-quotation",
    templateUrl: "./view-quotation.component.html",
    styleUrl: "./view-quotation.component.scss",
})
export class ViewQuotationComponent {
    @Input() pdfDataUri: any;

    @ViewChild("quotation") quotation: any;
    @ViewChild("modal") modal: any;

    closeQuotation() {
        this.modal.nativeElement.classList.remove("active");
    }

    @HostListener("document:click", ["$event"])
    clickOut(event: any) {
        if (
            !this.modal.nativeElement.contains(event.target) &&
            this.modal.nativeElement.classList.contains("active")
        ) {
            this.closeQuotation();
            console.log("click outside");
        }
    }

    ngOnChanges() {
        if (!this.quotation?.nativeElement) return;
        this.quotation.nativeElement.src = this.pdfDataUri;
    }
}
