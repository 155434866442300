import {Component, ViewChild} from "@angular/core";
import {NgbDate, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {DataSharingService} from "src/app/shared/services/data-sharing.service";
import {HttpClient} from "@angular/common/http";
import {SharedModule} from "src/app/shared/shared.module";
import {DatePipe, DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {CONFIG} from "config";
import {DateUtils} from "src/app/utils/date-utils";
import {UtilsService} from "src/app/shared/services/utils.service";
import jsPDF from "jspdf";
import {NgModel} from "@angular/forms";
import {BackendService} from "src/app/shared/services/backend.service";
import Swal from "sweetalert2";

interface EditForm {
    bookingId: string;
    bookingStatus: NgModel;
    paymentStatus: NgModel;
    ratePerHour: NgModel;
}

@Component({
    selector: "app-client-booking-history",
    standalone: true,
    imports: [
        SharedModule,
        DataTablesModule,
        NgIf,
        DatePipe,
        NgForOf,
        DecimalPipe,
    ],
    templateUrl: "./client-booking-history.component.html",
    styleUrl: "./client-booking-history.component.scss",
})
export class ClientBookingHistoryComponent {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    startDate: NgbDate;
    endDate: NgbDate;
    formattedStartDate: any;
    formattedEndDate: any;
    dataTableParam: any;
    displayMonths: any;
    outsideDays = "visible";
    showWeekNumbers = true;
    client_name: string = "";

    navigation = "select";
    persons: any[] = [
        {id: 1, firstName: "John", lastName: "Doe", action: "deposit"},
        {id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw"},
        {id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle"},
        {id: 4, firstName: "Bob", lastName: "Brown", action: "delete"},
    ];
    users: any[] = [];
    totals: any;

    // Logged in user
    currentUserRole: string;

    // Update Form
    editForm: EditForm;
    defaultEditValues: any;
    reqProcessing: boolean = false;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        private dataSharingService: DataSharingService,
        private utils: UtilsService,
        private datePipe: DatePipe,
        private backend: BackendService
    ) {
    }

    ngOnInit() {
        this.currentUserRole = localStorage.getItem("role").toLowerCase();

        const bookingId = this.dataSharingService.getBookingId();
        this.client_name = this.dataSharingService.getName();

        const currentDate = new Date();
        this.endDate = DateUtils.createNgbDate(currentDate);

        // Set filter's end date 7 days ahead of start date
        currentDate.setDate(currentDate.getDate() - 14);
        this.startDate = DateUtils.createNgbDate(currentDate);

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;

        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 1000,
            lengthChange: true,
            serverSide: true,
            searching: false,
            autoWidth: true,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                const requestBody = {
                    userId: bookingId,
                    startDate: this.formattedStartDate,
                    endDate: this.formattedEndDate,
                    ...dataTablesParameters,
                };
                that.http
                    .post<any>(CONFIG.clientBookingHistory, requestBody)
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        that.totals = resp.data?.total;
                        if (resp.data) {
                            that.users = data.map((booking) => {
                                return {
                                    ...booking,
                                    editable: false,
                                };
                            });
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                {data: "startTime"},
                {data: "name"},
                {data: "flag"},
                {data: "paymentStatus"},
                {data: "hours"},
                {data: "startTime"},
                {data: "endTime"},
                {data: "ratePerHour"},
                {data: "material"},
                {data: "totalAmount"},
                {data: "description"},
            ],
        };
    }

    // Edit the bookings
    editBooking(bookingFields: EditForm) {
        this.editForm = bookingFields;

        this.setDefaultValues(bookingFields);
    }

    cancelEditing() {
        this.resetValue();

        this.editForm = undefined;
    }

    saveBooking() {
        const {bookingId, bookingStatus, paymentStatus, ratePerHour} =
            this.editForm;

        const reqBody = {
            bookingId: bookingId,
            bookingStatus: bookingStatus.dirty
                ? bookingStatus.value
                : undefined,
            paymentStatus: paymentStatus.dirty
                ? paymentStatus.value
                : undefined,
            ratePerHour: ratePerHour.dirty ? ratePerHour.value : undefined,
        };

        if (
            reqBody.bookingStatus ||
            reqBody.paymentStatus ||
            reqBody.ratePerHour
        ) {
            this.reqProcessing = true;

            this.backend.updateBooking(reqBody).subscribe({
                next: (response) => {
                    this.cancelEditing();

                    Swal.fire({
                        title: "Booking updated successfully!",
                        icon: "success",
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                    this.rerender();
                    this.reqProcessing = false;
                },
                error: (error) => {
                    Swal.fire({
                        title: "There was an error updating the booking",
                        icon: "error",
                        position: "top-end",
                        showConfirmButton: false,
                    });
                    this.reqProcessing = false;
                },
            });
        }
    }

    setDefaultValues({bookingStatus, paymentStatus, ratePerHour}: EditForm) {
        this.defaultEditValues = {
            bookingStatus: bookingStatus.value,
            paymentStatus: paymentStatus.value,
            ratePerHour: ratePerHour.value,
        };
    }

    resetValue() {
        const {bookingStatus, paymentStatus, ratePerHour} = this.editForm;

        bookingStatus.reset(this.defaultEditValues.bookingStatus);
        paymentStatus.reset(this.defaultEditValues.paymentStatus);
        ratePerHour.reset(this.defaultEditValues.ratePerHour);
    }

    // To formate numeric data in table
    formatNumber(value: any): string {
        // Check if the value is numeric
        if (!isNaN(value)) {
            // Format the number without decimal places
            return Math.floor(value).toString();
        } else {
            // Return the original value if it's not numeric
            return value;
        }
    }

    onStartDateChange(newStartDate: NgbDate) {
        this.startDate = newStartDate;
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
    }

    onEndDateChange(newEndDate: NgbDate) {
        this.endDate = newEndDate;
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }


    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    generatePdf() {
        const bookingId = this.dataSharingService.getBookingId();

        const doc = new jsPDF() as any;

        const options = {
            draw: 1,
            columns: [
                {data: "startTime"},
                {data: "name"},
                {data: "flag"},
                {data: "paymentStatus"},
                {data: "hours"},
                {data: "startTime"},
                {data: "endTime"},
                {data: "ratePerHour"},
                {data: "material"},
                {data: "totalAmount"},
                {data: "description"},
            ],
            order: [
                {
                    column: 0,
                    dir: "asc",
                },
            ],
            start: 0,
            length: 1000,
            search: {
                value: "",
                regex: false,
            },
            userId: bookingId,
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
        };

        this.http.post(CONFIG.clientBookingHistory, options).subscribe({
            next: async (response: any) => {
                const extractedData = response.data.original.map(
                    ({
                         assignee,
                         flag,
                         paymentStatus,
                         hours,
                         startTime,
                         endTime,
                         material,
                         totalAmount,
                     }) => {
                        const formattedStartTime = new Date(startTime);
                        const formattedEndTime = new Date(endTime);

                        return {
                            Date: this.formatDate(formattedStartTime),
                            Assignee: assignee.name,
                            Status: flag,
                            PaymentStatus: paymentStatus,
                            Hours: hours,
                            StartTime:
                                this.formatDateToTime(formattedStartTime),
                            EndTime: this.formatDateToTime(formattedEndTime),
                            Material: material ? "Yes" : "No",
                            "T.Amount": totalAmount,
                        };
                    }
                );

                doc.setFontSize(20);
                doc.setTextColor(113, 32, 218);

                const imgData = await fetch(
                    "/assets/images/logo/site-logo-icon.png"
                ).then((response) => response.blob());

                const img = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (event) => resolve(event.target.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(imgData);
                });

                doc.addImage(img, "PNG", 55, 9, 15, 13);

                doc.text("HOME BRIGADIER", 75, 20);

                doc.setTextColor(0, 0, 0);
                doc.setFontSize(12);
                doc.text("Client Name: ", 15, 35);

                doc.setTextColor(113, 32, 218);
                doc.text(this.client_name, 45, 35);
                doc.setTextColor(0, 0, 0);

                const subtitle = `Start Date: ${this.formatDate(
                    this.formattedStartDate
                )} | End Date: ${this.formatDate(
                    this.formattedEndDate
                )} | Total Hours: ${
                    response?.data?.total?.totalHours
                } | Amount: ${response?.data?.total?.totalAmount}`;
                doc.text(subtitle, 15, 40);

                doc.autoTable({
                    head: [
                        [
                            "Date",
                            "Assignee",
                            "Status",
                            "PaymentStatus",
                            "Hours",
                            "StartTime",
                            "EndTime",
                            "Material",
                            "T.Amount",
                        ],
                    ],
                    body: extractedData.map(Object.values),
                    startY: 45,
                    headStyles: {
                        fillColor: [113, 32, 218],
                    },
                });

                doc.save(
                    `Bookings-from-${this.formatDate(
                        this.formattedStartDate
                    )}-To-${this.formatDate(this.formattedEndDate)}.pdf`
                );
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    generateinvoicePdf() {
        const bookingId = this.dataSharingService.getBookingId();
        const doc = new jsPDF() as any;

        const options = {
            draw: 1,
            columns: [
                {data: "startTime"},
                {data: "name"},
                {data: "flag"},
                {data: "paymentStatus"},
                {data: "hours"},
                {data: "startTime"},
                {data: "endTime"},
                {data: "ratePerHour"},
                {data: "material"},
                {data: "totalAmount"},
                {data: "description"},
            ],
            order: [
                {
                    column: 0,
                    dir: "asc",
                },
            ],
            start: 0,
            length: 1000,
            search: {
                value: "",
                regex: false,
            },
            userId: bookingId,
            startDate: this.formattedStartDate,
            endDate: this.formattedEndDate,
        };
        this.http.post(CONFIG.clientBookingHistory, options).subscribe({
            next: async (response: any) => {
                const extractedData = response.data.original.map(
                    ({
                         startTime,
                         hours,
                         ratePerHour,
                         totalAmount,

                     }) => {
                        const formattedStartTime = new Date(startTime);
                        return {
                            Date: this.formatDate(formattedStartTime),
                            Hours: hours,
                            Rate: ratePerHour,
                            "Amount": totalAmount,
                        };
                    }
                );
                // Get the default paper size dimensions
                const width = doc.internal.pageSize.getWidth();
                const height = doc.internal.pageSize.getHeight();

                doc.addImage(
                    "/assets/images/quotation/Letter.jpeg",
                    "JPEG",
                    0,
                    0,
                    width,
                    height
                );

                doc.setTextColor(80, 80, 80);
                doc.setFontSize(25);
                doc.text('Invoice', 12, 46);
                doc.setFontSize(10);
                doc.text(`To: ${this.client_name}`, 75, 45);
                doc.text(`Address: ${response?.data?.original[0].client?.address}`, 75, 55);
                doc.text(`NTN Number: '`, 75, 65);


                doc.autoTable({
                    head: [
                        [
                            "Date",
                            "Hours",
                            "Rate",
                            "Amount",
                        ],
                    ],
                    body: extractedData.map(Object.values),
                    margin: {left: 75},
                    startY: 80,
                    headStyles: {
                        fillColor: [113, 32, 218],
                    },
                    tableWidth: 120,
                });

                // Add a horizontal line at the last row
                const lastRowY = doc.lastAutoTable.finalY;
                doc.setDrawColor(80, 80, 80);
                doc.setLineWidth(0.5);
                doc.line(75, lastRowY + 5, 195, lastRowY + 5);

                doc.text(`Total Amount:${response?.data?.total?.totalAmount}`, 141, lastRowY + 12);
                doc.save(
                    `Invoice of ${this.client_name}.pdf`
                );
            },
            error: (error) => {
                console.log(error);
            },
        });
    }


    formatDate(dateTime: Date): string {
        return this.datePipe.transform(dateTime, "dd-MM-yy") || "";
    }

    formatDateToTime(dateTime: Date): string {
        return this.datePipe.transform(dateTime, "hh:mm a") || "";
    }
}
