import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbDate, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { DataSharingService } from "../../../shared/services/data-sharing.service";
import { HttpClient } from "@angular/common/http";
import { SharedModule } from "../../../shared/shared.module";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { CONFIG } from "../../../../../config";
import { DateUtils } from "../../../utils/date-utils";
import { UtilsService } from "../../../shared/services/utils.service";

@Component({
    selector: "app-client-account-history",
    standalone: true,
    imports: [SharedModule, DataTablesModule, NgIf, DatePipe, NgForOf, NgClass],
    templateUrl: "./client-account-history.component.html",
    styleUrls: ["./client-account-history.component.scss"],
})
export class ClientAccountHistoryComponent implements OnInit {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    startDate: NgbDate;
    endDate: NgbDate;
    formattedStartDate: any;
    formattedEndDate: any;
    dataTableParam: any;
    displayMonths: any;
    outsideDays = "visible";
    showWeekNumbers = true;

    navigation = "select";
    persons: any[] = [
        { id: 1, firstName: "John", lastName: "Doe", action: "deposit" },
        { id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw" },
        { id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle" },
        { id: 4, firstName: "Bob", lastName: "Brown", action: "delete" },
    ];
    statements: any[] = [];
    employee_name: string = "";

    totals: any;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        private dataSharingService: DataSharingService,
        private utils: UtilsService
    ) {}

    ngOnInit() {
        const bookingId = this.dataSharingService.getUserId();
        this.employee_name = this.dataSharingService.getName();

        const currentDate = new Date();
        this.endDate = DateUtils.createNgbDate(currentDate);

        // Set filter's end date 3 days ahead of start date
        currentDate.setDate(currentDate.getDate() - 7);
        this.startDate = DateUtils.createNgbDate(currentDate);

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;

        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            lengthChange: false,
            serverSide: true,
            searching: false,
            autoWidth: true,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                const requestBody = {
                    userId: bookingId,
                    role: "CLIENT",
                    startDate: this.formattedStartDate,
                    endDate: this.formattedEndDate,
                    ...dataTablesParameters,
                };
                that.http
                    .post<any>(CONFIG.userAccountStatement, requestBody)
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.statements = data;
                            that.totals = resp.data?.total;
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "date" },
                { data: "receivedBy" },
                { data: "paidBy" },
                { data: "amount" },
                { data: "paidVia" },
                { data: "type" },
                { data: "remarks" },
                { data: "status" },
                { data: "employee" },
            ],
        };
    }

    // To formate numeric data in table
    formatNumber(value: any): string {
        // Check if the value is numeric
        if (!isNaN(value)) {
            // Format the number without decimal places
            return Math.floor(value).toString();
        } else {
            // Return the original value if it's not numeric
            return value;
        }
    }

    onDateChange() {
        // Handle date change logic here
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }
}
