<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card login-dark">
                <div>
                    <div>
                        <a class="logo" routerLink="/">
                            <img
                                class="img-fluid for-light"
                                src="assets/images/logo/logo.png"
                                alt="looginpage"
                            />
                            <img
                                class="img-fluid for-dark"
                                src="assets/images/logo/logo_dark.png"
                                alt="looginpage"
                            />
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="theme-form" [formGroup]="changePassForm">
                            <h4>Change password</h4>
                            <p>Enter your old password</p>
                            <div class="form-group">
                                <!-- Password input field -->
                                <label class="col-form-label"
                                    >Old Password</label
                                >
                                <input
                                    class="form-control"
                                    [type]="showOld ? 'text' : 'password'"
                                    formControlName="currentPassword"
                                    required=""
                                    placeholder="*********"
                                />
                                <div
                                    class="show-hide"
                                    (click)="showPassword('old')"
                                    *ngIf="!showOld"
                                >
                                    <span class="show"></span>
                                </div>
                                <div
                                    class="show-hide"
                                    (click)="showPassword('old')"
                                    *ngIf="showOld"
                                >
                                    <span class="Hide"></span>
                                </div>
                                <div
                                    *ngIf="
                                        changePassForm.controls.currentPassword
                                            .touched &&
                                        changePassForm.controls.currentPassword
                                            .errors?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Old Password is required
                                </div>
                            </div>
                            <div class="form-group">
                                <!-- Password input field -->
                                <label class="col-form-label"
                                    >New Password</label
                                >
                                <input
                                    class="form-control"
                                    [type]="showNew ? 'text' : 'password'"
                                    formControlName="newPassword"
                                    required=""
                                    placeholder="*********"
                                />
                                <div
                                    class="show-hide"
                                    (click)="showPassword('new')"
                                    *ngIf="!showNew"
                                >
                                    <span class="show"></span>
                                </div>
                                <div
                                    class="show-hide"
                                    (click)="showPassword('new')"
                                    *ngIf="showNew"
                                >
                                    <span class="Hide"></span>
                                </div>
                                <div
                                    *ngIf="
                                        changePassForm.controls.newPassword
                                            .touched &&
                                        changePassForm.controls.newPassword
                                            .errors?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    New Password is required
                                </div>
                            </div>
                            <div
                                *ngIf="
                                    changePassForm.controls.currentPassword
                                        .dirty &&
                                    changePassForm.controls.newPassword.dirty &&
                                    changePassForm.hasError('match')
                                "
                                class="text text-danger mt-1"
                            >
                                Current password and new password cannot be the
                                same.
                            </div>
                            <div class="form-group mb-0">
                                <div class="text-end mt-3">
                                    <button
                                        class="btn btn-primary d-block w-100"
                                        [disabled]="changePassForm.invalid"
                                        (click)="changePass()"
                                        type="button"
                                    >
                                        <span>Change Password</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
