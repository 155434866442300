<app-breadcrumb
    [items]="['Settings']"
    [title]="'Company Profile'"
    active_item="Company Profile"
></app-breadcrumb>
<div class="container-fluid">
    <form (ngSubmit)="onSubmit()" [formGroup]="companyProfileForm">
        <div class="row">
            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="companyName">Company Name</label>
                    <input class="form-control" formControlName="companyName" id="companyName" type="text"/>
                    <div *ngIf="companyName?.invalid && (companyName?.dirty || companyName?.touched)">
                        <div *ngIf="companyName?.errors?.required">Company Name is required.</div>
                        <div *ngIf="companyName?.errors?.minlength">Company Name must be at least 3 characters long.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="phoneNumber">Phone Number</label>
                    <input class="form-control" formControlName="phoneNumber" id="phoneNumber" type="text"/>
                    <div *ngIf="phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched)">
                        <div *ngIf="phoneNumber?.errors?.required">Phone Number is required.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="whatsappNumber">WhatsApp Number</label>
                    <input class="form-control" formControlName="whatsappNumber" id="whatsappNumber" type="text"/>
                    <div *ngIf="whatsappNumber?.invalid && (whatsappNumber?.dirty || whatsappNumber?.touched)">
                        <div *ngIf="whatsappNumber?.errors?.required">WhatsApp Number is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="address">Address</label>
                    <input class="form-control" formControlName="address" id="address" type="text"/>
                    <div *ngIf="address?.invalid && (address?.dirty || address?.touched)">
                        <div *ngIf="address?.errors?.required">Address is required.</div>
                        <div *ngIf="address?.errors?.minlength">Address must be at least 10 characters long.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="POBox">PO Box</label>
                    <input class="form-control" formControlName="POBox" id="POBox" type="text"/>
                    <div *ngIf="POBox?.invalid && (POBox?.dirty || POBox?.touched)">
                        <div *ngIf="POBox?.errors?.required">PO Box is required.</div>
                        <div *ngIf="POBox?.errors?.pattern">PO Box must be 1-6 digits long.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="companyEmail">Company Email</label>
                    <input class="form-control" formControlName="companyEmail" id="companyEmail" type="email"/>
                    <div *ngIf="companyEmail?.invalid && (companyEmail?.dirty || companyEmail?.touched)">
                        <div *ngIf="companyEmail?.errors?.required">Email is required.</div>
                        <div *ngIf="companyEmail?.errors?.email">Email must be a valid email address.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 mb-1">
                <div class="form-group">
                    <label for="taxNumber">Tax Number</label>
                    <input class="form-control" formControlName="taxNumber" id="taxNumber" type="text"/>
                    <div *ngIf="taxNumber?.invalid && (taxNumber?.dirty || taxNumber?.touched)">
                        <div *ngIf="taxNumber?.errors?.required">Tax Number is required.</div>
                        <div *ngIf="taxNumber?.errors?.pattern">Tax Number must be between 1-15 digits long.</div>
                    </div>
                </div>
            </div>
        </div>

        <button [disabled]="companyProfileForm.invalid" class="btn btn-primary" type="submit">Submit</button>
    </form>
</div>
