// Testing URI
// export const BASE_URL_API = "https://hsmanager-production.up.railway.app";
// export const BASE_URL_API = "https://hs-manager-nodejs.onrender.com";

// Main URI
export const BASE_URL_API = "https://hs-manager-nodejs.fly.dev";

export const CONFIG = {
    // Dashboard stats
    getAllStats: BASE_URL_API + "/api/getAllStats",

    login: BASE_URL_API + "/api/login",
    ipInfo: 'https://ipinfo.io?token=38cf76140eb39e',
    loginActivity: BASE_URL_API + "/api/loginActivity",

    // themeConfig
    getThemeConfig: BASE_URL_API + "/api/getThemeConfig",
    addUpdateThemeConfig: BASE_URL_API + "/api/addUpdateTheme",

    changePass: BASE_URL_API + "/api/changePassword",
    forgotPassword: BASE_URL_API + "/api/forgotPassword",
    verifyOtp: BASE_URL_API + "/api/verifyOTP",
    // client
    addClient: BASE_URL_API + "/api/addClient",
    getClientsData: BASE_URL_API + "/api/getClientsData",
    updateClient: BASE_URL_API + "/api/updateClient",
    addCompanyProfile: BASE_URL_API + "/api/employee/company/profile/update",
    getCompanyProfile: BASE_URL_API + "/api/employee/company/profile/get",
    clientList: BASE_URL_API + "/api/clientList",
    addCategory: BASE_URL_API + "/api/addCategory",
    clientDepositWithdraw: BASE_URL_API + "/api/client/depositWithdraw",
    // Client Subscription
    getClientSubscriptions: BASE_URL_API + "/api/client/active/subscriptions",
    getClientSubscriptionsByCategory:
        BASE_URL_API + "/api/client/active/subscriptions/categorywise",
    getAllClientSubscriptions: BASE_URL_API + "/api/clients/subscriptions/all",
    addClientSubscription: BASE_URL_API + "/api/client/subscription/add",
    updateClientSubscriptionStatus:
        BASE_URL_API + "/api/change/client/subscription/status",
    // expenses
    getExpenses: BASE_URL_API + "/api/getExpenses",
    deleteExpense: BASE_URL_API + "/api/deleteExpense",
    addExpense: BASE_URL_API + "/api/addExpense",
    updateExpense: BASE_URL_API + "/api/updateExpense",
    // employee
    employeeList: BASE_URL_API + "/api/employeeList",
    addEmployee: BASE_URL_API + "/api/addEmployee",
    getEmployeeData: BASE_URL_API + "/api/getEmployeeData",
    getEmployeeDataByCategory: BASE_URL_API + "/api/employees/list/byCategory",
    updateEmployee: BASE_URL_API + "/api/updateEmployee",
    employeeDepositWithdraw: BASE_URL_API + "/api/employees/depositWithdraw",
    employeeAccountHistory: BASE_URL_API + "/api/userAccountStatement/get",

    // Reports
    userAccountStatement: BASE_URL_API + "/api/userAccountStatement/get",

    // subscription
    addSubscription: BASE_URL_API + "/api/addSubscription",
    allSubscriptions: BASE_URL_API + "/api/allSubscriptions",
    subscriptionByCategory: BASE_URL_API + "/api/category/subscriptions",
    deleteSubscription: BASE_URL_API + "/api/subscription",
    // category
    allCategories: BASE_URL_API + "/api/allCategories",
    addCategories: BASE_URL_API + "/api/addCategory",
    deleteCategory: BASE_URL_API + "/api/category",
    updateCategory: BASE_URL_API + "/api/updateCategory",

    // bookingHistory
    employeeBookingHistory: BASE_URL_API + "/api/employeeBookingHistory",
    clientBookingHistory: BASE_URL_API + "/api/clientBookingHistory",
    getClientsBookings: BASE_URL_API + "/api/getClientsBookings",
    addBooking: BASE_URL_API + "/api/addBooking",
    updateBooking: BASE_URL_API + "/api/booking/update",

    // deleteSubscription: BASE_URL_API + "/api/subscription/65f84b9d0a71bf7b2444e2fe",

    // Bookings
    getAllBookings: BASE_URL_API + "/api/allBookings",
    getAllBookingsByCategory: BASE_URL_API + "/api/allBookings/byCategory",

    // File Upload
    uploadFile: BASE_URL_API + "/api/uploadFile",
    getFile: BASE_URL_API + "/api/image/",

    // Profit Loss
    getProfitLoss: BASE_URL_API + "/api/profitLoss/get",

    // Cash Flow
    addCashFlow: BASE_URL_API + "/api/cashflow/add",
    getCashFlow: BASE_URL_API + "/api/getCashFlow",
    updateCashFlow: BASE_URL_API + "/api/updateCashFlow",
    deleteCashFlow: BASE_URL_API + "/api/deleteCashFlow",

    // Attendance
    getAllAttendance: BASE_URL_API + "/api/getAllAttendance",

    // Quotations
    getAllQuotations: BASE_URL_API + "/api/quotation/getAll",
    addQuotation: BASE_URL_API + "/api/quotation/add",
    getQuotationById: BASE_URL_API + "/api/quotation/getById",
    updateQuotation: BASE_URL_API + "/api/quotation/update",
};
