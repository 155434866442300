import { Component, ViewChild } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BackendService } from "src/app/shared/services/backend.service";
import { HttpClient } from "@angular/common/http";
import {
    NgbDate,
    NgbDatepickerModule,
    NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CONFIG } from "config";
import { CommonModule } from "@angular/common";
import { EmployeeBookingHistoryComponent } from "../employees/employee-booking-history/employee-booking-history.component";
import { DateUtils } from "src/app/utils/date-utils";
import { UtilsService } from "src/app/shared/services/utils.service";

@Component({
    selector: "app-cashflow",
    standalone: true,
    templateUrl: "./cashflow.component.html",
    styleUrl: "./cashflow.component.scss",
    imports: [
        CommonModule,
        EmployeeBookingHistoryComponent,
        SharedModule,
        DataTablesModule,
        NgbDatepickerModule,
    ],
})
export class CashflowComponent {
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    statement: any[];
    total: any;
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    public selected = [];
    formattedStartDate: any;
    formattedEndDate: any;
    navigation = "select";
    total$: Observable<number>;
    userForm!: FormGroup;
    displayMonths = 1;
    outsideDays = "visible";
    expensesId: string = "";
    showWeekNumbers = true; // Define the showWeekNumbers property here

    persons: any[] = [
        { id: 1, firstName: "John", lastName: "Doe", action: "deposit" },
        { id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw" },
        { id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle" },
        { id: 4, firstName: "Bob", lastName: "Brown", action: "delete" },
    ];
    dataTableParam: any;
    expenseForm: FormGroup;
    show: boolean = false;

    // Modals Config
    @ViewChild("cashFlowContent") cashFlowContent: any;
    CfTitle: string = "";

    cashFlowForm: FormGroup;
    // CashFlow loading tracker
    cashFlowLoading: boolean = false;

    constructor(
        private fb: FormBuilder,
        private backend: BackendService,
        private http: HttpClient,
        private modalService: NgbModal,
        private utils: UtilsService
    ) {
        this.cashFlowForm = this.fb.group({
            type: ["", Validators.required],
            receivedBy: ["", Validators.required],
            remarks: ["", Validators.required],
            paidBy: ["", Validators.required],
            date: ["", Validators.required],
            paidVia: ["", Validators.required],
            amount: ["", [Validators.required, Validators.min(0)]],
        });
    }

    startDate: any;
    endDate: any;

    ngOnInit(): void {
        this.startDate = DateUtils.createNgbDate(new Date());
        this.endDate = DateUtils.createNgbDate(new Date());

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getCashFlow,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.statement = data;
                            that.total = resp.data.total;
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "date", orderable: true },
                { data: "remarks" },
                { data: "receivedBy" },
                { data: "paidBy" },
                { data: "amount", orderable: true },
                { data: "paidVia" },
                { data: "status", orderable: true },
            ],
        };
    }

    onDateChange() {
        // Handle date change logic here
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    // API Calls
    deleteCashFlow(id: string) {
        console.log(id);
    }

    // Modal Handlers
    openCashFlow(type: string) {
        if (type.toLowerCase() === "i") {
            this.cashFlowForm.patchValue({ type: "IN" });
            this.CfTitle = "Cash In";
        } else if (type.toLowerCase() === "o") {
            this.cashFlowForm.patchValue({ type: "OUT" });
            this.CfTitle = "Cash Out";
        }

        this.cashFlowForm.patchValue({
            date: DateUtils.createNgbDate(new Date()),
        });

        this.modalService.open(this.cashFlowContent);
    }

    // Cash Flow form
    // Submit the cashflow form
    onSubmitCF() {
        this.cashFlowLoading = true;
        // Convert NgbDate to UTC format
        const ngbDate: NgbDate = this.cashFlowForm.get("date").value;
        const currentTime = new Date();

        const formattedDate = this.utils.getFormattedDateTime(
            ngbDate,
            currentTime.getHours(),
            currentTime.getMinutes()
        );

        const reqBody = {
            ...this.cashFlowForm.value,
            date: formattedDate,
        };

        this.backend.addCashFlow(reqBody).subscribe({
            next: (response) => {
                console.log(response);
                this.modalService.dismissAll();
                this.cashFlowForm.reset();
                this.rerender();
                this.cashFlowLoading = false;
            },
            error: (error) => {
                console.log(error);
                this.cashFlowLoading = false;
            },
        });
    }
}
