import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DataSharingService {
    private bookingId: any;
    private userId: any;
    private name: any;

    stats = new Subject<any>();
    private employeeList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
        []
    );
    private employeeListByCategory: BehaviorSubject<any[]> =
        new BehaviorSubject<any[]>([]);
    private allCategories: BehaviorSubject<any | null> = new BehaviorSubject<
        any | null
    >(null);
    private allClients: BehaviorSubject<any | []> = new BehaviorSubject<
        any | []
    >([]);

    private viewDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(
        new Date()
    );

    getEmployeeListByCategory(): BehaviorSubject<any> {
        return this.employeeListByCategory;
    }

    getEmployeeList(): BehaviorSubject<any | null> {
        return this.employeeList;
    }

    setEmployeeList(value: any | null): void {
        this.employeeList.next(value);
    }

    getAllCategories(): BehaviorSubject<any | null> {
        return this.allCategories;
    }

    setAllCategories(value: any | null): void {
        this.allCategories.next(value);
    }

    getAllClients(): BehaviorSubject<any | null> {
        return this.allClients;
    }

    setAllClients(value: any | null): void {
        this.allClients.next(value);
    }

    getViewDate() {
        return this.viewDate;
    }

    setViewDate(value: Date) {
        this.viewDate.next(value);
    }

    setBookingId(id: any) {
        this.bookingId = id;
    }

    getBookingId() {
        return this.bookingId;
    }

    setUserId(id: any) {
        this.userId = id;
    }

    getUserId() {
        return this.userId;
    }

    setName(name: any) {
        this.name = name;
    }

    getName() {
        return this.name;
    }
}
