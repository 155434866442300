<app-breadcrumb
    title="Attendance"
    [items]="['Settings']"
    active_item="Attendance"
></app-breadcrumb>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <div
                        class="theme-form flex-column flex-md-row align-items-start align-items-md-stretch"
                        style="display: flex; gap: 10px"
                    >
                        <div class="input-group">
                            <div class="input-group-text">From</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="Start Date"
                                name="startDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #startDatePicker="ngbDatepicker"
                                (click)="startDatePicker.toggle()"
                                [(ngModel)]="startDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">To</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="End Date"
                                name="endDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #endDatePicker="ngbDatepicker"
                                (click)="endDatePicker.toggle()"
                                [(ngModel)]="endDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="d-grid d-md-block w-100">
                            <button
                                class="btn btn-primary-gradien h-100"
                                (click)="rerender()"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <th
                                        class="position-sticky start-0 bg-white"
                                    ></th>
                                    <th
                                        *ngFor="let record of attendanceRec"
                                        class="text-center"
                                    >
                                        {{ record.date | date : "dd-MM-yyyy" }}
                                    </th>
                                </thead>
                                <tbody *ngIf="attendanceRec?.length > 0">
                                    @for (employee of employeeList; track
                                    $index) {
                                    <tr>
                                        <td class="fw-bold suspense">
                                            {{ employee.name }}
                                        </td>
                                        @for (record of attendanceRec; track
                                        $index) {
                                        <td
                                            class="text-center fw-bold"
                                            [ngClass]="
                                                findPresence(
                                                    record.employees,
                                                    employee
                                                )
                                                    ? 'text-success'
                                                    : 'text-danger'
                                            "
                                        >
                                            {{
                                                findPresence(
                                                    record.employees,
                                                    employee
                                                )
                                                    ? "P"
                                                    : "A"
                                            }}
                                        </td>
                                        }
                                    </tr>
                                    }
                                </tbody>
                                <tbody *ngIf="attendanceRec?.length <= 0">
                                    <tr>
                                        <td class="text-center" colSpan="10">
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
