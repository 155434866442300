import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    public config = {
        settings: {
            layout: 'Dubai',
            layout_type: 'ltr',
            layout_version: 'light-only',
            sidebar_type: 'default-sidebar',
            icon: 'stroke-svg'
        },
        color: {
            primary_color: '#7366ff',
            secondary_color: '#f73164'
        }
    }

    constructor() {
        if (this.config.settings.layout_type == 'rtl')
            document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

        let theme = JSON.parse(localStorage.getItem('themeConfig') as string);
        if (theme && theme.config && theme.config['themeColor']) {
            document.documentElement.style.setProperty('--theme-deafult', theme.config['themeColor']);
        }


    }


}
