import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { BackendService } from "src/app/shared/services/backend.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-forgot-password",
    templateUrl: "./forgot-password.component.html",
    styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
    public ForgotPasswordForm: FormGroup;
    public OtpForm: FormGroup;

    public errorMessage: string;
    isforgot = true;
    forgotEmailVal: string = "";

    countdown: number = 300; // 5 minutes in seconds
    intervalId: any;
    timerDisplay: string = "05:00";

    constructor(
        private fb: FormBuilder,
        private backendservice: BackendService,
        private router: Router
    ) {
        // Inject HttpClient
        this.ForgotPasswordForm = this.fb.group({
            email: ["", [Validators.required, Validators.email]],
        });

        this.OtpForm = this.fb.group({
            otpDigitOne: ["", [Validators.required, Validators.maxLength(1)]],
            otpDigitTwo: ["", [Validators.required, Validators.maxLength(1)]],
            otpDigitThree: ["", [Validators.required, Validators.maxLength(1)]],
            otpDigitFour: ["", [Validators.required, Validators.maxLength(1)]],
            otpDigitFive: ["", [Validators.required, Validators.maxLength(1)]],
            otpDigitSix: ["", [Validators.required, Validators.maxLength(1)]],
            newPassword: ["", [Validators.required]],
        });
    }

    ngOnInit() {
        // Optionally start the timer on component load, if needed
    }

    ngOnDestroy() {
        this.stopTimer();
    }

    moveToNext(
        event: KeyboardEvent,
        currentField: HTMLInputElement,
        nextField: HTMLInputElement | null,
        prevField: HTMLInputElement | null
    ): void {
        let inputValue = (event.target as HTMLInputElement).value;

        // Restrict the input to a single character and set the value explicitly
        if (inputValue.length > 1) {
            inputValue = inputValue.slice(0, 1);
            currentField.value = inputValue;
        }

        // Update the form control with the restricted input value
        const controlName = currentField.getAttribute("formControlName");
        if (controlName) {
            this.OtpForm.get(controlName)?.setValue(inputValue);
        }

        // Move to the next field if a character is entered and it's not a backspace
        if (inputValue.length === 1 && nextField && event.key !== "Backspace") {
            nextField.focus();
        }

        // Handle backspace to move focus to the previous field
        if (event.key === "Backspace" && inputValue.length === 0 && prevField) {
            prevField.focus();
        }
    }

    forgot() {
        if (this.ForgotPasswordForm.valid) {
            const email = this.ForgotPasswordForm.get("email")?.value;
            this.backendservice.forgotPassword({ email }).subscribe({
                next: (response) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Success!",
                        text: response.message,
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        this.isforgot = false;
                        this.startTimer(); // Start the timer when OTP form is displayed
                    });
                },
                error: (err) => {
                    console.error("Error sending email", err);
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: err.error?.message || "Something went wrong!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                },
            });
        }
    }

    OTP() {
        if (this.OtpForm.valid) {
            const otpValue = [
                this.OtpForm.get("otpDigitOne")?.value,
                this.OtpForm.get("otpDigitTwo")?.value,
                this.OtpForm.get("otpDigitThree")?.value,
                this.OtpForm.get("otpDigitFour")?.value,
                this.OtpForm.get("otpDigitFive")?.value,
                this.OtpForm.get("otpDigitSix")?.value,
            ].join(""); // Combine the six OTP digits

            const newPassword = this.OtpForm.get("newPassword")?.value;

            const payload = {
                email: this.forgotEmailVal,
                otp: otpValue,
                newPassword: newPassword,
            };

            this.backendservice.verifyOtp(payload).subscribe({
                next: (response) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Password Changed!",
                        text: response.message,
                        showConfirmButton: false,
                        timer: 1500,
                    }).then(() => {
                        this.router.navigate(["/auth/login"]);
                    });
                },
                error: (err) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Verification Failed!",
                        text:
                            err.error?.message || "Invalid OTP or other error!",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                },
            });
        }
    }

    startTimer() {
        this.intervalId = setInterval(() => {
            if (this.countdown > 0) {
                this.countdown--;
                this.updateTimerDisplay();
            } else {
                this.stopTimer();
                this.handleTimerExpiration();
            }
        }, 1000);
    }

    updateTimerDisplay() {
        const minutes = Math.floor(this.countdown / 60);
        const seconds = this.countdown % 60;
        this.timerDisplay = `${this.pad(minutes)}:${this.pad(seconds)}`;
    }

    pad(value: number): string {
        return value < 10 ? `0${value}` : value.toString();
    }

    stopTimer() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    handleTimerExpiration() {
        Swal.fire({
            icon: "warning",
            title: "OTP Expired",
            text: "Your OTP has expired. Please request a new one.",
        }).then(() => {
            this.isforgot = true; // Reset the form to show the email input again
            this.countdown = 300; // Reset the countdown
            this.timerDisplay = "05:00"; // Reset the timer display
        });
    }

    EmailIputVal(val: any) {
        this.forgotEmailVal = val.target.value;
    }
}
