import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { Observable } from "rxjs";
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from "@angular/forms";
import { BackendService } from "../../shared/services/backend.service";
import { UtilsService } from "../../shared/services/utils.service";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BASE_URL_API, CONFIG } from "../../../../config";
import { SharedModule } from "../../shared/shared.module";
import { CommonModule, DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { DateUtils } from "src/app/utils/date-utils";

@Component({
    selector: "app-expenses",
    standalone: true,
    imports: [SharedModule, DataTablesModule, DatePipe, CommonModule],
    templateUrl: "./expenses.component.html",
    styleUrl: "./expenses.component.scss",
    providers: [DatePipe],
})
export class ExpensesComponent implements OnInit {
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    public selected = [];

    @ViewChild("receipt") receipt: any;
    @ViewChild("content") content: any;
    @ViewChild("contentEdit") contentEdit: any;

    formattedStartDate: any;
    formattedEndDate: any;
    users: any[] = [];
    navigation = "select";
    total$: Observable<number>;
    userForm!: FormGroup;
    displayMonths = 1;
    outsideDays = "visible";
    expensesId: any;
    modal_title: string = "";
    modal_button: string = "";
    showWeekNumbers = true; // Define the showWeekNumbers property here

    persons: any[] = [
        { id: 1, firstName: "John", lastName: "Doe", action: "deposit" },
        { id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw" },
        { id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle" },
        { id: 4, firstName: "Bob", lastName: "Brown", action: "delete" },
    ];
    dataTableParam: any;
    DwTitle: any;
    transactionForm: FormGroup;
    expenseForm: FormGroup;
    show: boolean = false;

    // Receipt variables
    showReceipt: boolean = false;
    currentReceipt: string = "";
    uploadedFileSize: number;

    currentUser: any;
    // Track expense form loading
    expenseLoading: boolean = false;

    constructor(
        private fb: FormBuilder,
        private backend: BackendService,
        private http: HttpClient,
        private modalService: NgbModal,
        private datePipe: DatePipe,
        private utils: UtilsService
    ) {
        this.expenseForm = this.fb.group({
            type: ["", Validators.required],
            remarks: ["", Validators.required],
            date: ["", Validators.required],
            paidBy: ["", Validators.required],
            amount: ["", [Validators.required, Validators.min(0)]],
            receiptFile: ["", [this.fileSizeValidator(3 * 1024 * 1024)]],
            receiptId: [""],
            paidVia: ["", Validators.required],
        });
    }

    statement: any[] = [];
    total: any[] = [];
    startDate: any;
    endDate: any;

    ngOnInit(): void {
        this.currentUser = localStorage.getItem("role")?.toLowerCase();

        this.startDate = DateUtils.createNgbDate(new Date());
        this.endDate = DateUtils.createNgbDate(new Date());

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10000,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getExpenses,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.statement = data;
                            that.total = resp.data.total;
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "date" },
                { data: "paidBy" },
                { data: "paidVia" },
                { data: "remarks" },
                { data: "_id" },
                { data: "amount", orderable: true },
            ],
        };
    }

    openModal() {
        this.modal_title = "Add Expense";
        this.modal_button = "Submit";

        this.expenseForm.patchValue({
            date: DateUtils.createNgbDate(new Date()),
        });

        this.modalService
            .open(this.content, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // Handle close/dismiss
                },
                (reason) => {
                    // Handle close/dismiss
                }
            );
    }

    deposit(person) {}

    showPassword: boolean = false;

    withdraw(person) {}

    toggleStatus(person) {}

    delete(person) {}

    // Show receipt image
    showReceiptModal(receiptUrl: string) {
        this.currentReceipt = CONFIG.getFile + receiptUrl;
        this.modalService.open(this.receipt, { size: "md" });
        this.showReceipt = true;
    }

    // Handling Receipt file change
    onReceiptFileChange(event: any) {

        const file = event.target.files[0];
        if (!file) return;

        this.expenseForm.get("receiptFile")?.markAsDirty();

        // Checks for file size validation
        this.uploadedFileSize = file.size;
        this.expenseForm.get("receiptFile")?.updateValueAndValidity(); // Trigger validation

        // if (this.expenseForm.invalid) return;

        const formData = new FormData();
        formData.append("file", file);

        this.backend.uploadFile(formData).subscribe({
            next: (data: any) => {
                this.expenseForm.patchValue({ receiptId: data.file._id });

                this.expenseForm.get("receiptFile")?.updateValueAndValidity(); // Re-Trigger validation when id is set
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    // Custom validator
    fileSizeValidator(maxSize: number) {
        return (control: FormControl): ValidationErrors | null => {
            if (this.expenseForm?.get("receiptFile").pristine) return null;

            if (this.uploadedFileSize && this.uploadedFileSize > maxSize) {
                return {
                    fileSize:
                        "File size exceeds the maximum limit of " +
                        maxSize +
                        " bytes",
                };
            }

            return null;
        };
    }

    onDateChange() {
        // Handle date change logic here
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    onSubmit() {
        this.expenseLoading = true;

        const currentDate = new Date();
        const date = this.utils.getFormattedDateTime(
            this.expenseForm.get("date").value,
            currentDate.getHours(),
            currentDate.getMinutes()
        );

        const reqBody = { ...this.expenseForm.value, date };
        delete reqBody.receiptFile;

        if (this.modal_title === "Add Expense") {
            this.backend.addExpense(reqBody).subscribe({
                next: (data: any) => {
                    this.closeModal();
                    this.expenseForm.reset();
                    this.rerender();
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Expense added successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.expenseLoading = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: error.error.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.expenseLoading = false;
                },
            });
        } else if (this.modal_title === "Edit Expense") {
            debugger;
            const expenseId = this.expensesId;
            if (this.expenseForm.contains("expenseId")) {
                // If the control exists, patch the value
                this.expenseForm.patchValue({
                    expenseId: expenseId,
                });
            } else {
                // If the control does not exist, add it
                this.expenseForm.addControl(
                    "expenseId",
                    new FormControl(expenseId)
                );
            }
            this.backend
                .updateExpense({ ...this.expenseForm.value, date })
                .subscribe({
                    next: (data: any) => {
                        this.closeModal();
                        this.expenseForm.reset();
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Expense updated successfully.",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.rerender();
                        this.expenseLoading = false;
                    },
                    error: (error) => {
                        // Error callback
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: error.error.message,
                            showConfirmButton: false,
                            timer: 2500,
                        });
                        this.expenseLoading = false;
                    },
                });
        }
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    // Delete Api
    deleteExpense(expenseId: any) {
        this.backend.deleteExpense(expenseId).subscribe({
            next: (data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Expense deleted successfully.",
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.rerender();
            },
            error: (error) => {
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error.error.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
            },
        });
    }

    // Patch Values Call
    patchExpenseFormValues(item: any) {
        this.expensesId = item._id;

        this.expenseForm.patchValue({
            type: item.type,
            remarks: item.remarks,
            date: DateUtils.createNgbDate(new Date(item.date)),
            paidBy: item.paidBy,
            amount: item.amount,
            receiptId: item.recieptId,
            paidVia: item.paidVia,
        });
    }

    // onClick Edit Button
    editExpense(item: any) {
        this.modal_title = "Edit Expense";
        this.modal_button = "Update";

        this.patchExpenseFormValues(item);
        this.modalService.open(this.content, {
            ariaLabelledBy: "modal-basic-title",
        });
    }
}

function moment(startDate: Date) {
    throw new Error("Function not implemented.");
}
