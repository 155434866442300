<app-breadcrumb
    [title]="'Subscriptions'"
    [items]="['Settings']"
    active_item="Subscriptions"
></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <button
                        class="btn btn-primary"
                        style="float: right"
                        (click)="openModal()"
                    >
                        Add Subscriptions
                    </button>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Subscription Name</th>
                                        <th>Category</th>
                                        <th>Price</th>
                                        <th>Total Visits</th>
                                        <th>Total Hours</th>
                                        <th>Rate Per Hour</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                @if (users.length) {
                                <tbody>
                                    <tr
                                        *ngFor="
                                            let person of users;
                                            let i = index
                                        "
                                    >
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ person?.subscriptionName }}</td>
                                        <td>
                                            {{ person?.category?.categoryName }}
                                        </td>
                                        <td>{{ person?.price }}</td>
                                        <td>{{ person?.totalVisits }}</td>
                                        <td>{{ person?.totalHours }}</td>
                                        <td>{{ person?.ratePerHour }}</td>

                                        <td class="d-flex gap-1">
                                            <button
                                                class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                                type="button"
                                                data-bs-original-title=""
                                                title=""
                                                (click)="
                                                    deleteSubscription(
                                                        person._id
                                                    )
                                                "
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                } @else {
                                <tbody>
                                    <tr>
                                        <td
                                            [colSpan]="
                                                dtOptions?.columns.length + 1
                                            "
                                            class="no-data-available text-center"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Add Subscriptions</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="subscriptionForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="subscriptionName" class="form-label"
                    >Subscription Name</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="subscriptionName"
                    formControlName="subscriptionName"
                    placeholder="Enter Subscription Name"
                    required
                />
                <div
                    *ngIf="
                        subscriptionForm.get('subscriptionName').invalid &&
                        subscriptionForm.get('subscriptionName').touched
                    "
                    class="text-danger"
                >
                    Subscription Name is required
                </div>
            </div>
            <div class="mb-3">
                <label for="categoryId" class="form-label">Category</label>
                <select
                    class="form-select"
                    name="categoryId"
                    id="categoryId"
                    formControlName="categoryId"
                    required
                >
                    <option
                        *ngFor="let category of allCategories"
                        [value]="category._id"
                    >
                        {{ category.categoryName }}
                    </option>
                </select>
                <div
                    *ngIf="
                        subscriptionForm.get('categoryId').invalid &&
                        subscriptionForm.get('categoryId').touched
                    "
                    class="text-danger"
                >
                    Category is required
                </div>
            </div>
            <div class="mb-3">
                <label for="totalVisits" class="form-label">Total Visits</label>
                <input
                    type="number"
                    class="form-control"
                    id="totalVisits"
                    formControlName="totalVisits"
                    placeholder="Enter Total Visits"
                    required
                    (input)="updateVisitDays()"
                />
                <div
                    *ngIf="
                        subscriptionForm.get('totalVisits').invalid &&
                        subscriptionForm.get('totalVisits').touched
                    "
                    class="text-danger"
                >
                    Total Visits is required
                </div>
            </div>
            <ng-container formArrayName="visitDays">
                <div
                    *ngFor="let visit of visitDays.controls; let i = index"
                    [formGroupName]="i"
                >
                    <div class="row mb-3" *ngIf="shouldDisplayVisitDay(i)">
                        <div class="col">
                            <label class="form-label">Select Day</label>
                            <select
                                class="form-select"
                                formControlName="day"
                                required
                            >
                                <option value="" disabled selected>
                                    Select a Day
                                </option>
                                <option
                                    *ngFor="let day of weekdays"
                                    [value]="day"
                                >
                                    {{ day }}
                                </option>
                            </select>
                        </div>
                        <div class="col">
                            <label class="form-label">From</label>
                            <input
                                type="time"
                                class="form-control"
                                formControlName="timeFrom"
                                required
                            />
                        </div>
                        <div class="col">
                            <label class="form-label">To</label>
                            <input
                                type="time"
                                class="form-control"
                                formControlName="timeTo"
                                required
                            />
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="mb-3">
                <label for="totalHours" class="form-label">Total Hours</label>
                <input
                    type="number"
                    class="form-control"
                    id="totalHours"
                    formControlName="totalHours"
                    placeholder="Enter Total Hours"
                    required
                />
                <div
                    *ngIf="
                        subscriptionForm.get('totalHours').invalid &&
                        subscriptionForm.get('totalHours').touched
                    "
                    class="text-danger"
                >
                    Total Hours is required
                </div>
            </div>
            <div class="mb-3">
                <label for="ratePerHour" class="form-label"
                    >Rate Per Hour</label
                >
                <input
                    type="number"
                    class="form-control"
                    id="ratePerHour"
                    formControlName="ratePerHour"
                    placeholder="Enter Rate Per Hour"
                    required
                />
                <div
                    *ngIf="
                        subscriptionForm.get('ratePerHour').invalid &&
                        subscriptionForm.get('ratePerHour').touched
                    "
                    class="text-danger"
                >
                    Rate Per Hour is required
                </div>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </div>
</ng-template>
