<div class="container-fluid overflow-hidden">
    <div class="page-title">
        <div class="row justify-content-between">
            <div class="col-6 col-lg-4">
                <h3>{{ title }}</h3>
            </div>
            <ng-content></ng-content>
            <div class="col-6 col-lg-4">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a [routerLink]="'/dashboard/default'">
                            <svg class="stroke-icon">
                                <use
                                    href="assets/svg/icon-sprite.svg#stroke-home"
                                ></use>
                            </svg>
                        </a>
                    </li>
                    <li class="breadcrumb-item" *ngFor="let item of items">
                        {{ item }}
                    </li>
                    <li class="breadcrumb-item active">{{ active_item }}</li>
                </ol>
            </div>
        </div>
    </div>
</div>
