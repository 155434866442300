import { Component, OnInit, ViewChild } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { HttpClient } from "@angular/common/http";
import { CONFIG } from "config";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe, NgClass, NgForOf, NgIf } from "@angular/common";
import { BackendService } from "src/app/shared/services/backend.service";
import Swal from "sweetalert2";

@Component({
    selector: "app-client-subscriptions",
    standalone: true,
    templateUrl: "./client-subscriptions.component.html",
    styleUrl: "./client-subscriptions.component.scss",
    imports: [SharedModule, DataTablesModule, NgIf, NgForOf, NgClass, DatePipe],
})
export class ClientSubscriptionsComponent implements OnInit {
    // DataTables Configuration variables
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dataTableParam: any;

    // Options for the data-table
    dtOptions: DataTables.Settings = {};

    // Data to show in the data-table
    clientSubscriptions: any[] = [];

    // Form to add/update client subscriptions
    clientSubscriptionForm: FormGroup;

    // Modals Configurations
    @ViewChild("content") content: any;

    // Inject necessary dependencies
    constructor(
        private http: HttpClient,
        private fb: FormBuilder,
        private backend: BackendService,
        public modalService: NgbModal
    ) {
        // Create client subscription form
        this.clientSubscriptionForm = this.fb.group({});
    }

    // Handle initial configurations on page load
    ngOnInit(): void {
        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getAllClientSubscriptions,
                        Object.assign(dataTablesParameters, {})
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.clientSubscriptions = data;
                        }
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "subscriptionName", orderable: true },
                { data: "name" },
                { data: "phoneNumber" },
                { data: "categoryName" },
                { data: "totalHours" },
                { data: "remainingHours" },
                { data: "totalVisits" },
                { data: "remainingVisits" },
                { data: "ratePerHour" },
                { data: "price" },
                { data: "count", orderable: true },
                { data: "status", orderable: true },
                { data: "updatedAt", orderable: true },
            ],
        };
    }

    // Re-instantiate and draw the table
    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    // Handle Events
    onSubscriptionStatusChange(
        clientId: string,
        clientSubscriptionId: string,
        status: string
    ) {
        this.backend
            .updateClientSubscriptionStatus({
                clientId,
                clientSubscriptionId,
                status,
            })
            .subscribe({
                next: (response) => {
                    Swal.fire({
                        title: "Subscription Status updated successfully!",
                        position: "top-end",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    this.rerender();
                },
                error: (error) => {
                    Swal.fire({
                        title: error?.error?.message,
                        position: "top-end",
                        icon: "error",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    this.rerender();
                },
            });
    }

    // Modal Logic
    openModal() {
        this.modalService.open(this.content);
    }

    // Handle form submit
    onSubmit() {}
}
