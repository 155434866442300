import { Component, ViewChild } from "@angular/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { Observable } from "rxjs";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BackendService } from "../../shared/services/backend.service";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CONFIG } from "../../../../config";
import { SharedModule } from "../../shared/shared.module";
import Swal from "sweetalert2";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-subscriptions",
    standalone: true,
    imports: [CommonModule, SharedModule, DataTablesModule],
    templateUrl: "./subscriptions.component.html",
    styleUrl: "./subscriptions.component.scss",
})
export class SubscriptionsComponent {
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    public selected = [];
    @ViewChild("content") content: any;
    subscriptionForm: FormGroup;
    weekdays: string[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    users: any[] = [];
    navigation = "select";
    total$: Observable<number>;
    userForm!: FormGroup;
    persons: any[] = [
        { id: 1, firstName: "John", lastName: "Doe", action: "deposit" },
        { id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw" },
        { id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle" },
        { id: 4, firstName: "Bob", lastName: "Brown", action: "delete" },
    ];
    dataTableParam: any;
    DwTitle: any;
    transactionForm: FormGroup;
    show: boolean = false;

    // Add Subscription
    allCategories: any[];

    constructor(
        private fb: FormBuilder,
        private backend: BackendService,
        private http: HttpClient,
        private modalService: NgbModal
    ) {}

    ngOnInit(): void {
        this.subscriptionForm = this.fb.group({
            subscriptionName: ["", Validators.required],
            categoryId: ["", Validators.required],
            totalVisits: [1, [Validators.required, Validators.min(1)]],
            totalHours: ["", [Validators.required, Validators.min(0)]],
            visitDays: this.fb.array([this.createVisitDay()]),
            ratePerHour: ["", [Validators.required, Validators.min(0)]],
        });
        this.trackVisitDayChanges();
        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.allSubscriptions,
                        Object.assign(dataTablesParameters, {})
                    )
                    .subscribe((resp) => {
                        let data = resp.data?.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.users = data;
                        }
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "subscriptionName" },
                { data: "category" },
                { data: "price", orderable: true },
                { data: "totalVisits" },
                { data: "totalHours", orderable: true },
                { data: "ratePerHour", orderable: true },
            ],
        };
    }
    get visitDays(): FormArray {
        return this.subscriptionForm.get("visitDays") as FormArray;
    }

    createVisitDay(): FormGroup {
        return this.fb.group({
            day: ["", Validators.required],
            timeFrom: ["", Validators.required],
            timeTo: ["", Validators.required],
        });
    }

    updateVisitDays(): void {
        const totalVisits = this.subscriptionForm.get("totalVisits").value || 1;
        while (this.visitDays.length < totalVisits) {
            this.visitDays.push(this.createVisitDay());
        }
        while (this.visitDays.length > totalVisits) {
            this.visitDays.removeAt(this.visitDays.length - 1);
        }
    }

    shouldDisplayVisitDay(index: number): boolean {
        if (index === 0) {
            return true;
        }
        const previousVisitDay = this.visitDays.at(index - 1);
        return (
            previousVisitDay &&
            previousVisitDay.get("day").value &&
            previousVisitDay.get("timeFrom").value &&
            previousVisitDay.get("timeTo").value
        );
    }

    trackVisitDayChanges(): void {
        this.subscriptionForm.get("totalVisits").valueChanges.subscribe(() => {
            this.updateVisitDays();
        });

        this.visitDays.valueChanges.subscribe(() => {
            const totalVisits = this.subscriptionForm.get("totalVisits").value;
            for (let i = 0; i < this.visitDays.length; i++) {
                if (i < totalVisits - 1 && this.shouldDisplayVisitDay(i)) {
                    const nextVisitDay = this.visitDays.at(i + 1);
                    if (nextVisitDay && !this.shouldDisplayVisitDay(i + 1)) {
                        nextVisitDay.reset();
                    }
                }
            }
        });
    }

    openModal() {
        this.backend.allCategories().subscribe({
            next: (response) => {
                this.allCategories = response.data;
                this.subscriptionForm.patchValue({
                    categoryId: this.allCategories[0]._id,
                });
            },
            error: (error) => {
                console.log(error.error);
            },
        });

        this.modalService
            .open(this.content, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // Handle close/dismiss
                },
                (reason) => {
                    // Handle close/dismiss
                }
            );
    }

    deposit(person) {}

    showPassword: boolean = false;

    withdraw(person) {}

    toggleStatus(person) {}

    delete(person) {}

    onSubmit() {
        this.backend.addSubscription(this.subscriptionForm.value).subscribe({
            next: (response: any) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.closeModal();
                this.subscriptionForm.reset();
                this.rerender();
            },
            error: (error) => {
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Failed to add Subscription.",
                    showConfirmButton: false,
                    timer: 2500,
                });
            },
        });
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    openDeposit(w: string, user: any) {
        this.transactionForm.patchValue({
            userId: user._id,
        });
        if (w == "D") {
            this.DwTitle = "Deposit";
            this.transactionForm.patchValue({
                type: w,
            });
        } else if (w == "W") {
            this.DwTitle = "WithDraw";
            this.transactionForm.patchValue({
                type: w,
            });
        }
    }

    showNewPassword: boolean = false;
    showMasterPassword: boolean = false;
    selectedRole: string = localStorage.getItem("role");

    showHideNewPassword() {
        this.showNewPassword = !this.showNewPassword;
    }

    showHideMasterPassword() {
        this.showMasterPassword = !this.showMasterPassword;
    }

    deleteSubscription(subscriptionId: string) {
        this.backend.deleteSubscription(subscriptionId).subscribe({
            next: (data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Subscription deleted successfully.",
                    showConfirmButton: false,
                    timer: 1500,
                });

                this.rerender();
            },
            error: (error) => {
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Failed to delete Subscription.",
                    showConfirmButton: false,
                    timer: 2500,
                });
            },
        });
    }
}
