import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, finalize, catchError, throwError } from 'rxjs';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private loadingBar: LoadingBarService, private router: Router,) {}
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token = localStorage.getItem('token');
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        }
        return next.handle(request).pipe(
            // Hide the loader when the request is complete
            finalize(() => {
                this.loadingBar.complete();
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                  localStorage.clear();
                  this.router.navigate(['/auth/login']);
                }
                return throwError(error);
              })
        );
    }
}
