<!-- Booking History Modal  -->
<div class="modal-header">
    <h6>
        Booking History Of
        <span style="color: var(--theme-deafult)">{{ client_name }}</span>
    </h6>

    <h6 class="mx-auto">
        HOURS: {{ totals?.totalHours | number }} | AMOUNT :
        {{ totals?.totalAmount | number }} | DUE: <span class="text-warning">{{
            totals?.totalDueAmount | number
        }}</span>
    </h6>

    <button
        (click)="closeModal()"
        aria-label="Close"
        class="btn-close"
        type="button"
    ></button>
</div>
<div class="modal-body px-0 px-lg-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div
                        class="px-2 d-flex justify-content-between align-items-center flex-wrap flex-lg-nowrap"
                    >
                        <div
                            class="theme-form d-flex gap-2 flex-column flex-lg-row align-items-start align-items-lg-stretch"
                        >
                            <div class="input-group">
                                <div class="input-group-text">From</div>
                                <input
                                    #startDatePicker="ngbDatepicker"
                                    (click)="startDatePicker.toggle()"
                                    (ngModelChange)="onStartDateChange($event
                                    )"
                                    [(ngModel)]="startDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    class="datepicker-here form-control digits"
                                    name="startDate"
                                    ngbDatepicker
                                    placeholder="Start Date"
                                />
                            </div>
                            <div class="input-group">
                                <div class="input-group-text">To</div>
                                <input
                                    #endDatePicker="ngbDatepicker"
                                    (click)="endDatePicker.toggle()"
                                    (ngModelChange)="onEndDateChange($event
                                    )"
                                    [(ngModel)]="endDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    class="datepicker-here form-control digits"
                                    name="endDate"
                                    ngbDatepicker
                                    placeholder="End Date"
                                />
                            </div>
                            <div class="d-grid d-lg-block w-100">
                                <button
                                    (click)="rerender()"
                                    class="btn btn-primary-gradien h-100"
                                >
                                    Search
                                </button>
                            </div>
                        </div>

                        <button
                            (click)="generateinvoicePdf()"
                            *ngIf="
                                ['admin', 'manager', 'super'].includes(
                                    currentUserRole
                                )
                            "
                            class="btn btn-outline-secondary float-end me-4"
                        >
                            <i class="bi bi-file-arrow-down"></i> Download Invoice
                        </button>
                        <button
                            (click)="generatePdf()"
                            *ngIf="
                                ['admin', 'manager', 'super'].includes(
                                    currentUserRole
                                )
                            "
                            class="btn btn-outline-secondary float-end me-4"
                        >
                            <i class="bi bi-download"></i> Download
                        </button>
                    </div>
                    <div class="card-body px-0 px-md-4">
                        <div class="col-12 account-statement-tbl">
                            <div class="table-responsive">
                                <table
                                    [dtOptions]="dtOptions"
                                    class="row-border hover dataTable"
                                    datatable
                                >
                                    <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th class="suspense">
                                            Assignee Name
                                        </th>
                                        <th>Booking Status</th>
                                        <th>Payment Status</th>
                                        <th>Hours</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Rate Per Hour</th>
                                        <th>Material Charges / Hr</th>
                                        <th>Total Amount</th>
                                        <th>Description</th>
                                        <th>Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody *ngIf="persons?.length != 0">
                                    <tr *ngFor="let person of users">
                                        <td>
                                            {{
                                                person.startTime
                                                    | date : "d, MMM, EEE"
                                            }}
                                        </td>
                                        <td class="suspense">
                                            {{ person.assignee.name }}
                                        </td>
                                        <td>
                                            <select
                                                #bookingStatus="ngModel"
                                                [(ngModel)]="person.flag"
                                                [disabled]="
                                                        !person.editable
                                                    "
                                                class="form-select"
                                                name="bookingStatus"
                                            >
                                                <option value="PENDING">
                                                    PENDING
                                                </option>
                                                <option value="COMPLETED">
                                                    COMPLETED
                                                </option>
                                                <option value="CANCELLED">
                                                    CANCELLED
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <select
                                                #paymentStatus="ngModel"
                                                [(ngModel)]="
                                                        person.paymentStatus
                                                    "
                                                [disabled]="
                                                        !person.editable
                                                    "
                                                class="form-select"
                                                name="paymentStatus"
                                            >
                                                <option value="DUE">
                                                    DUE
                                                </option>
                                                <option value="RECEIVED">
                                                    RECEIVED
                                                </option>
                                                <option
                                                    value="COLLECTED BY ASIGNEE"
                                                >
                                                    COLLECTED BY ASSIGNEE
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            {{
                                                person.hours
                                                    | number : "1.0-2"
                                            }}
                                        </td>
                                        <td class="text-nowrap">
                                            {{
                                                person.startTime
                                                    | date : "HH:MM"
                                            }}
                                        </td>
                                        <td class="text-nowrap">
                                            {{
                                                person.endTime
                                                    | date : "HH:mm"
                                            }}
                                        </td>
                                        <td>
                                            <input
                                                #ratePerHour="ngModel"
                                                [(ngModel)]="
                                                        person.ratePerHour
                                                    "
                                                [disabled]="
                                                        !person.editable
                                                    "
                                                name="ratePerHour"
                                                type="text"
                                            />
                                        </td>
                                        <td>{{ person.material }}</td>
                                        <td>{{ person.totalAmount }}</td>
                                        <td>{{ person.description }}</td>
                                        <td
                                            class="d-flex gap-2 align-items-center"
                                        >
                                            @if (person.editable) {
                                                <button
                                                    class="btn btn-success d-flex gap-2 align-items-center"
                                                    (click)="saveBooking()"
                                                    [disabled]="reqProcessing"
                                                >
                                                    @if (reqProcessing) {
                                                        <span
                                                            class="spinner-border spinner-border-sm"
                                                            aria-hidden="true"
                                                        ></span>
                                                        <span role="status"
                                                        >Loading...</span
                                                        >
                                                    } @else {
                                                        <span>Save</span>
                                                    }
                                                </button>
                                                <button
                                                    class="btn btn-danger"
                                                    (click)="
                                                        person.editable = false;
                                                        cancelEditing()
                                                    "
                                                >
                                                    Cancel
                                                </button>
                                            } @else {
                                                <button
                                                    class="btn btn-primary"
                                                    (click)="
                                                        person.editable = true;
                                                        editBooking({
                                                            bookingId:
                                                                person._id,
                                                            bookingStatus,
                                                            paymentStatus,
                                                            ratePerHour
                                                        })
                                                    "
                                                    [disabled]="
                                                        editForm?.bookingId
                                                            ? editForm.bookingId !==
                                                              person._id
                                                            : false
                                                    "
                                                >
                                                    Edit
                                                </button>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                    <tbody *ngIf="users?.length == 0">
                                    <tr>
                                        <td
                                            [colSpan]="
                                                    dtOptions.columns?.length
                                                "
                                            class="no-data-available text-center"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
