import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { SharedModule } from "../../shared/shared.module";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { HttpClient } from "@angular/common/http";
import { UtilsService } from "src/app/shared/services/utils.service";
import { CONFIG } from "config";
import { DateUtils } from "src/app/utils/date-utils";
import { DatePipe, NgClass, NgFor, NgIf } from "@angular/common";
import { DataSharingService } from "src/app/shared/services/data-sharing.service";
import { BackendService } from "src/app/shared/services/backend.service";

@Component({
    selector: "app-attendance",
    standalone: true,
    templateUrl: "./attendance.component.html",
    styleUrls: ["./attendance.component.scss"],
    imports: [DataTablesModule, SharedModule, DatePipe, NgFor, NgClass, NgIf],
})
export class AttendanceComponent implements OnInit {
    // Datatables configurations
    @ViewChild(DataTableDirective, { static: false })
    dataTableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};

    formattedStartDate: string;
    formattedEndDate: string;

    dataTableParam: any;
    // Records to be fetched from the api
    attendanceRec: any;
    employeeList: any[];

    navigation = "select";
    displayMonths = 1;
    outsideDays = "visible";
    showWeekNumbers = true; // Define the showWeekNumbers property here

    // For filter date inputs property binding
    startDate: NgbDate;
    endDate: NgbDate;

    constructor(
        private http: HttpClient,
        private utils: UtilsService,
        private data: DataSharingService,
        private backendService:BackendService,
    ) {}

    ngOnInit(): void {
        const currentDate = new Date();
        this.endDate = DateUtils.createNgbDate(currentDate);

        currentDate.setDate(currentDate.getDate() - 10);
        this.startDate = DateUtils.createNgbDate(currentDate);

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;

        this.dtOptions = {
            paging: false,
            info: false,
            serverSide: true,
            searching: false,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getAllAttendance,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.attendanceRec = resp.data.original;

                            // Sort records based on date
                            if (
                                that.attendanceRec &&
                                that.attendanceRec.length
                            ) {
                                that.attendanceRec.sort((a: any, b: any) => {
                                    return (
                                        new Date(a.date).getTime() -
                                        new Date(b.date).getTime()
                                    );
                                });
                            }
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { name: "date", orderable: true },
                { name: "employeeName", orderable: true },
                { name: "email", orderable: true },
                { name: "present", orderable: true },
            ],
        };

        this.backendService.employeeList({}).subscribe((list:any)=>{
            this.employeeList = list.data;
        })
        // this.data.getEmployeeList().subscribe((value: any[]) => {
        //     this.employeeList = value;
        // });
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    onDateChange(): void {
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    findPresence(records: any[], currentEmployee: any): boolean {
        return records.find(
            (item) => item?.employee?._id === currentEmployee?._id
        )?.present;
    }
}
