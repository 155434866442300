<div class="media profile-media">
    <img alt="" class="b-r-10" src="assets/images/dashboard/profile.png"/>
    <div class="media-body">
        <span>{{ userName }}</span>
        <p class="mb-0 font-roboto">
            {{ role }}<i class="middle fa fa-angle-down"></i>
        </p>
    </div>
</div>
<ul class="profile-dropdown onhover-show-div" style="width: 200px; left: -70px">
    <li (click)="Settings()" *ngIf="role === 'admin' || role === 'super'" class="d-flex">
        <app-feather-icons [icon]="'settings'"></app-feather-icons>
        <span>Settings</span>
    </li>
    <li (click)="changePassFunc()" *ngIf="role !== 'employee'" class="d-flex">
        <app-feather-icons [icon]="'edit'"></app-feather-icons
        >
        <span>Change Password</span>
    </li>
    <li (click)="ActivityLogs()" class="d-flex">
        <app-feather-icons [icon]="'activity'"></app-feather-icons
        >
        <span>Activity Logs</span>
    </li>

    <li (click)="logoutFunc()">
        <app-feather-icons [icon]="'log-in'"></app-feather-icons
        >
        <span>Log out</span>
    </li>
</ul>
