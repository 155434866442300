<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card login-dark">
                <div>
                    <div>
                        <a class="logo" routerLink="/">
                            <img
                                class="img-fluid for-light"
                                src="assets/images/logo/logo.png"
                                alt="looginpage"
                            />
                            <img
                                class="img-fluid for-dark"
                                src="assets/images/logo/logo_dark.png"
                                alt="looginpage"
                            />
                        </a>
                    </div>
                    <div class="login-main">
                        <form
                            class="theme-form"
                            *ngIf="isforgot"
                            [formGroup]="ForgotPasswordForm"
                        >
                            <h4>Forgot Password</h4>

                            <div class="form-group">
                                <label class="col-form-label"
                                    >Email Address</label
                                >
                                <input
                                    class="form-control"
                                    type="email"
                                    required=""
                                    placeholder="app@yourmail.com"
                                    formControlName="email"
                                    (input)="EmailIputVal($event)"
                                />
                                <div
                                    *ngIf="
                                        ForgotPasswordForm.controls.email
                                            .touched &&
                                        ForgotPasswordForm.controls.email.errors
                                            ?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Email is required
                                </div>
                                <div
                                    *ngIf="
                                        ForgotPasswordForm.controls.email
                                            .touched &&
                                        ForgotPasswordForm.controls.email.errors
                                            ?.email
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Invalid Email
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <!-- forgot in button -->
                                <div class="text-end mt-3">
                                    <button
                                        class="btn btn-primary d-block w-100"
                                        [disabled]="!ForgotPasswordForm.valid"
                                        (click)="forgot()"
                                        type="submit"
                                    >
                                        <span>Forgot Password</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <form
                            class="theme-form"
                            *ngIf="!isforgot"
                            [formGroup]="OtpForm"
                        >
                            <!-- Disabled Email Field -->
                            <div class="form-group">
                                <label class="col-form-label"
                                    >Email Address</label
                                >
                                <input
                                    class="form-control"
                                    type="email"
                                    [value]="forgotEmailVal"
                                    disabled
                                />
                            </div>

                            <div class="form-group">
                                <label class="col-form-label">OTP</label>
                                <div class="otp-field mb-4">
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitOne"
                                        #otp1
                                        (keydown)="
                                            moveToNext($event, otp1, otp2, null)
                                        "
                                        (input)="
                                            moveToNext($event, otp1, otp2, null)
                                        "
                                    />
                                    <span class="m-1">-</span>
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitTwo"
                                        #otp2
                                        (keydown)="
                                            moveToNext($event, otp2, otp3, otp1)
                                        "
                                        (input)="
                                            moveToNext($event, otp2, otp3, otp1)
                                        "
                                    />
                                    <span class="m-1">-</span>
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitThree"
                                        #otp3
                                        (keydown)="
                                            moveToNext($event, otp3, otp4, otp2)
                                        "
                                        (input)="
                                            moveToNext($event, otp3, otp4, otp2)
                                        "
                                    />
                                    <span class="m-1">-</span>
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitFour"
                                        #otp4
                                        (keydown)="
                                            moveToNext($event, otp4, otp5, otp3)
                                        "
                                        (input)="
                                            moveToNext($event, otp4, otp5, otp3)
                                        "
                                    />
                                    <span class="m-1">-</span>
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitFive"
                                        #otp5
                                        (keydown)="
                                            moveToNext($event, otp5, otp6, otp4)
                                        "
                                        (input)="
                                            moveToNext($event, otp5, otp6, otp4)
                                        "
                                    />
                                    <span class="m-1">-</span>
                                    <input
                                        type="number"
                                        class="form-control"
                                        required
                                        formControlName="otpDigitSix"
                                        #otp6
                                        (keydown)="
                                            moveToNext($event, otp6, null, otp5)
                                        "
                                        (input)="
                                            moveToNext($event, otp6, null, otp5)
                                        "
                                    />
                                </div>

                                <div
                                    *ngIf="
                                        OtpForm.controls.otpDigitOne.touched &&
                                        OtpForm.controls.otpDigitOne.errors
                                            ?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Please Enter Your OTP
                                </div>
                            </div>
                            <!-- New Password Field-->
                            <div class="form-group">
                                <label class="col-form-label"
                                    >New Password</label
                                >
                                <input
                                    class="form-control"
                                    type="password"
                                    formControlName="newPassword"
                                    placeholder="Enter your new password"
                                    required
                                />
                                <div
                                    *ngIf="
                                        OtpForm.controls.newPassword.touched &&
                                        OtpForm.controls.newPassword.errors
                                            ?.required
                                    "
                                    class="text text-danger mt-1"
                                >
                                    Password is required
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <div class="text-end mt-3">
                                    <p>Time remaining: {{ timerDisplay }}</p>
                                    <button
                                        class="btn btn-primary d-block w-100"
                                        [disabled]="!OtpForm.valid"
                                        (click)="OTP()"
                                        type="submit"
                                    >
                                        <span>Verify</span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
