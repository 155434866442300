import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  private loadingSubscription: Subscription;
  public showLoader: boolean = false;

  constructor(private loadingBar: LoadingBarService) { }

  ngOnInit(): void {
    this.loadingSubscription = this.loadingBar.progress$.subscribe(progress => {
      if (progress > 0 && progress < 100) {
        this.showLoader = true;
      } else {
        this.showLoader = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}