<app-breadcrumb
    [title]="'Client Subscription'"
    [items]="['Settings']"
    active_item="Client Subscription"
></app-breadcrumb>

<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <button class="btn btn-primary" style="float: right">
                        Add Client Subscription
                    </button>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th class="suspense">
                                            Subscription Name
                                        </th>
                                        <th>Client Name</th>
                                        <th>Phone Number</th>
                                        <th>Category</th>
                                        <th>Hours</th>
                                        <th>Visits</th>
                                        <th>Rate Per Hour</th>
                                        <th>Price</th>
                                        <th>count</th>
                                        <th>Status</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="clientSubscriptions?.length != 0">
                                    <tr
                                        *ngFor="
                                            let clientSubscription of clientSubscriptions;
                                            let i = index
                                        "
                                    >
                                        <td>{{ i + 1 }}</td>
                                        <td class="suspense">
                                            {{
                                                clientSubscription?.subscription
                                                    ?.subscriptionName
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.client?.name
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.client
                                                    ?.phoneNumber
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.subscription
                                                    ?.category?.categoryName
                                            }}
                                        </td>
                                        <td
                                            [ngClass]="{
                                                'text-danger':
                                                    clientSubscription?.remainingHours <=
                                                    0
                                            }"
                                        >
                                            {{
                                                clientSubscription?.remainingHours
                                            }}
                                            /
                                            {{
                                                clientSubscription?.subscription
                                                    ?.totalHours
                                            }}
                                        </td>
                                        <td
                                            [ngClass]="{
                                                'text-danger':
                                                    clientSubscription?.remainingVisits <=
                                                    0
                                            }"
                                        >
                                            {{
                                                clientSubscription?.remainingVisits
                                            }}
                                            /
                                            {{
                                                clientSubscription?.subscription
                                                    ?.totalVisits
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.subscription
                                                    ?.ratePerHour
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.subscription
                                                    ?.price
                                            }}
                                        </td>
                                        <td>
                                            {{ clientSubscription?.count }}
                                        </td>
                                        <td>
                                            <select
                                                name="status"
                                                id="status"
                                                [(ngModel)]="
                                                    clientSubscription.status
                                                "
                                                (ngModelChange)="
                                                    onSubscriptionStatusChange(
                                                        clientSubscription
                                                            ?.client?._id,
                                                        clientSubscription?._id,
                                                        $event
                                                    )
                                                "
                                            >
                                                <option value="COMPLETED">
                                                    COMPLETED
                                                </option>
                                                <option value="SUSPENDED">
                                                    SUSPENDED
                                                </option>
                                                <option value="ACTIVE">
                                                    ACTIVE
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            {{
                                                clientSubscription?.updatedAt
                                                    | date : "short"
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="clientSubscriptions?.length == 0">
                                    <tr>
                                        <td
                                            [colSpan]="
                                                dtOptions?.columns.length + 1
                                            "
                                            class="no-data-available text-center"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add/Edit subscription modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modal_title }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modalService.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="clientSubscriptionForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="name" class="form-label">Name</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    formControlName="name"
                    placeholder="Enter Name"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('name').invalid &&
                        clientSubscriptionForm.get('name').touched
                    "
                    class="text-danger"
                >
                    Name is required
                </div>
            </div>

            <div class="mb-3">
                <label for="email" class="form-label">Email</label>
                <input
                    type="email"
                    class="form-control"
                    id="email"
                    formControlName="email"
                    placeholder="Enter Email"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('email').invalid &&
                        clientSubscriptionForm.get('email').touched
                    "
                    class="text-danger"
                >
                    Valid email is required
                </div>
            </div>

            <div class="mb-3">
                <label for="phoneNumber" class="form-label">Phone Number</label>
                <input
                    type="tel"
                    class="form-control"
                    id="phoneNumber"
                    formControlName="phoneNumber"
                    placeholder="Enter Phone Number"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('phoneNumber').invalid &&
                        clientSubscriptionForm.get('phoneNumber').touched
                    "
                    class="text-danger"
                >
                    Valid phone number is required (e.g., +1234567890)
                </div>
            </div>

            <div class="mb-3">
                <label for="address" class="form-label">Address</label>
                <input
                    type="text"
                    class="form-control"
                    id="address"
                    formControlName="address"
                    placeholder="Enter Address"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('address').invalid &&
                        clientSubscriptionForm.get('address').touched
                    "
                    class="text-danger"
                >
                    Address is required
                </div>
            </div>

            <div class="mb-3">
                <label for="area" class="form-label">Area</label>
                <input
                    type="text"
                    class="form-control"
                    id="area"
                    formControlName="area"
                    placeholder="Enter Area"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('area').invalid &&
                        clientSubscriptionForm.get('area').touched
                    "
                    class="text-danger"
                >
                    Area is required
                </div>
            </div>

            <div class="mb-3">
                <label for="customerType" class="form-label"
                    >Customer Type</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="customerType"
                    formControlName="customerType"
                    placeholder="Enter Customer Type"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('customerType').invalid &&
                        clientSubscriptionForm.get('customerType').touched
                    "
                    class="text-danger"
                >
                    Customer Type is required
                </div>
            </div>

            <div class="mb-3">
                <label for="pinLocation" class="form-label">PIN Location</label>
                <input
                    type="text"
                    class="form-control"
                    id="pinLocation"
                    formControlName="pinLocation"
                    placeholder="Enter PIN Location"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('pinLocation').invalid &&
                        clientSubscriptionForm.get('pinLocation').touched
                    "
                    class="text-danger"
                >
                    PIN Location is required
                </div>
            </div>

            <div class="mb-3">
                <label for="emiratesID" class="form-label">Emirates ID</label>
                <input
                    type="text"
                    class="form-control"
                    id="emiratesID"
                    formControlName="emiratesID"
                    placeholder="Enter Emirates ID"
                />
                <div
                    *ngIf="
                        clientSubscriptionForm.get('emiratesID').invalid &&
                        clientSubscriptionForm.get('emiratesID').touched
                    "
                    class="text-danger"
                >
                    Valid Emirates ID is required (15 digits)
                </div>
            </div>

            <button
                type="submit"
                class="btn btn-primary"
                [disabled]="clientSubscriptionForm.invalid"
            >
                {{ modal_button }}
            </button>
        </form>
    </div>
</ng-template>
