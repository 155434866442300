import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.component.html',
    styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
    userName: string;
    role: string;
    public profileImg: 'assets/images/dashboard/profile.jpg';

    constructor(public router: Router, private authService: AuthService) {
        if (JSON.parse(localStorage.getItem('user'))) {
        } else {
        }
    }

    ngOnInit() {
        this.userName = localStorage.getItem('userName');
        this.role = localStorage.getItem('role').toLowerCase();
    }

    changePassFunc() {
        this.router.navigateByUrl('auth/change-password');
    }
    Settings(){
        this.router.navigateByUrl('/settings');
    }
    ActivityLogs() {
        this.router.navigateByUrl('/activity-Logs');
    }

    logoutFunc() {

        this.authService.logout();
        this.router.navigateByUrl('auth/login');
    }
}
