import {Component, ViewChild} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CONFIG} from "../../../../config";
import {BackendService} from "../../shared/services/backend.service";
import {Observable} from "rxjs";
import {NgFor, NgForOf, NgIf} from "@angular/common";
import {
    EmployeeBookingHistoryComponent
} from "../employees/employee-booking-history/employee-booking-history.component";
import {
    EmployeeAccountHistoryComponent
} from "../employees/employee-account-history/employee-account-history.component";
import {DataSharingService} from "src/app/shared/services/data-sharing.service";
import Swal from "sweetalert2";
import {AuthService} from "src/app/service/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: "app-employees",
    standalone: true,
    imports: [
        EmployeeBookingHistoryComponent,
        EmployeeAccountHistoryComponent,
        SharedModule,
        DataTablesModule,
        NgIf,
        NgForOf,
        NgFor,
    ],
    templateUrl: "./employees.component.html",
    styleUrl: "./employees.component.scss",
})
export class EmployeesComponent {
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, {static: false})
    dtElement!: DataTableDirective;
    public selected = [];

    @ViewChild("bookingHistoryContent") bookingHistoryContent: any;
    @ViewChild("accountHistoryContent") accountHistoryContent: any;
    @ViewChild("content") content: any;
    @ViewChild("depositContent") depositContent: any;
    @ViewChild("passwordChangeContent") passwordChangeContent: any;

    users: any[] = [];
    categories: any = [];
    navigation = "select";
    total$: Observable<number>;
    userForm!: FormGroup;
    persons: any[] = [
        {id: 1, firstName: "John", lastName: "Doe", action: "deposit"},
        {id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw"},
        {id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle"},
        {id: 4, firstName: "Bob", lastName: "Brown", action: "delete"},
    ];
    dataTableParam: any;
    DwTitle: any;
    transactionForm: FormGroup;
    employeeForm: FormGroup;
    passwordChangeForm: FormGroup;
    show: boolean = false;
    userId: string = "";
    modal_title: string = "";
    modal_button: string = "";
    selectedUserId: string | null = null;
    loginRole: any;

    // Deposit Withdraw variables
    currentEmployee: any;
    currentUser: any;

    // Track loading state of form
    reqProcessing: boolean = false;

    // All Totals data
    total: any;
    isActive: boolean = true;
    showPassword: boolean = false;
    showNewPassword: boolean = false;
    showMasterPassword: boolean = false;
    selectedRole: string = localStorage.getItem("role");

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private backend: BackendService,
        private http: HttpClient,
        private modalService: NgbModal,
        private dataSharingService: DataSharingService,
        private router: Router
    ) {
        this.employeeForm = this.fb.group({
            totalSalary: ["", [Validators.required, Validators.min(0)]],
            emergencyContact: ["", Validators.required],
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            password: ["", [Validators.required, Validators.minLength(8)]],
            role: ["", Validators.required],
            phoneNumber: [
                "",
                [Validators.required, Validators.pattern("^\\d{10}$")],
            ],
            address: ["", Validators.required],
            categoryId: ["", Validators.required],
            emiratesID: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^\\d{3}-\\d{4}-\\d{7}-\\d{1}$"),
                ],
            ],
        });
        this.transactionForm = this.fb.group({
            userId: ["", Validators.required],
            remarks: [""],
            amount: [0, [Validators.required, Validators.min(1)]],
        });
        this.passwordChangeForm = this.fb.group({
            userId: ["", Validators.required],
            newPassword: ["", [Validators.required, Validators.minLength(8)]],
        });
    }

    ngOnInit(): void {
        this.currentUser = localStorage.getItem("role")?.toLowerCase();
        console.log(this.currentUser);
        this.getCategories();
        this.initializeDataTable();
    }

    initializeDataTable(): void {
        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                const payload = {
                    ...dataTablesParameters,
                    userId: this.selectedUserId,
                };
                that.http
                    .post<any>(CONFIG.getEmployeeData, payload)
                    .subscribe((resp) => {
                        const usersDataForBotton = resp.data.original.forEach(
                            (element) => {
                                if (element.parent.role == "ADMIN") {
                                    this.isActive = true;
                                } else {
                                    this.isActive = false;
                                }
                            }
                        );
                        let data = resp.data?.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.users = data;
                            that.total = resp.data?.total;
                        }
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                {data: "Id"},
                {data: "name"},
                {data: "email"},
                {data: "phoneNumber"},
                {data: "address"},
                {data: "categoryName"},
                {data: "totalSalary"},
                {data: "actions"},
            ],
        };
    }

    onNameClick(userId: string): void {
        this.selectedUserId = this.selectedUserId === userId ? null : userId;
        const table = $("#example").DataTable();
        table.ajax.reload();
        const role = localStorage.getItem("user");
        this.loginRole = JSON.parse(role).role.toLowerCase();
        console.log(this.loginRole);
        if (this.loginRole == "super") {
            this.isActive = true;
        }
    }

    // API calls
    updateStatus(event: any, user: any) {
        const status = event.target.checked ? "active" : "suspended";

        const reqBody = {
            userId: user._id,
            status,
        };

        this.backend.updateEmployee(reqBody).subscribe({
            next: (response) => {
                console.log(response);
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    openModal() {
        this.modal_title = "Add Employee";
        this.modal_button = "Submit";
        this.employeeForm.reset();
        this.modalService
            .open(this.content, {ariaLabelledBy: "modal-basic-title"})
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reasond) => {
                // Handle close/dismiss
            }
        );

        // Fetch categories when the modal is opened
    }

    getCategories() {
        this.dataSharingService.getAllCategories().subscribe((value: any) => {
            this.categories = value;
        });
    }

    deposit(person) {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
        });
    }

    withdraw(person) {
    }

    toggleStatus(person) {
    }

    delete(expenseId: string) {
        // this.backend.deleteEmployee(expenseId).subscribe(data => {
        // Swal.fire({
        //     position: "top-end",
        //     icon: "success",
        //     title: "Employee deleted successfully.",
        //     showConfirmButton: false,
        //     timer: 1500
        // });
        //     this.statement = data.data;
        // },(error) => {
        // Error callback
        //     Swal.fire({
        //         position: 'top-end',
        //         icon: 'error',
        //         title: 'Failed to delete Employee.',
        //         showConfirmButton: false,
        //         timer: 2500
        //     });
        // }
        // );
    }

    onSubmit() {
        this.reqProcessing = true;
        if (this.employeeForm.invalid) {
            this.reqProcessing = false;
            return;
        }
        if (this.modal_title === "Add Employee") {
            this.backend.addEmployee(this.employeeForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Employee added successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    // this.notificationService.success(data.meta.message);
                    this.closeModal();
                    this.employeeForm.reset();
                    this.rerender();
                    this.reqProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: error.error.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.reqProcessing = false;
                },
            });
        } else if (this.modal_title === "Edit Employee") {
            const userId = this.userId;
            this.employeeForm.addControl("userId", this.fb.control(userId));
            this.backend.updateEmployee(this.employeeForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Employee updated successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.rerender();
                    this.closeModal();
                    this.reqProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: error.error.message,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.reqProcessing = false;
                },
            });
        }
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    openDeposit(user: any) {
        this.transactionForm.patchValue({
            userId: user._id,
        });

        this.currentEmployee = user;

        this.modalService.open(this.depositContent);
    }

    openChangePass(employee: any) {
        this.currentEmployee = employee;
        this.passwordChangeForm.patchValue({userId: employee?._id});

        this.modalService.open(this.passwordChangeContent);
    }

    // Deposit Withdraw logic
    selectAmount(event: MouseEvent) {
        const target = event.target as HTMLFormElement;
        target["select"]();
    }

    onSubmitDw(transactionType: string) {
        this.reqProcessing = true;
        const options = this.transactionForm.value;

        if (transactionType.toLowerCase() === "w") {
            options.amount = options.amount * -1;
        }

        this.backend.employeeDepositWithdraw(options).subscribe({
            next: (response) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: response?.message,
                    showConfirmButton: false,
                    timer: 1500,
                });

                this.transactionForm.reset();
                this.modalService.dismissAll();
                this.rerender();
                this.reqProcessing = false;
            },
            error: (error) => {
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: error?.error.message,
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.reqProcessing = false;
            },
        });
    }

    onSubmitCp() {
        this.reqProcessing = true;
        this.authService
            .changePassword(this.passwordChangeForm.value)
            .subscribe({
                next: (response) => {
                    this.modalService.dismissAll();
                    this.passwordChangeForm.reset();
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: response?.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.reqProcessing = false;
                },
                error: (error) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: error?.error.message,
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.reqProcessing = false;
                },
            });
    }

    showHideNewPassword() {
        this.showNewPassword = !this.showNewPassword;
    }

    showHideMasterPassword() {
        this.showMasterPassword = !this.showMasterPassword;
    }

    // Patch Values Call
    patchEmployeeFormValues(user: any) {
        this.employeeForm.patchValue({
            name: user.name,
            email: user.email,
            password: user.password,
            totalSalary: user.totalSalary,
            advance: user.advance,
            role: user.role,
            phoneNumber: user.phoneNumber,
            address: user.address,
            categoryId: user.category?._id,
            emiratesID: user.emiratesID,
            salaryTaken: user.salaryTaken,
            emergencyContact: user.emergencyContact,
        });
    }

    // Modals
    openEditEmployeeModal(user: any) {
        this.modal_title = "Edit Employee";
        this.modal_button = "Update";
        this.userId = user._id;
        this.patchEmployeeFormValues(user);
        this.modalService.open(this.content, {
            ariaLabelledBy: "modal-basic-title",
        });
    }

    openAccountModal(id: string, name: string) {
        this.dataSharingService.setUserId(id);
        this.dataSharingService.setName(name);
        this.modalService
            .open(this.accountHistoryContent, {
                ariaLabelledBy: "modal-basic-title",
                size: "xl",
                centered: true,
            })
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    openBookingModal(id, name) {
        this.dataSharingService.setBookingId(id);
        this.dataSharingService.setName(name);
        this.modalService
            .open(this.bookingHistoryContent, {
                ariaLabelledBy: "modal-basic-title",
                size: "xl",
                centered: true,
            })
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    navigateToAdminSettings(adminId: string): void {
        this.router.navigate(['/settings', adminId]);
    }
}
