import { Component, ViewChild } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";
import { FormBuilder, Validators } from "@angular/forms";
import { BackendService } from "../../shared/services/backend.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForOf, NgIf } from "@angular/common";
import { DataTableDirective } from "angular-datatables";
import Swal from "sweetalert2";

@Component({
    selector: "app-category",
    standalone: true,
    imports: [SharedModule, NgForOf, NgIf],
    templateUrl: "./category.component.html",
    styleUrl: "./category.component.scss",
})
export class CategoryComponent {
    categoryForm: any;
    @ViewChild(DataTableDirective)
    datatableElement!: DataTableDirective;
    categories: any[] = [];
    @ViewChild("content") content: any;
    modal_title: string;
    modal_button: string;
    categoryId: any;

    // Track forms loading state
    reqProcessing: boolean = false;

    constructor(
        private fb: FormBuilder,
        private backend: BackendService,
        private modalService: NgbModal
    ) {
        this.categoryForm = this.fb.group({
            categoryName: ["", Validators.required],
        });
    }

    ngOnInit(): void {
        this.getCategories();
    }

    openModal() {
        this.modal_title = "Add Category";
        this.modal_button = "Submit";
        this.categoryForm.reset();
        this.modalService
            .open(this.content, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // Handle close/dismiss
                },
                (reason) => {
                    // Handle close/dismiss
                }
            );
    }

    delete(person) {}

    onSubmit() {
        this.reqProcessing = true;
        if (this.modal_title === "Add Category") {
            this.backend.addCategory(this.categoryForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Category added successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.getCategories();
                    this.closeModal();
                    this.categoryForm.reset();
                    this.reqProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Failed to update booking.",
                        text: error.message || "An error occurred.",
                        showConfirmButton: false,
                    });
                    this.reqProcessing = false;
                },
            });
        } else if (this.modal_title === "Edit Category") {
            const categoryId = this.categoryId;
            this.categoryForm.addControl(
                "categoryId",
                this.fb.control(categoryId)
            );
            this.backend.updateCategory(this.categoryForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Category updated successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.getCategories();
                    this.modalService.dismissAll();
                    this.reqProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Failed to update Category.",
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.reqProcessing = false;
                },
            });
        }
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    openDeposit(w: string, user: any) {}

    getCategories() {
        this.backend.allCategories().subscribe((data) => {
            this.categories = data.data;
        });
    }

    deleteCategories(categoryId: string) {
        this.backend.deleteCategory(categoryId).subscribe({
            next: (data) => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Category deleted successfully.",
                    showConfirmButton: false,
                    timer: 1500,
                });
                this.getCategories();
            },
            error: (error) => {
                // Error callback
                Swal.fire({
                    position: "top-end",
                    icon: "error",
                    title: "Failed to delete Category.",
                    showConfirmButton: false,
                    timer: 2500,
                });
            },
        });
    }
    // Patch Values Call
    patchCategoryFormValues(category: any) {
        this.categoryId = category._id;
        this.categoryForm.patchValue({
            categoryName: category.categoryName,
        });
    }
    // onClick Edit Button
    openEditCategoryModal(category: any) {
        this.modal_title = "Edit Category";
        this.modal_button = "Update";
        this.patchCategoryFormValues(category);
        this.modalService.open(this.content, {
            ariaLabelledBy: "modal-basic-title",
        });
    }
}
