import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CONFIG } from '../../../config';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
   
    constructor(private http: HttpClient, private router: Router) {}

    login(email: string, password: string ,ipInfo:any): Observable<any> {
        return this.http.post(CONFIG.login, { email, password ,ipInfo }).pipe(
            tap((response: any) => {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem(
                    'user',
                    JSON.stringify(response.data.userDetails)
                );
            })
        );
    }
    getIpLocation(): Observable<any> {
        return this.http.post(CONFIG.ipInfo, {})
      }
    changePassword(reqBody: { currentPassword: string, newPassword: string }): Observable<any> {
        return this.http.post(CONFIG.changePass, reqBody);
    }

    logout() {
        localStorage.clear();
        this.router.navigate(['/auth/login']);
    }
}
