<app-breadcrumb
    [title]="'Category'"
    [items]="['Settings']"
    active_item="Category"
></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="p-2">
                    <button
                        class="btn btn-primary"
                        style="float: right"
                        (click)="openModal()"
                    >
                        Add Category
                    </button>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <div class="mt-4">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Category Name</th>
                                            <th class="actions">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="
                                                let category of categories;
                                                let i = index
                                            "
                                        >
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ category.categoryName }}</td>

                                            <td class="d-flex gap-1 actions">
                                                <button
                                                    class="btn btn-pill btn-primary-gradien"
                                                    type="button"
                                                    data-bs-original-title=""
                                                    title=""
                                                    (click)="
                                                        openEditCategoryModal(
                                                            category
                                                        )
                                                    "
                                                >
                                                    Edit
                                                </button>

                                                <button
                                                    class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                                    type="button"
                                                    data-bs-original-title=""
                                                    title=""
                                                    (click)="
                                                        deleteCategories(
                                                            category._id
                                                        )
                                                    "
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modal_title }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="categoryName" class="form-label"
                    >Category Name</label
                >
                <input
                    type="text"
                    class="form-control"
                    id="categoryName"
                    formControlName="categoryName"
                    placeholder="Enter Category Name"
                    required
                />
                <div
                    *ngIf="
                        categoryForm.get('categoryName').invalid &&
                        categoryForm.get('categoryName').touched
                    "
                    class="text-danger"
                >
                    Category Name is required
                </div>
            </div>
            <button
                type="submit"
                class="btn btn-primary d-flex gap-2 align-items-center"
                [disabled]="reqProcessing"
            >
                @if (reqProcessing) {
                <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                ></span>
                <span role="status">Loading...</span>
                } @else {
                <span>{{ modal_button }}</span>
                }
            </button>
        </form>
    </div>
</ng-template>
