<div class="overlay">
  <div #modal [class.active]="pdfDataUri" class="modal bg-white rounded-4 d-flex flex-column shadow-lg">
    <div class="modal-header px-4 py-2 align-items-center">
      <h3>Quotation</h3>

      <button class="btn-close" (click)="closeQuotation()"></button>
    </div>

    <div class="modal-body">
      <iframe src="" class="h-100 w-100" #quotation type="application/pdf" frameborder="0"></iframe>
    </div>
  </div>
</div>