import {Component, OnInit} from '@angular/core';
import {BackendService} from "../../shared/services/backend.service";
import {ActivatedRoute} from "@angular/router";
import {CONFIG} from "../../../../config";

interface FileDetails {
    url: string;
    id?: string;
    fileName: string;
    isModified: boolean;
}

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
    details: { [key: string]: FileDetails } = {
        logo: {url: null, id: null, fileName: null, isModified: false},
        logoSmall: {url: null, id: null, fileName: null, isModified: false},
        favicon: {url: null, id: null, fileName: null, isModified: false},
        letterHeadPad: {url: null, id: null, fileName: null, isModified: false}
    };

    role: string | null = null;
    adminId: string | null = null;
    themeColors = {primaryColor: '#7366ff'};
    originalThemeColor: string = '#7366ff'; // Default or initial color
    themeConfig: any = {};

    constructor(private backendService: BackendService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.adminId = params['adminId'];
        });
        this.role = localStorage.getItem('role').toLowerCase();
        this.updateThemeFromConfig();

        if (this.role === 'super' && this.adminId) {
            this.fetchThemeFromBackend();
        }
    }

    updateThemeFromConfig(): void {
        this.themeConfig = JSON.parse(localStorage.getItem('themeConfig') as string);
        this.originalThemeColor = this.themeConfig.config['themeColor'];
        this.themeColors.primaryColor = this.themeConfig.config['themeColor'];

        for (const key of Object.keys(this.details)) {
            if (this.themeConfig[key]) {
                this.details[key] = {
                    url: this.themeConfig[key].driveId,
                    fileName: this.themeConfig[key].fileName,
                    isModified: false
                };
            }
        }
    }

    fetchThemeFromBackend(): void {
        const payload = {userId: this.adminId};
        this.backendService.getTheme(payload).subscribe(
            response => this.updateThemeFromBackendResponse(response.data[0]),
            error => console.error('Failed to fetch theme settings from backend', error)
        );
    }

    updateThemeFromBackendResponse(response: any): void {
        this.themeColors.primaryColor = response.config['themeColor'];

        for (const key of Object.keys(this.details)) {
            if (response[key]) {
                this.details[key] = {
                    url: `${CONFIG.getFile}${response[key].driveId}`,
                    fileName: response[key].fileName,
                    isModified: false
                };
            }
        }
    }

    getFileLabel(type: string): string {
        const labels = {
            logo: 'Logo',
            logoSmall: 'Small Logo',
            favicon: 'Favicon',
            letterHeadPad: 'Letterhead Pad'
        };
        return labels[type] || 'File';
    }

    onFileSelected(event: any, type: string): void {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => this.handleFileLoad(e, file, type);
            reader.readAsDataURL(file);
            this.uploadFile(file, type);
        }
    }

    handleFileLoad(event: any, file: File, type: string): void {
        const details = this.details[type];
        details.url = event.target.result;
        details.fileName = file.name;
        details.isModified = true; // Mark as modified
    }

    uploadFile(file: File, type: string): void {
        const formData = new FormData();
        formData.append('file', file);
        this.backendService.uploadFile(formData).subscribe(
            response => this.handleFileUploadSuccess(response, type),
            error => console.error(`${type} upload failed`, error)
        );
    }

    handleFileUploadSuccess(response: any, type: string): void {
        console.log(`${type} upload successful`, response);
        this.details[type].id = response.file._id;
        this.details[type].isModified = true; // Mark as modified
    }


    saveSettings(): void {
        const settingsPayload: any = {};

        // Check if the theme color has been changed
        if (this.originalThemeColor !== this.themeColors.primaryColor) {
            settingsPayload.config = {themeColor: this.themeColors.primaryColor};
        }

        // Iterate over each detail to check if it has been modified
        Object.keys(this.details).forEach(key => {
            if (this.details[key].isModified) {
                // Include only the ID of the changed detail
                settingsPayload[key] = this.details[key].id;
            }
        });

        // Include user ID if applicable
        if (this.role === 'super' && this.adminId) {
            settingsPayload.userId = this.adminId;
        }

        // Check if there are any changes to send
        if (Object.keys(settingsPayload).length > 0) {
            this.backendService.addUpdateTheme(settingsPayload).subscribe(
                response => {
                    this.fetchThemeConfig();  // Optionally re-fetch config
                    // Reset modification state
                    Object.keys(this.details).forEach(key => this.details[key].isModified = false);
                    this.originalThemeColor = this.themeColors.primaryColor;
                },
                error => console.error('Failed to save settings', error)
            );
        } else {
            console.log('No changes to save');
        }
    }


    fetchThemeConfig(): void {
        this.backendService.getThemeConfig({});
    }
}
