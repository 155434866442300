import {Routes} from "@angular/router";
import {ClientsComponent} from "../../components/clients/clients.component";
import {ClientSubscriptionsComponent} from "../../components/client-subscriptions/client-subscriptions.component";
import {EmployeesComponent} from "../../components/employees/employees.component";
import {ProfitLossComponent} from "../../components/profit-loss/profit-loss.component";
import {SubscriptionsComponent} from "../../components/subscriptions/subscriptions.component";
import {ExpensesComponent} from "../../components/expenses/expenses.component";
import {CategoryComponent} from "../../components/category/category.component";
import {CashflowComponent} from "src/app/components/cashflow/cashflow.component";
import {ReportsComponent} from "../../components/reports/reports.component";
import {ListViewComponent} from "../../list-view/list-view/list-view.component";
import {AttendanceComponent} from "src/app/components/attendance/attendance.component";
import {CompanyProfileComponent} from "../../components/company-profile/company-profile.component";
import {QuotationComponent} from "../../components/quotation/quotation.component";
import {LoginActivityComponent} from "src/app/components/login-activity/login-activity.component";
import {SettingsComponent} from "src/app/components/settings/settings.component";

export const content: Routes = [
    {
        path: "simple-page",
        loadChildren: () =>
            import("../../components/simple-page/simple-page.module").then(
                (m) => m.SimplePageModule
            ),
    },
    {
        path: "booking",
        loadChildren: () =>
            import("../../components/single-page/single-page.module").then(
                (m) => m.SinglePageModule
            ),
    },
    {
        path: "employees",
        component: EmployeesComponent,
        pathMatch: "full",
    },
    {
        path: "clients",
        component: ClientsComponent,
        pathMatch: "full",
    },
    {
        path: "quotations",
        component: QuotationComponent,
        pathMatch: "full",
    },
    {
        path: "companyProfile",
        component: CompanyProfileComponent,
        pathMatch: "full",
    },
    {
        path: "clients/subscriptions",
        component: ClientSubscriptionsComponent,
        pathMatch: "full",
    },
    {
        path: "profit-loss",
        component: ProfitLossComponent,
        pathMatch: "full",
    },
    {
        path: "subscriptions",
        component: SubscriptionsComponent,
        pathMatch: "full",
    },
    {
        path: "expenses",
        component: ExpensesComponent,
        pathMatch: "full",
    },
    {
        path: "listView",
        component: ListViewComponent,
        pathMatch: "full",
    },
    {
        path: "category",
        component: CategoryComponent,
        pathMatch: "full",
    },
    {
        path: "attendance",
        component: AttendanceComponent,
        pathMatch: "full",
    },
    {
        path: "cashflow",
        component: CashflowComponent,
        pathMatch: "full",
    },
    {
        path: "activity-Logs",
        component: LoginActivityComponent,
        pathMatch: "full",
    },
    {path: 'settings', component: SettingsComponent, pathMatch: 'full'},
    {path: 'settings/:adminId', component: SettingsComponent, pathMatch: 'full'},
    {
        path: "reports",
        component: ReportsComponent,
        pathMatch: "full",
    },
];
