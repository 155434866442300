import {Component, ViewChild} from "@angular/core";
import {SharedModule} from "../../shared/shared.module";
import {DataTableDirective, DataTablesModule} from "angular-datatables";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BackendService} from "../../shared/services/backend.service";
import {HttpClient} from "@angular/common/http";
import {NgbModal, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {CONFIG} from "../../../../config";
import {CommonModule, DatePipe, NgClass, NgForOf, NgIf,} from "@angular/common";
import {DataSharingService} from "../../shared/services/data-sharing.service";
import Swal from "sweetalert2";
import {ClientBookingHistoryComponent} from "./client-booking-history/client-booking-history.component";
import {ClientAccountHistoryComponent} from "./client-account-history/client-account-history.component";

@Component({
    selector: "app-clients",
    standalone: true,
    imports: [
        ClientAccountHistoryComponent,
        ClientBookingHistoryComponent,
        SharedModule,
        DataTablesModule,
        NgIf,
        NgForOf,
        NgClass,
        DatePipe,
        NgbTooltipModule,
        CommonModule,
    ],
    templateUrl: "./clients.component.html",
    styleUrl: "./clients.component.scss",
})
export class ClientsComponent {
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};

    moreActive: number = -1;

    public selected = [];
    @ViewChild("content") content: any;
    @ViewChild("bookingHistoryContent") bookingHistoryContent: any;
    @ViewChild("accountHistoryContent") accountHistoryContent: any;
    @ViewChild("subscriptionContent") subscriptionContent: any;
    @ViewChild("showSubscriptionsContent") showSubscriptionsContent: any;
    @ViewChild("depositContent") depositContent: any;
    formattedStartDate: any;
    formattedEndDate: any;
    clientSubscriptionForm: FormGroup;

    users: any[] = [];
    navigation = "select";
    userForm!: FormGroup;
    persons: any[] = [
        {id: 1, firstName: "John", lastName: "Doe", action: "deposit"},
        {id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw"},
        {id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle"},
        {id: 4, firstName: "Bob", lastName: "Brown", action: "delete"},
    ];
    dataTableParam: any;
    customerForm: FormGroup;
    transactionForm: FormGroup;
    show: boolean = false;
    categories: any[] = [];
    subscriptions: any[] = [];
    selectedSubscription: any;
    userId: any;
    bookingHistory: any;
    modal_title: string;
    modal_button: string;

    // Deposit Withdraw variables
    currentClient: any;
    // Subscriptions variables
    currentClientSubscriptions: any[] = [];

    // Track loading state of forms
    requestProcessing: boolean = false;
    dropdownVisible: boolean = false;
    dropdownStyle: any = {};
    // Tooltip config
    activeTooltipIndex = -1;
    inCellView: boolean = false;
    inTooltipView: boolean = false;
    startDate: any;
    endDate: any;

    constructor(
        private fb: FormBuilder,
        private backend: BackendService,
        private http: HttpClient,
        private modalService: NgbModal,
        private dataSharingService: DataSharingService
    ) {
        this.customerForm = this.fb.group({
            name: ["", Validators.required],
            email: ["", [Validators.required, Validators.email]],
            phoneNumber: [
                "",
                [Validators.required, Validators.pattern(/^\+\d{10,}$/)],
            ],
            address: ["", Validators.required],
            area: ["", Validators.required],
            customerType: ["", Validators.required],
            pinLocation: ["", Validators.required],
            emiratesID: ["", []],
            ntnNumber: ['']
        });
        this.clientSubscriptionForm = this.fb.group({
            categoryId: ["", Validators.required],
            clientId: ["", Validators.required],
            subscriptionId: ["", Validators.required],
        });
        this.transactionForm = this.fb.group({
            userId: ["", Validators.required],
            remarks: [""],
            amount: [0, [Validators.required, Validators.min(1)]],
        });
    }

    toggleDropdown(index: number) {
        if (index == this.moreActive) {
            this.moreActive = -1;
            return;
        }
        this.moreActive = index;
    }

    hideDropdown() {
        this.dropdownVisible = false;
        this.dropdownStyle = {
            display: "none",
        };
    }

    showTooltip(index: number) {
        this.activeTooltipIndex = index;
    }

    // Tooltip end

    hideTooltip() {
        if (this.inCellView || this.inTooltipView) return;
        this.activeTooltipIndex = -1;
    }

    chatOnWhatsapp(phoneNumber: string) {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;

        window.open(whatsappUrl, "_blank");
    }

    ngOnInit(): void {
        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getClientsData,
                        Object.assign(dataTablesParameters, {})
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;
                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.users = data;
                        }
                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                {data: "name", orderable: true},
                {data: "phoneNumber"},
                {data: "email"},
                {data: "address"},
                {data: "area"},
                {data: "customerType", orderable: true},
                {data: "pinLocation"},
                {data: "walletBallance", orderable: true},
            ],
        };

        this.getCategories();
    }

    openModal() {
        this.modal_title = "Add Client";
        this.modal_button = "Submit";
        this.customerForm.reset();
        this.modalService
            .open(this.content, {ariaLabelledBy: "modal-basic-title"})
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    openBookingHistoryModal(id, name) {
        this.dataSharingService.setBookingId(id);
        this.dataSharingService.setName(name);
        this.modalService
            .open(this.bookingHistoryContent, {
                ariaLabelledBy: "modal-basic-title",
                size: "xl",
                centered: true,
            })
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    openAccountHistoryModal(id: string, name: string) {
        this.dataSharingService.setUserId(id);
        this.dataSharingService.setName(name);
        this.modalService
            .open(this.accountHistoryContent, {
                ariaLabelledBy: "modal-basic-title",
                size: "xl",
                centered: true,
            })
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    openShowSubscriptionsModal(client: any) {
        this.backend
            .getClientSubscriptions({clientId: client._id})
            .subscribe({
                next: (response) => {
                    if (Array.isArray(response.data)) {
                        this.currentClientSubscriptions = response.data;
                    } else {
                        this.currentClientSubscriptions = [];
                    }

                    this.currentClient = client;

                    this.modalService
                        .open(this.showSubscriptionsContent, {
                            ariaLabelledBy: "modal-basic-title",
                            size: "xl",
                            centered: true,
                        })
                        .result.then(
                        (result) => {
                            // Handle close/dismiss
                        },
                        (reason) => {
                            // Handle close/dismiss
                        }
                    );
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    // Handle Subscription Events
    onSubscriptionStatusChange(
        clientId: string,
        clientSubscriptionId: string,
        status: string
    ) {
        this.backend
            .updateClientSubscriptionStatus({
                clientId,
                clientSubscriptionId,
                status,
            })
            .subscribe({
                next: (response) => {
                    Swal.fire({
                        title: "Subscription Status updated successfully!",
                        position: "top-end",
                        icon: "success",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    this.modalService.dismissAll();
                    this.rerender();
                },
                error: (error) => {
                    Swal.fire({
                        title: error?.error?.message,
                        position: "top-end",
                        icon: "error",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                },
            });
    }

    openSubscriptionModal(clientId: string) {
        this.clientSubscriptionForm.patchValue({
            clientId,
        });

        this.modalService
            .open(this.subscriptionContent, {
                ariaLabelledBy: "modal-basic-title",
            })
            .result.then(
            (result) => {
                // Handle close/dismiss
            },
            (reason) => {
                // Handle close/dismiss
            }
        );
    }

    deposit(person) {
    }

    withdraw(person) {
    }

    delete(person) {
    }

    onSubmit() {
        this.requestProcessing = true;
        if (this.modal_title === "Add Client") {
            this.backend.createUser(this.customerForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Client added successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.closeModal();
                    this.customerForm.reset();
                    this.rerender();
                    this.requestProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Failed to add Client.",
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.requestProcessing = false;
                },
            });
        } else if (this.modal_title === "Edit Client") {
            const userId = this.userId;
            this.customerForm.addControl("userId", this.fb.control(userId));
            this.backend.updateClient(this.customerForm.value).subscribe({
                next: (data: any) => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Client updated successfully.",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.rerender();
                    this.closeModal();
                    this.requestProcessing = false;
                },
                error: (error) => {
                    // Error callback
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: "Failed to update Client.",
                        showConfirmButton: false,
                        timer: 2500,
                    });
                    this.requestProcessing = false;
                },
            });
        }
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }

    openDeposit(user: any) {
        this.transactionForm.patchValue({
            userId: user._id,
        });

        this.currentClient = user;

        this.modalService.open(this.depositContent, {
            ariaLabelledBy: "modal-basic-title",
        });
    }

    // Deposit Withdraw logic
    selectAmount(event: MouseEvent) {
        const target = event.target as HTMLFormElement;
        target["select"]();
    }

    onSubmitDw(transactionType: string) {
        this.requestProcessing = true;
        const options = this.transactionForm.value;

        if (transactionType.toLowerCase() === "w") {
            options.amount = options.amount * -1;
        }

        this.backend.depositWithdrawClient(options).subscribe({
            next: (response) => {
                this.transactionForm.reset();
                this.modalService.dismissAll();
                this.rerender();
                this.requestProcessing = false;
            },
            error: (error) => {
                console.log(error);
                this.requestProcessing = false;
            },
        });
    }

    onSubmitSubscription() {
        if (this.clientSubscriptionForm.valid) {
            this.requestProcessing = true;

            const {clientId, subscriptionId} =
                this.clientSubscriptionForm.value;
            const reqBody = {clientId, subscriptionId};

            this.backend.addClientSubscription(reqBody).subscribe({
                next: (response) => {
                    this.modalService.dismissAll();
                    this.clientSubscriptionForm.reset();
                    Swal.fire({
                        title: "Subscription added successfully!",
                        icon: "success",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    this.requestProcessing = false;
                },
                error: (error) => {
                    console.log(error);
                    Swal.fire({
                        title: error.error?.message,
                        icon: "error",
                        position: "top-end",
                        timer: 2000,
                        showConfirmButton: false,
                    });
                    this.requestProcessing = false;
                },
            });
        }
    }

    addCustomSubscription() {
    }

    getCategories() {
        this.backend.allCategories().subscribe((data) => {
            this.categories = data.data;
        });
    }

    onCategoryChange() {
        const clientId = this.clientSubscriptionForm.get("categoryId").value;
        this.getSubscriptions(clientId);
    }

    getSubscriptions(clientId) {
        let req = {
            categoryId: clientId,
        };
        this.backend.subscriptionByCategory(req).subscribe((data) => {
            this.subscriptions = data.data;
        });
    }

    getSubscriptionDetails() {
        const subscriptionId =
            this.clientSubscriptionForm.get("subscriptionId").value;
        this.selectedSubscription = this.subscriptions.filter(
            (data) => data._id == subscriptionId
        );
    }

    patchClientFormValues(user: any) {
        this.customerForm.patchValue({
            name: user.name,
            email: user.email,
            phoneNumber: user.phoneNumber,
            address: user.address,
            area: user.area,
            customerType: user.customerType,
            pinLocation: user.pinLocation,
            emiratesID: user.emiratesID,
            balance: user.balance,
        });
    }

    // onClick Edit Button
    openEditClientModal(user: any) {
        this.modal_title = "Edit Client";
        this.modal_button = "Update";
        this.userId = user._id;
        this.patchClientFormValues(user);
        this.modalService.open(this.content, {
            ariaLabelledBy: "modal-basic-title",
        });
    }
}
