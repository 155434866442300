import { Injectable } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: "root",
})
export class UtilsService {
    constructor(private datePipe: DatePipe) {}

    getFormattedDateTime(
        date: NgbDate | NgbDateStruct,
        hours: number,
        minutes: number
    ): string {
        const newDate = new Date(date.year, date.month - 1, date.day);

        // Set time to provided parameters
        newDate.setHours(hours, minutes, 0, 0);

        const formattedEndDate = this.datePipe.transform(
            newDate,
            "yyyy-MM-ddTHH:mm:ssZ"
        );

        return formattedEndDate;
    }

    getEndDate(date: NgbDate | NgbDateStruct): string {
        if (!date) {
            date = { year: new Date().getFullYear(), month: 1, day: 1 };
        }

        const endDate = new Date(date.year, date.month - 1, date.day);

        // Set the time to 23:59:00
        endDate.setHours(23, 59, 0, 0);

        // Formatting endDate using DatePipe
        const formattedEndDate = this.datePipe.transform(
            endDate,
            "yyyy-MM-ddTHH:mm:ssZ"
        ) as string;

        return formattedEndDate;
    }

    getStartDate(date: NgbDate | NgbDateStruct): string {
        if (!date) {
            date = { year: new Date().getFullYear(), month: 1, day: 1 };
        }

        const startDate = new Date(date.year, date.month - 1, date.day);

        // Formatting startDate using DatePipe
        const formattedStartDate = this.datePipe.transform(
            startDate,
            "yyyy-MM-ddTHH:mm:ssZ"
        ) as string;

        return formattedStartDate;
    }
}
