<!-- Page Sidebar Start-->
<div class="logo-wrapper mt-1">
    <a routerLink="/">
        <img
            [src]="themeConfig?.logo?.driveId"
            alt="logo"
            class="img-fluid"
            width="140px"
        />
    </a>
    <div (click)="sidebarToggle()" class="back-btn">
        <i class="fa fa-angle-left"></i>
    </div>
    <div (click)="sidebarToggle()" class="toggle-sidebar">
        <app-feather-icons
            [icon]="'grid'"
            class="status_toggle middle sidebar-toggle"
        ></app-feather-icons>
    </div>
</div>
<div class="logo-icon-wrapper">
    <a href="javascript:void(0)">
        <img
            [src]="themeConfig?.logoSmall"
            alt=""
            class="img-fluid"
            width="40px"
        />
    </a>
</div>
<nav class="sidebar-main">
    <div
        (click)="scrollToLeft()"
        [class.d-none]="leftArrowNone"
        class="left-arrow"
        id="left-arrow"
    >
        <app-feather-icons [icon]="'arrow-left'"></app-feather-icons>
    </div>
    <div
        [ngStyle]="{
            marginLeft:
                this.layout.config.settings.layout == 'Rome' ||
                'Singapore' ||
                'Barcelona'
                    ? margin + 'px'
                    : '0px'
        }"
        id="sidebar-menu"
    >
        <ul class="sidebar-links custom-scrollbar">
            <div class="simplebar-wrapper">
                <div class="simplebar-mask">
                    <div class="simplebar-offset">
                        <div class="simplebar-content-wrapper">
                            <div class="simplebar-content">
                                <li class="back-btn">
                                    <a href="javascript:void(0)">
                                        <img
                                            [src]="themeConfig?.logoSmall.driveId"
                                            alt="smalllogo"
                                            class="img-fluid"
                                        />
                                    </a>
                                    <div
                                        (click)="sidebarToggle()"
                                        class="mobile-back text-end"
                                    >
                                        <span>Back</span>
                                        <i
                                            aria-hidden="true"
                                            class="fa fa-angle-right ps-2"
                                        ></i>
                                    </div>
                                </li>

                                <li
                                    *ngFor="let menuItem of menuItems"
                                    [class]="
                                        menuItem.headTitle1
                                            ? 'sidebar-main-title'
                                            : 'sidebar-list'
                                    "
                                    [ngClass]="{ active: menuItem.active }"
                                >
                                    <div *ngIf="menuItem.headTitle1">
                                        <h6 class="lan-1">
                                            {{
                                                menuItem.headTitle1 | translate
                                            }}
                                        </h6>
                                    </div>

                                    <label
                                        *ngIf="
                                            menuItem.badgeType &&
                                            menuItem.badgeValue
                                        "
                                        class="badge badge-{{
                                            menuItem.badgeType
                                        }}"
                                    >{{ menuItem.badgeValue }}</label
                                    >
                                    <!-- Sub -->
                                    <a
                                        (click)="togglerNavActive(menuItem)"
                                        *ngIf="
                                            menuItem.type === 'sub' &&
                                            checkUserRole(menuItem)
                                        "
                                        [class.link-nav]="!menuItem.children"
                                        [ngClass]="{ active: menuItem.active }"
                                        class="sidebar-link sidebar-title"
                                        href="javascript:void(0)"
                                    >
                                        <app-svg-icon
                                            [icon]="menuItem.icon"
                                        ></app-svg-icon>
                                        <span>{{
                                                menuItem.title | translate
                                            }}</span>
                                        <div class="according-menu">
                                            <i
                                                *ngIf="menuItem.children"
                                                class="fa fa-angle-{{
                                                    menuItem.active
                                                        ? 'down'
                                                        : 'right'
                                                }} pull-right"
                                            ></i>
                                        </div>
                                    </a>
                                    <!-- Link -->
                                    <a
                                        *ngIf="
                                            menuItem.type === 'link' &&
                                            checkUserRole(menuItem)
                                        "
                                        [class.link-nav]="!menuItem.children"
                                        [ngClass]="{ active: menuItem.active }"
                                        [routerLink]="
                                            !menuItem.type
                                                ? null
                                                : [menuItem.path]
                                        "
                                        class="sidebar-link sidebar-title"
                                        routerLinkActive="active"
                                    >
                                        <app-feather-icons
                                            [icon]="menuItem.icon"
                                        ></app-feather-icons>
                                        <span>{{
                                                menuItem.title | translate
                                            }}</span>
                                        <div class="according-menu">
                                            <i
                                                *ngIf="menuItem.children"
                                                class="fa fa-angle-{{
                                                    menuItem.active
                                                        ? 'down'
                                                        : 'right'
                                                }} pull-right"
                                            ></i>
                                        </div>
                                    </a>
                                    <!-- External Link -->
                                    <a
                                        *ngIf="
                                            menuItem.type === 'extLink' &&
                                            checkUserRole(menuItem)
                                        "
                                        [class.link-nav]="!menuItem.children"
                                        [ngClass]="{ active: menuItem.active }"
                                        class="sidebar-link sidebar-title"
                                        href="{{
                                            !menuItem.type
                                                ? null
                                                : menuItem.path
                                        }}"
                                    >
                                        <app-svg-icon
                                            [icon]="menuItem.icon"
                                        ></app-svg-icon
                                        >
                                        <span>{{
                                                menuItem.title | translate
                                            }}</span>
                                        <div class="according-menu">
                                            <i
                                                *ngIf="menuItem.children"
                                                class="fa fa-angle-{{
                                                    menuItem.active
                                                        ? 'down'
                                                        : 'right'
                                                }} pull-right"
                                            ></i>
                                        </div>
                                    </a>
                                    <!-- External Tab Link -->
                                    <a
                                        *ngIf="menuItem.type === 'extTabLink'"
                                        [class.link-nav]="!menuItem.children"
                                        [ngClass]="{ active: menuItem.active }"
                                        class="sidebar-link sidebar-title"
                                        href="{{
                                            !menuItem.type
                                                ? null
                                                : menuItem.path
                                        }}"
                                        target="_blank"
                                    >
                                        <app-svg-icon
                                            [icon]="menuItem.icon"
                                        ></app-svg-icon>
                                        <span>{{ menuItem.title }}</span>
                                        <div class="according-menu">
                                            <i
                                                *ngIf="menuItem.children"
                                                class="fa fa-angle-{{
                                                    menuItem.active
                                                        ? 'down'
                                                        : 'right'
                                                }} pull-right"
                                            ></i>
                                        </div>
                                    </a>
                                    <!-- 2nd Level Menu -->
                                    <ul
                                        *ngIf="menuItem.children"
                                        [ngClass]="{
                                            'menu-open': menuItem.active,
                                            'menu-close': !menuItem.active
                                        }"
                                        [ngClass]="{ active: menuItem.active }"
                                        [style.display]="
                                            menuItem.active ? 'block' : 'none'
                                        "
                                        class="sidebar-submenu"
                                    >
                                        <li
                                            *ngFor="
                                                let childrenItem of menuItem.children
                                            "
                                            [ngClass]="{
                                                active: childrenItem.active
                                            }"
                                        >
                                            <!-- Sub -->
                                            <a
                                                (click)="
                                                    togglerNavActive(
                                                        childrenItem
                                                    )
                                                "
                                                *ngIf="
                                                    childrenItem.type === 'sub'
                                                "
                                                class="submenu-title"
                                                href="javascript:void(0)"
                                            >
                                                <span>
                                                    {{
                                                        childrenItem.title
                                                            | translate
                                                    }}</span
                                                >
                                                <div class="according-menu">
                                                    <i
                                                        *ngIf="
                                                            childrenItem.children
                                                        "
                                                        class="fa fa-angle-{{
                                                            childrenItem.active
                                                                ? 'down'
                                                                : 'right'
                                                        }} pull-right"
                                                    ></i>
                                                </div>
                                            </a>
                                            <!-- Link -->
                                            <a
                                                *ngIf="
                                                    childrenItem.type === 'link'
                                                "
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                [routerLink]="
                                                    !childrenItem.type
                                                        ? null
                                                        : [childrenItem.path]
                                                "
                                                class="submenu-title"
                                                routerLinkActive="active"
                                            >
                                                {{
                                                    childrenItem.title
                                                        | translate
                                                }}
                                                <i
                                                    *ngIf="
                                                        childrenItem.children
                                                    "
                                                    class="fa fa-angle-down pull-right"
                                                ></i>
                                            </a>
                                            <!-- External Link -->
                                            <a
                                                *ngIf="
                                                    childrenItem.type ===
                                                    'extLink'
                                                "
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                class="submenu-title"
                                                href="{{
                                                    !childrenItem.type
                                                        ? null
                                                        : childrenItem.path
                                                }}"
                                                routerLinkActive="active"
                                            >
                                                {{
                                                    childrenItem.title
                                                        | translate
                                                }}
                                                <i
                                                    *ngIf="
                                                        childrenItem.children
                                                    "
                                                    class="fa fa-angle-down pull-right"
                                                ></i>
                                            </a>
                                            <!-- External Tab Link -->
                                            <a
                                                *ngIf="
                                                    childrenItem.type ===
                                                    'extTabLink'
                                                "
                                                class="submenu-title"
                                                href="{{
                                                    !childrenItem.type
                                                        ? null
                                                        : childrenItem.path
                                                }}"
                                                target="_blank"
                                            >
                                                <span>{{
                                                        childrenItem.title
                                                            | translate
                                                    }}</span>
                                                <i
                                                    *ngIf="
                                                        childrenItem.children
                                                    "
                                                    class="fa fa-angle-down pull-right"
                                                ></i>
                                            </a>
                                            <!-- 3rd Level Menu -->
                                            <ul
                                                *ngIf="childrenItem.children"
                                                [ngClass]="{
                                                    active: childrenItem.active
                                                }"
                                                [ngClass]="{
                                                    'menu-open':
                                                        childrenItem.active,
                                                    'menu-close':
                                                        !childrenItem.active
                                                }"
                                                [style.display]="
                                                    childrenItem.active
                                                        ? 'block'
                                                        : 'none'
                                                "
                                                class="nav-sub-childmenu submenu-content"
                                            >
                                                <li
                                                    *ngFor="
                                                        let childrenSubItem of childrenItem.children
                                                    "
                                                    [ngClass]="{
                                                        active: childrenSubItem.active
                                                    }"
                                                >
                                                    <!-- Link -->
                                                    <a
                                                        *ngIf="
                                                            childrenSubItem.type ===
                                                            'link'
                                                        "
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        [routerLink]="
                                                            !childrenSubItem.type
                                                                ? null
                                                                : [
                                                                      childrenSubItem.path
                                                                  ]
                                                        "
                                                        routerLinkActive="active"
                                                    >
                                                        {{
                                                            childrenSubItem.title
                                                                | translate
                                                        }}
                                                    </a>
                                                    <!-- External Link -->
                                                    <a
                                                        *ngIf="
                                                            childrenSubItem.type ===
                                                            'extLink'
                                                        "
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        href="{{
                                                            !childrenSubItem.type
                                                                ? null
                                                                : childrenSubItem.path
                                                        }}"
                                                        routerLinkActive="active"
                                                    >
                                                        {{
                                                            childrenSubItem.title
                                                                | translate
                                                        }}
                                                    </a>
                                                    <!-- External Tab Link -->
                                                    <a
                                                        *ngIf="
                                                            childrenSubItem.type ===
                                                            'extTabLink'
                                                        "
                                                        href="{{
                                                            !childrenSubItem.type
                                                                ? null
                                                                : childrenSubItem.path
                                                        }}"
                                                        target="_blank"
                                                    >
                                                        {{
                                                            childrenSubItem.title
                                                                | translate
                                                        }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ul>
    </div>
    <div
        (click)="scrollToRight()"
        [class.d-none]="rightArrowNone"
        class="right-arrow"
        id="right-arrow"
    >
        <app-feather-icons [icon]="'arrow-right'"></app-feather-icons>
    </div>
</nav>
