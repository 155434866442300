import { Component } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrl: './change-password.component.scss',
})
export class ChangePasswordComponent {
    public changePassForm: FormGroup;
    public showOld: boolean = false;
    public showNew: boolean = false;
    public errorMessage: string;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService
    ) {
        this.changePassForm = this.fb.group(
            {
                currentPassword: ['', Validators.required],
                newPassword: ['', Validators.required],
            },
            {
                validators: this.passwordMatchValidator,
            }
        );
    }

    ngOnInit() {}

    changePass() {
        this.authService.changePassword(this.changePassForm.value).subscribe({
            next: (response) => {
                localStorage.setItem('token',response.data.token)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Password changed successfully.',
                    showConfirmButton: false,
                });
                
                this.router.navigate(['/simple-page/first-page']);
            },
            error: (error) => {
                if (error.status === 401) this.logout();
                // Error callback
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: error.error.message,
                    showConfirmButton: false,
                });
            },
        });
    }

    logout() {
        this.authService.logout();
        this.router.navigateByUrl('auth/login');
    }

    showPassword(pass: string) {
        if (!pass) {
            throw new Error(
                `The password field should be provided as argument to the showPassword: e.g. showPassword('new' | 'old')`
            );
        }

        if (pass.toLowerCase() === 'old') {
            return (this.showOld = !this.showOld);
        } else if (pass.toLowerCase() === 'new') {
            return (this.showNew = !this.showNew);
        }
        throw new Error(`Argument ${pass} does not match 'old' | 'new' `);
    }

    // Password match validator
    passwordMatchValidator(control: AbstractControl) {
        const oldPassword = control.get('currentPassword')?.value;
        const newPassword = control.get('newPassword')?.value;

        return oldPassword === newPassword ? { match: true } : null;
    }
}
