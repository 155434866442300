import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export class DateUtils {
    static formatter = new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });

    static formatDateISO(date: NgbDate | NgbDateStruct, endDate?: boolean) {
        if (date) {
            if (endDate) {
                return new Date(
                    date.year,
                    date.month - 1,
                    date.day,
                    23,
                    59,
                    59,
                    99
                ).toISOString();
            }

            return new Date(date.year, date.month - 1, date.day).toISOString();
        }
        return null;
    }

    static formatDateToISO8601(
        date: NgbDate | NgbDateStruct,
        endDate?: boolean
    ) {
        const pad = (number: number) => String(number).padStart(2, "0");

        const year = date.year;
        const month = pad(date.month); // Months are 0-indexed
        const day = pad(date.day);
        let hour: string, minute: string, second: string;

        if (endDate) {
            hour = pad(23);
            minute = pad(59);
            second = pad(99);
        } else {
            hour = pad(0);
            minute = pad(0);
            second = pad(0);
        }

        // Get the time zone offset in hours (positive or negative)
        const offset = new Date().getTimezoneOffset() / 60;
        const offsetSign = offset >= 0 ? "+" : "-";
        const offsetHours = pad(Math.abs(offset));
        const offsetMinutes = "00"; // Minutes are always 0 in ISO 8601

        return `${year}-${month}-${day}T${hour}:${minute}:${second}${offsetSign}${offsetHours}:${offsetMinutes}`;
    }

    static createNgbDate(date: Date) {
        return new NgbDate(
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate()
        );
    }
}
