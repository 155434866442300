import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Menu, NavService } from "../../services/nav.service";
import { LayoutService } from "../../services/layout.service";
import { CONFIG } from "config";
import { AuthService } from "src/app/service/auth.service";



@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, OnDestroy {
    public iconSidebar;
    public menuItems: Menu[];
    themeConfig: any = {};
    // For Horizontal Menu

    public margin: any = 0;
    public width: any = window.innerWidth;
    public leftArrowNone: boolean = true;
    public rightArrowNone: boolean = false;

    constructor(
        private router: Router,
        public navServices: NavService,
        private authService: AuthService,
        public layout: LayoutService
    ) {
        this.navServices.items.subscribe((menuItems) => {
            this.menuItems = menuItems;
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter((items) => {
                        if (items.path === event.url) {
                            this.setNavActive(items);
                        }
                        if (!items.children) {
                            return false;
                        }
                        items.children.filter((subItems) => {
                            if (subItems.path === event.url) {
                                this.setNavActive(subItems);
                            }
                            if (!subItems.children) {
                                return false;
                            }
                            subItems.children.filter((subSubItems) => {
                                if (subSubItems.path === event.url) {
                                    this.setNavActive(subSubItems);
                                }
                            });
                        });
                    });
                }
            });
        });
    }
    interval: any;
    ngOnDestroy(): void {
        window.clearInterval(this.interval);
    }
    ngOnInit(): void {
        this.themeConfig = JSON.parse(localStorage.getItem('themeConfig') as string);
        if (!this.themeConfig) {
            this.interval = window.setInterval(() => {
                this.themeConfig = JSON.parse(localStorage.getItem('themeConfig') as string);
                if (this.themeConfig) {
                    window.clearInterval(this.interval);
                }
            }, 50);
        }
        // if(!this.themeConfig){
        //     this.authService.logout();
        // }
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.width = event.target.innerWidth - 500;
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }

    // Active Nave state
    setNavActive(item) {
        this.menuItems.filter((menuItem) => {
            if (menuItem !== item) {
                menuItem.active = false;
            }
            if (menuItem.children && menuItem.children.includes(item)) {
                menuItem.active = true;
            }
            if (menuItem.children) {
                menuItem.children.filter((submenuItems) => {
                    if (
                        submenuItems.children &&
                        submenuItems.children.includes(item)
                    ) {
                        menuItem.active = true;
                        submenuItems.active = true;
                    }
                });
            }
        });
    }

    // Click Toggle menu
    togglerNavActive(item) {
        if (!item.active) {
            this.menuItems.forEach((a) => {
                if (this.menuItems.includes(item)) {
                    a.active = false;
                }
                if (!a.children) {
                    return false;
                }
                a.children.forEach((b) => {
                    if (a.children.includes(item)) {
                        b.active = false;
                    }
                });
            });
        }
        item.active = !item.active;
    }

    // For Horizontal Menu
    scrollToLeft() {
        if (this.margin >= -this.width) {
            this.margin = 0;
            this.leftArrowNone = true;
            this.rightArrowNone = false;
        } else {
            this.margin += this.width;
            this.rightArrowNone = false;
        }
    }

    scrollToRight() {
        if (this.margin <= -3051) {
            this.margin = -3464;
            this.leftArrowNone = false;
            this.rightArrowNone = true;
        } else {
            this.margin += -this.width;
            this.leftArrowNone = false;
        }
    }

    checkUserRole(button: any) {
        const Role = localStorage.getItem("role");
        if (button.role.includes(Role)) {
            return true;
        } else {
            return false;
        }
    }
}
