import { Component } from '@angular/core';

import { CONFIG } from 'config';
import { first } from 'rxjs';
import * as moment from 'moment';
import { BackendService } from 'src/app/shared/services/backend.service';

@Component({
    selector: 'app-login-activity',
    templateUrl: './login-activity.component.html',
    styleUrls: ['./login-activity.component.scss']
})
export class LoginActivityComponent {
    data: any = []

    constructor(private backendService: BackendService) {
    }
    ngOnInit(): void {
        this.clientActivity();
    }
    clientActivity() {
        this.backendService.getActivity({})
            .pipe(first())
            .subscribe(
                data => {
                    this.data = data.data.sort((a: any, b: any) => {
                        const dateA = typeof a.createdAt === 'string' ? new Date(a.createdAt).getTime() : a.createdAt;
                        const dateB = typeof b.createdAt === 'string' ? new Date(b.createdAt).getTime() : b.createdAt;
                        return dateB - dateA;
                    });
                },
                error => {
                    let responseData = error.error;

                });
    }
}
