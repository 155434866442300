<!-- Account History Modal  -->
<div class="modal-header">
    <h6>Account History Of {{ employee_name }}</h6>

    <div class="mx-auto d-flex gap-2 align-items-center">
        <h6>
            Total In:
            <span class="text-success">{{ totals?.totalIn ?? 0 }}</span>
        </h6>
        <h6>
            Total Out:
            <span class="text-danger">{{ totals?.totalOut ?? 0 }}</span>
        </h6>
    </div>

    <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
    ></button>
</div>
<div class="modal-body px-0 px-lg-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="px-2 row">
                        <div
                            class="theme-form gap-2 flex-column flex-lg-row align-items-start align-items-lg-stretch col-lg-6"
                            style="display: flex"
                        >
                            <div class="input-group">
                                <div class="input-group-text">From</div>
                                <input
                                    class="datepicker-here form-control digits"
                                    placeholder="Start Date"
                                    name="startDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    ngbDatepicker
                                    #startDatePicker="ngbDatepicker"
                                    (click)="startDatePicker.toggle()"
                                    [(ngModel)]="startDate"
                                    (ngModelChange)="onDateChange()"
                                />
                            </div>
                            <div class="input-group">
                                <div class="input-group-text">To</div>
                                <input
                                    class="datepicker-here form-control digits"
                                    placeholder="End Date"
                                    name="endDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    ngbDatepicker
                                    #endDatePicker="ngbDatepicker"
                                    (click)="endDatePicker.toggle()"
                                    [(ngModel)]="endDate"
                                    (ngModelChange)="onDateChange()"
                                />
                            </div>
                            <div class="d-grid d-lg-block w-100">
                                <button
                                    class="btn btn-primary-gradien h-100"
                                    (click)="rerender()"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 px-md-4">
                        <div class="col-12 account-statement-tbl">
                            <div class="table-responsive">
                                <table
                                    datatable
                                    [dtOptions]="dtOptions"
                                    class="row-border hover"
                                >
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th class="suspense">
                                                Received By
                                            </th>
                                            <th
                                                class="suspense"
                                                style="left: 7.5rem !important"
                                            >
                                                Paid By
                                            </th>
                                            <th>Amount</th>
                                            <th>Paid Via</th>
                                            <th>Type</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Employee</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="statements?.length != 0">
                                        <tr
                                            *ngFor="let statement of statements"
                                        >
                                            <td>
                                                {{
                                                    statement.date
                                                        | date : "short"
                                                }}
                                            </td>
                                            <td class="suspense">
                                                {{ statement.receivedBy }}
                                            </td>
                                            <td
                                                class="suspense"
                                                style="left: 7.5rem !important"
                                            >
                                                {{ statement.paidBy }}
                                            </td>
                                            <td
                                                [ngClass]="
                                                    statement.type.toLowerCase() ===
                                                    'in'
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                "
                                            >
                                                {{ statement.amount }}
                                            </td>
                                            <td>
                                                {{ statement.paidVia }}
                                            </td>
                                            <td
                                                class="text-nowrap"
                                                [ngClass]="
                                                    statement.type.toLowerCase() ===
                                                    'in'
                                                        ? 'text-success'
                                                        : 'text-danger'
                                                "
                                            >
                                                {{ statement.type }}
                                            </td>
                                            <td class="text-nowrap">
                                                {{ statement.remarks }}
                                            </td>
                                            <td>{{ statement.status }}</td>
                                            <td>
                                                {{
                                                    statement.employee?.name +
                                                        " > " +
                                                        statement.employee
                                                            ?.category
                                                            ?.categoryName ??
                                                        "-"
                                                }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="statements?.length == 0">
                                        <tr>
                                            <td
                                                colspan="3"
                                                class="no-data-available"
                                            >
                                                No data!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
