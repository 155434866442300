<!-- Booking History Modal  -->
<div class="modal-header">
    <h6>Booking History Of {{ employee_name }}</h6>
        <h6 style="margin-left: auto">
            Total Working Hours: {{this.totals?.totalHours}}</h6>
    <h6 style="margin-left: auto">Total Worked Amount: {{this.totals?.totalAmount | number}}</h6>
    <h6 style="margin-left: auto">Total Due Amount: {{this.totals?.totalDueAmount | number}}</h6>
    <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="closeModal()"
    ></button>
</div>
<div class="modal-body px-0 px-lg-2">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="px-2">
                        <div
                            class="theme-form d-flex gap-2 flex-column flex-lg-row align-items-start align-items-lg-stretch"
                        >
                            <div class="input-group">
                                <div class="input-group-text">From</div>
                                <input
                                    class="datepicker-here form-control digits"
                                    placeholder="Start Date"
                                    name="startDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    ngbDatepicker
                                    #startDatePicker="ngbDatepicker"
                                    (click)="startDatePicker.toggle()"
                                    [(ngModel)]="startDate"
                                    (ngModelChange)="onDateChange()"
                                />
                            </div>
                            <div class="input-group">
                                <div class="input-group-text">To</div>
                                <input
                                    class="datepicker-here form-control digits"
                                    placeholder="End Date"
                                    name="endDate"
                                    [displayMonths]="displayMonths"
                                    [navigation]="navigation"
                                    [outsideDays]="outsideDays"
                                    [showWeekNumbers]="showWeekNumbers"
                                    ngbDatepicker
                                    #endDatePicker="ngbDatepicker"
                                    (click)="endDatePicker.toggle()"
                                    [(ngModel)]="endDate"
                                    (ngModelChange)="onDateChange()"
                                />
                            </div>
                            <div class="d-grid d-lg-block w-100">
                                <button
                                    class="btn btn-primary-gradien h-100"
                                    (click)="rerender()"
                                >
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body px-0 px-md-4">
                        <div class="col-12 account-statement-tbl">
                            <div class="table-responsive">
                                <table
                                    datatable
                                    [dtOptions]="dtOptions"
                                    class="row-border hover"
                                >
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th class="suspense">
                                                Client Name
                                            </th>
                                            <th>Booking Status</th>
                                            <th>Payment Status</th>
                                            <th>Hours</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Rate Per Hour</th>
                                            <th>Material Charges / Hr</th>
                                            <th>Description</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="persons?.length != 0">
                                        <tr *ngFor="let person of users">
                                            <td>
                                                {{ person.startTime | date }}
                                            </td>
                                            <td class="suspense">
                                                {{ person.client.name }}
                                            </td>
                                            <td>{{ person.flag }}</td>
                                            <td>{{ person.paymentStatus }}</td>
                                            <td>
                                                {{ formatNumber(person.hours) }}
                                            </td>
                                            <td class="text-nowrap">
                                                {{
                                                    person.startTime
                                                        | date : "HH:MM"
                                                }}
                                            </td>
                                            <td class="text-nowrap">
                                                {{
                                                    person.endTime
                                                        | date : "HH:mm"
                                                }}
                                            </td>
                                            <td>{{ person.ratePerHour }}</td>
                                            <td>
                                                {{ person.materialCharges }}
                                            </td>
                                            <td>{{ person.description }}</td>
                                            <td>{{ person.totalAmount }}</td>
                                            <!--                                        <td>{{ person.createdAt | date }}</td>-->
                                            <!--                                        <td>{{ person.updatedAt | date }}</td>-->
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="users?.length == 0">
                                        <tr>
                                            <td
                                                colspan="3"
                                                class="no-data-available"
                                            >
                                                No data!
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
