<app-breadcrumb title="Cashflow" active_item="Cashflow"></app-breadcrumb>
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div
                    class="p-2 d-flex flex-column flex-lg-row align-items-start align-items-md-center justify-content-between"
                >
                    <div
                        class="theme-form mb-2 mb-md-3 mb-lg-0 flex-column flex-md-row align-items-start align-items-md-stretch"
                        style="display: flex; gap: 10px"
                    >
                        <div class="input-group">
                            <div class="input-group-text">From</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="Start Date"
                                name="startDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #startDatePicker="ngbDatepicker"
                                (click)="startDatePicker.toggle()"
                                [(ngModel)]="startDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="input-group">
                            <div class="input-group-text">To</div>
                            <input
                                class="datepicker-here form-control digits"
                                placeholder="End Date"
                                name="endDate"
                                [displayMonths]="displayMonths"
                                [navigation]="navigation"
                                [outsideDays]="outsideDays"
                                [showWeekNumbers]="showWeekNumbers"
                                ngbDatepicker
                                #endDatePicker="ngbDatepicker"
                                (click)="endDatePicker.toggle()"
                                [(ngModel)]="endDate"
                                (ngModelChange)="onDateChange()"
                            />
                        </div>
                        <div class="d-grid d-md-block w-100">
                            <button
                                class="btn btn-primary-gradien h-100"
                                (click)="rerender()"
                            >
                                Search
                            </button>
                        </div>
                    </div>

                    <div
                        class="w-100 mt-4 mt-md-0 d-flex flex-column flex-md-row gap-2 gap-md-2 justify-content-center justify-content-lg-end align-items-center"
                    >
                        <div class="cash-in-out d-flex gap-2 fw-bold">
                            <span>
                                Cash in:
                                <span class="text-success">
                                    {{ total?.totalIn ?? "..." }}
                                </span>
                            </span>
                            <span>
                                Cash Out:
                                <span class="text-danger">
                                    {{ total?.totalOut ?? "..." }}
                                </span>
                            </span>
                            <span>
                                Cash In Hand:
                                <span
                                    [ngClass]="{
                                        'text-success':
                                            total?.totalIn - total?.totalOut >
                                            0,
                                        'text-danger':
                                            total?.totalIn - total?.totalOut <=
                                            0
                                    }"
                                >
                                    {{ total?.totalIn - total?.totalOut }}
                                </span>
                            </span>
                        </div>

                        <div class="d-flex gap-2">
                            <button
                                class="btn btn-success"
                                (click)="openCashFlow('I')"
                            >
                                Cash In
                            </button>
                            <button
                                class="btn btn-danger"
                                (click)="openCashFlow('O')"
                            >
                                Cash Out
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body p-4">
                    <div class="col-12 account-statement-tbl">
                        <div class="table-responsive">
                            <table
                                datatable
                                [dtOptions]="dtOptions"
                                class="row-border hover"
                            >
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Remarks</th>
                                        <th>Received By</th>
                                        <th>Paid By</th>
                                        <th>Amount</th>
                                        <th>Paid Via</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="statement?.length != 0">
                                    <tr *ngFor="let item of statement">
                                        <td>
                                            {{
                                                item.date
                                                    | date : "dd:MM:YY hh:mm"
                                            }}
                                        </td>
                                        <td>{{ item.remarks }}</td>
                                        <td>{{ item.receivedBy }}</td>
                                        <td>{{ item.paidBy }}</td>
                                        <td
                                            [ngClass]="{
                                                payIn: item.type == 'IN',
                                                payOut: item.type == 'OUT'
                                            }"
                                        >
                                            <b>{{ item.amount }}</b>
                                        </td>
                                        <td>{{ item.paidVia }}</td>
                                        <td>{{ item.status }}</td>

                                        <td>
                                            <button
                                                class="btn btn-pill btn-danger btn-air-danger btn-danger-gradien"
                                                type="button"
                                                data-bs-original-title=""
                                                title=""
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="statement?.length == 0">
                                    <tr>
                                        <td
                                            [colSpan]="
                                                dtOptions.columns?.length + 1
                                            "
                                            class="no-data-available text-center"
                                        >
                                            No data!
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #cashFlowContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ CfTitle }}</h4>
        <button
            type="button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        ></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="cashFlowForm" (ngSubmit)="onSubmitCF()">
            <div class="mb-3">
                <label for="date" class="form-label">Date</label>

                <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    name="dp"
                    formControlName="date"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    (click)="d.toggle()"
                />
                <div
                    *ngIf="
                        cashFlowForm.get('date').invalid &&
                        cashFlowForm.get('date').touched
                    "
                    class="text-danger"
                >
                    Date is required
                </div>
            </div>

            <div class="mb-3">
                <label for="paidBy" class="form-label">Paid By</label>
                <input
                    type="text"
                    class="form-control"
                    id="paidBy"
                    formControlName="paidBy"
                    placeholder="Enter name"
                />
                <div
                    *ngIf="
                        cashFlowForm.get('paidBy').invalid &&
                        cashFlowForm.get('paidBy').touched
                    "
                    class="text-danger"
                >
                    Payer is required
                </div>
            </div>

            <div class="mb-3">
                <label for="paidVia" class="form-label">Paid Via</label>
                <input
                    type="text"
                    class="form-control"
                    id="paidVia"
                    formControlName="paidVia"
                    placeholder="Enter source"
                />
                <div
                    *ngIf="
                        cashFlowForm.get('paidVia').invalid &&
                        cashFlowForm.get('paidVia').touched
                    "
                    class="text-danger"
                >
                    Payment source is required
                </div>
            </div>

            <div class="mb-3">
                <label for="receivedBy" class="form-label">Received By</label>
                <input
                    type="text"
                    class="form-control"
                    id="receivedBy"
                    formControlName="receivedBy"
                    placeholder="Enter receiver"
                />
                <div
                    *ngIf="
                        cashFlowForm.get('receivedBy').invalid &&
                        cashFlowForm.get('receivedBy').touched
                    "
                    class="text-danger"
                >
                    Receiver is required
                </div>
            </div>

            <div class="mb-3">
                <label for="amount" class="form-label">Amount</label>
                <input
                    type="number"
                    class="form-control"
                    id="amount"
                    formControlName="amount"
                    placeholder="Enter amount"
                />
                <div
                    *ngIf="
                        cashFlowForm.get('amount').invalid &&
                        cashFlowForm.get('amount').touched
                    "
                    class="text-danger"
                >
                    Amount is required
                </div>
            </div>

            <div class="mb-3">
                <label for="remarks" class="form-label">Remarks</label>
                <textarea
                    class="form-control"
                    id="remarks"
                    formControlName="remarks"
                    placeholder="remarks..."
                ></textarea>
                <div
                    *ngIf="
                        cashFlowForm.get('remarks').invalid &&
                        cashFlowForm.get('remarks').touched
                    "
                    class="text-danger"
                >
                    Remarks is required
                </div>
            </div>

            <button
                type="submit"
                class="btn btn-primary d-flex gap-2 align-items-center"
                [disabled]="cashFlowForm.invalid || cashFlowLoading"
            >
                @if (cashFlowLoading) {
                <span
                    class="spinner-border spinner-border-sm"
                    aria-hidden="true"
                ></span>
                <span role="status">Loading...</span>
                } @else {
                <span>Submit</span>
                }
            </button>
        </form>
    </div>
</ng-template>
