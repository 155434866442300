import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
// import {
//     isNumber,
//     toInteger,
//     padNumber,
// } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
    private isNumber(value: any): boolean {
        return typeof value === 'number' && !isNaN(value);
    }
    
    private toInteger(value: any): number {
        return parseInt(value, 10);
    }
    
    private padNumber(value: number): string {
        return value.toString().padStart(2, '0');
    }
    
    parse(value: string): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('-');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return {
                    day: this.toInteger(dateParts[0]),
                    month: null,
                    year: null,
                } as unknown as NgbDateStruct;
            } else if (
                dateParts.length === 2 &&
                this.isNumber(dateParts[0]) &&
                this.isNumber(dateParts[1])
            ) {
                return {
                    day: this.toInteger(dateParts[0]),
                    month: this.toInteger(dateParts[1]),
                    year: null,
                } as unknown as NgbDateStruct;
            } else if (
                dateParts.length === 3 &&
                this.isNumber(dateParts[0]) &&
                this.isNumber(dateParts[1]) &&
                this.isNumber(dateParts[2])
            ) {
                return {
                    day: this.toInteger(dateParts[0]),
                    month: this.toInteger(dateParts[1]),
                    year: this.toInteger(dateParts[2]),
                };
            }
        }
        return null as unknown as NgbDateStruct;
    }

    format(date: NgbDateStruct): string {
        return date
            ? `${this.isNumber(date.day) ? this.padNumber(date.day) : ''}-${
                    this.isNumber(date.month) ? this.padNumber(date.month) : ''
                }-${date.year}`
            : '';
    }
}
