import { Component, ViewChild } from "@angular/core";
import { SharedModule } from "../../shared/shared.module";

import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CONFIG } from "../../../../config";
import { DatePipe, NgIf, NgFor, NgClass, DecimalPipe } from "@angular/common";
import { DataTableDirective, DataTablesModule } from "angular-datatables";
import { DateUtils } from "src/app/utils/date-utils";
import { UtilsService } from "src/app/shared/services/utils.service";

@Component({
    selector: "app-profit-loss",
    standalone: true,
    imports: [
        SharedModule,
        DatePipe,
        DataTablesModule,
        NgFor,
        NgIf,
        NgClass,
        DecimalPipe,
    ],
    templateUrl: "./profit-loss.component.html",
    styleUrl: "./profit-loss.component.scss",
})
export class ProfitLossComponent {
    @ViewChild(DataTableDirective)
    dataTableElement!: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    @ViewChild(DataTableDirective, { static: false })
    dtElement!: DataTableDirective;
    public selected = [];

    formattedStartDate: any;
    formattedEndDate: any;

    users: any[] = [];
    navigation = "select";
    total$: Observable<number>;
    displayMonths = 1;
    outsideDays = "visible";
    showWeekNumbers = true; // Define the showWeekNumbers property here
    totalPl: any;

    persons: any[] = [
        { id: 1, firstName: "John", lastName: "Doe", action: "deposit" },
        { id: 2, firstName: "Jane", lastName: "Smith", action: "withdraw" },
        { id: 3, firstName: "Alice", lastName: "Johnson", action: "toggle" },
        { id: 4, firstName: "Bob", lastName: "Brown", action: "delete" },
    ];
    dataTableParam: any;
    show: boolean = false;

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        private utils: UtilsService
    ) {}

    statement: any = [];
    startDate: any;
    endDate: any;

    ngOnInit(): void {
        this.startDate = DateUtils.createNgbDate(new Date());
        this.endDate = DateUtils.createNgbDate(new Date());

        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);

        const that = this;
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 10000,
            serverSide: true,
            searching: true,
            autoWidth: false,
            processing: false,
            order: [0, "desc"],
            ajax: (dataTablesParameters: any, callback) => {
                that.http
                    .post<any>(
                        CONFIG.getProfitLoss,
                        Object.assign(dataTablesParameters, {
                            startDate: this.formattedStartDate,
                            endDate: this.formattedEndDate,
                        })
                    )
                    .subscribe((resp) => {
                        let data = resp.data.original;

                        this.dataTableParam = dataTablesParameters;
                        if (resp.data) {
                            that.statement = resp.data.original;
                            this.totalPl = resp.data.total[0];
                        }

                        callback({
                            recordsTotal: resp.data.recordsTotal,
                            recordsFiltered: resp.data.recordsFiltered,
                            data: [],
                        });
                    });
            },
            columns: [
                { data: "date" },
                { data: "expenses" },
                { data: "sales" },
                { data: "totalPl" },
                { data: "updatedAt" },
            ],
        };
    }

    onDateChange() {
        // Handle date change logic here
        this.formattedStartDate = this.utils.getStartDate(this.startDate);
        this.formattedEndDate = this.utils.getEndDate(this.endDate);
    }

    closeModal() {
        this.modalService.dismissAll(); // This will close all open modals
    }

    rerender(): void {
        this.dataTableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.draw();
        });
    }
}
