<!-- <app-breadcrumb
    [title]="'Schedule (List View)'"
    [items]="['Schedule']"
    [active_item]="'List'"
>
</app-breadcrumb> -->
<div
    class="my-3 p-3 bg-white w-100 d-flex flex-column flex-md-row gap-1 gap-md-2 gap-lg-3 flex-wrap align-items-center justify-content-center justify-content-md-between"
>
    <div class="totals d-flex gap-4 text-center justify-content-center">
        <h3 class="d-flex flex-column flex-lg-row gap-lg-2">
            Total Hours:
            <span style="color: var(--theme-deafult)">{{
                    totals?.totalHours ?? 0
                }}</span>
        </h3>
        <h3 class="d-flex flex-column flex-lg-row gap-lg-2">
            Total Amount:
            <span style="color: var(--theme-deafult)">{{
                    totals?.totalAmount ?? 0 | currency : "AED"
                }}</span>
        </h3>
        <h3 class="d-flex flex-column flex-lg-row gap-lg-2">
            Total Due Amount:
            <span class="text-danger">{{
                    totals?.totalDueAmount ?? 0 | currency : "AED"
                }}</span>
        </h3>
    </div>

    <button
        (click)="generatePdf()"
        *ngIf="['admin', 'manager', 'super'].includes(currentUserRole)"
        class="btn btn-outline-primary"
    >
        <i class="bi bi-download"></i> Download
    </button>
</div>

<div class="row px-2 my-2">
    <div class="col-md-4">
        <div class="btn-group">
            <div
                (viewDateChange)="viewDateChange($event)"
                [(viewDate)]="viewDate"
                [view]="'day'"
                class="btn btn-primary"
                mwlCalendarPreviousView
            >
                Previous
            </div>
            <div
                (viewDateChange)="viewDateChange($event)"
                [(viewDate)]="viewDate"
                class="btn btn-outline-secondary"
                mwlCalendarToday
            >
                Today
            </div>
            <div
                (viewDateChange)="viewDateChange($event)"
                [(viewDate)]="viewDate"
                [view]="'day'"
                class="btn btn-primary"
                mwlCalendarNextView
            >
                Next
            </div>
        </div>
    </div>
    <div
        class="calendar-date my-2 my-md-0 col-md-7 col-lg-4 text-start text-md-center text-lg-end"
    >
        <h3>{{ viewDate | calendarDate : "dayViewTitle" }}</h3>
    </div>
</div>

<div
    class="px-2 d-flex gap-2 flex-column flex-lg-row align-items-stretch align-items-lg-center justify-content-lg-between"
>
    <!--show from or to-->
    <div
        *ngIf="currentUserRole !== 'employee'"
        class="theme-form d-flex gap-2 flex-column flex-md-row align-items-start align-items-md-stretch"
    >
        <div class="input-group">
            <div class="input-group-text">From</div>
            <input
                #startDatePicker="ngbDatepicker"
                (click)="startDatePicker.toggle()"
                (ngModelChange)="onDateChange()"
                [(ngModel)]="startDate"
                [displayMonths]="displayMonths"
                [navigation]="navigation"
                [outsideDays]="outsideDays"
                [showWeekNumbers]="showWeekNumbers"
                class="datepicker-here form-control digits"
                name="startDate"
                ngbDatepicker
                placeholder="Start Date"
            />
        </div>
        <div class="input-group">
            <div class="input-group-text">To</div>
            <input
                #endDatePicker="ngbDatepicker"
                (click)="endDatePicker.toggle()"
                (ngModelChange)="onDateChange()"
                [(ngModel)]="endDate"
                [displayMonths]="displayMonths"
                [navigation]="navigation"
                [outsideDays]="outsideDays"
                [showWeekNumbers]="showWeekNumbers"
                class="datepicker-here form-control digits"
                name="endDate"
                ngbDatepicker
                placeholder="End Date"
            />
        </div>
        <div class="d-grid d-md-block w-100">
            <button (click)="rerender()" class="btn btn-primary-gradien h-100">
                Search
            </button>
        </div>
    </div>

    <div class="d-flex gap-4 justify-content-between">
        <div class="p-0 p-lg-2 d-flex">
            <button
                (click)="openModal({ title: modals.addBooking })"
                *ngIf="['admin', 'manager', 'super'].includes(currentUserRole)"
                class="btn btn-secondary"
                style="float: right"
            >
                Add Booking
            </button>
        </div>
    </div>

    <div *ngIf="currentUserRole !== 'employee'" class="text-right ms-lg-auto">
        <button
            (click)="toggleViews()"
            class="btn btn-primary d-flex align-items-center"
            style="padding-top: 0px; padding-bottom: 0px"
        >
            <i
                class="bi bi-calendar-date-fill h5"
                style="margin-top: 8px; margin-right: 4px"
            ></i>
            Calender View
        </button>
    </div>
</div>
<br/>

<ng-container>
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body p-4">
                        <div class="col-12 account-statement-tbl">
                            <div class="table-responsive">
                                <table
                                    [dtOptions]="dtOptions"
                                    class="row-border hover"
                                    datatable
                                >
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <th>Booking Name</th>
                                        }
                                        <th class="suspense">Client</th>
                                        <!--                                        <th>One Time</th>-->
                                        <th>Hours</th>
                                        <th>Total Amount</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            Assignee
                                        </th>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <th>Subscription</th>
                                        }
                                        <th>Material</th>
                                        <th
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            Material Charges / Hr
                                        </th>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <th>Rate/Hour</th>
                                        }
                                        <th
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            Payment Status
                                        </th>
                                        <th
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            Booking Status
                                        </th>
                                        <th>Description</th>

                                        <th
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                            class="actions"
                                        >
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr
                                        *ngFor="
                                                let booking of bookings;
                                                let i = index
                                            "
                                        [ngClass]="{
                                                'bg-cancelled':
                                                    booking.flag ===
                                                    'CANCELLED',
                                                'bg-completed':
                                                    booking.flag === 'COMPLETED'
                                            }"
                                    >
                                        <td>{{ i + 1 }}</td>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <td>
                                                {{ booking?.bookingName }}
                                            </td>
                                        }
                                        <td
                                            (click)="
                                                    openClientDataModal(booking)
                                                "
                                            class="fw-bold cursor suspense"
                                            style="
                                                    color: var(--theme-deafult);
                                                "
                                        >
                                            {{ booking?.client?.name }}
                                        </td>
                                        <td>
                                            {{
                                                booking?.hours
                                                    | number : "1.2-2"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                booking?.totalAmount
                                                    | number : "1.2-2"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                booking?.startTime
                                                    | date : "shortTime"
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                booking?.endTime
                                                    | date : "shortTime"
                                            }}
                                        </td>
                                        <td
                                            (click)="
                                                    openEmployeeDataModal(
                                                        booking
                                                    )
                                                "
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                            class="fw-bold cursor"
                                            style="
                                                    color: var(--theme-deafult);
                                                "
                                        >
                                            {{ booking?.assignee?.name }}
                                        </td>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <td
                                                [ngClass]="
                                                    booking?.subscription ??
                                                    'text-danger ps-4'
                                                "
                                            >
                                                {{
                                                    booking?.subscription
                                                        ?.name ?? "None"
                                                }}
                                            </td>
                                        }
                                        <td>
                                            {{
                                                booking?.material
                                                    ? "Yes"
                                                    : "No"
                                            }}
                                        </td>
                                        <td
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            {{
                                                booking?.materialCharges ??
                                                "None"
                                            }}
                                        </td>
                                        @if (['admin',
                                            'manager', 'super'].includes(currentUserRole)) {
                                            <td>{{ booking?.ratePerHour }}</td>
                                        }
                                        <td
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            <select
                                                (ngModelChange)="
                                                        currentUserRole ===
                                                        'driver'
                                                            ? null
                                                            : onPaymentStatusChange(
                                                                  $event,
                                                                  booking
                                                              )
                                                    "
                                                [(ngModel)]="
                                                        booking.paymentStatus
                                                    "
                                                [disabled]="
                                                        currentUserRole ===
                                                        'driver'
                                                    "
                                                [ngClass]="{
                                                        'bg-pending':
                                                            booking.paymentStatus ===
                                                            'DUE',
                                                        'bg-received':
                                                            booking.paymentStatus ===
                                                            'RECEIVED',
                                                        'bg-collected':
                                                            booking.paymentStatus ===
                                                            'COLLECTED BY ASIGNEE'
                                                    }"
                                                class="paymentDropdown"
                                            >
                                                <option value="DUE">
                                                    DUE
                                                </option>
                                                @if (currentUserRole !==
                                                'employee') {
                                                    <option value="RECEIVED">
                                                        RECEIVED
                                                    </option>
                                                    <option
                                                        value="COLLECTED BY ASIGNEE"
                                                    >
                                                        COLLECTED BY ASSIGNEE
                                                    </option>
                                                } @else {
                                                    <option
                                                        value="COLLECTED BY ASIGNEE"
                                                    >
                                                        COLLECTED BY ME
                                                    </option>
                                                }
                                            </select>
                                        </td>
                                        <td
                                            *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                        >
                                            <select
                                                (ngModelChange)="
                                                        currentUserRole ===
                                                        'driver'
                                                            ? null
                                                            : onFlagChange(
                                                                  $event,
                                                                  booking
                                                              )
                                                    "
                                                [(ngModel)]="booking.flag"
                                                [disabled]="
                                                        currentUserRole ===
                                                        'driver'
                                                    "
                                                [ngClass]="{
                                                        'bg-pending':
                                                            booking.flag ===
                                                            'PENDING',
                                                        'bg-completed':
                                                            booking.flag ===
                                                            'COMPLETED',
                                                        'bg-cancelled':
                                                            booking.flag ===
                                                            'CANCELLED',
                                                        'bg-progress':
                                                            booking.flag ===
                                                            'IN-PROGRESS'
                                                    }"
                                                class="paymentDropdown"
                                            >
                                                <option value="PENDING">
                                                    PENDING
                                                </option>
                                                <option value="COMPLETED">
                                                    COMPLETED
                                                </option>
                                                <option value="CANCELLED">
                                                    CANCELLED
                                                </option>
                                                <option value="IN-PROGRESS">
                                                    IN-PROGRESS
                                                </option>
                                            </select>
                                        </td>
                                        <td>{{ booking?.description }}</td>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <td
                                                *ngIf="
                                                    currentUserRole !=
                                                    'employee'
                                                "
                                                class="d-flex actions"
                                            >
                                                <!-- Delete button only -->
                                                <button
                                                    (click)="
                                                        sendBookingFeedback(
                                                            clientData
                                                        )
                                                    "
                                                    class="btn btn-pill btn-warning btn-outline-warning"
                                                    data-bs-original-title=""
                                                    title=""
                                                    type="button"
                                                >
                                                    Get FeedBack
                                                </button>
                                                <!--                                            </td>
                                                -->
                                                <!--                                            <td *ngIf="['admin', 'manager', 'super'].includes(currentUserRole)"-->
                                                <!--                                                style="max-width: inherit">-->
                                                <!--                                                <button-->
                                                <!--                                                    (click)="sendBookingConfirmation(clientData)"-->
                                                <!--                                                    class="btn btn-primary float-end"-->
                                                <!--                                                    type="submit"-->
                                                <!--                                                >-->
                                                <!--                                                    Send Booking Confirmation Message-->
                                                <!--                                                </button>-->
                                                <!--                                            </td>-->
                                        </div>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <form
            (ngSubmit)="addBooking()"
            [formGroup]="form"
            class="eventForm"
            layout="vertical"
            requiredMark="optional"
        >
            <div class="row">
                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="eventTitle">Booking Name</label>
                        <input
                            class="form-control"
                            formControlName="bookingName"
                            id="bookingName"
                            type="text"
                        />
                    </div>
                </div>
                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="startDate">Event Date</label>
                        <input
                            #d="ngbDatepicker"
                            (click)="d.toggle()"
                            [displayMonths]="displayMonths"
                            [navigation]="navigation"
                            [outsideDays]="outsideDays"
                            [showWeekNumbers]="showWeekNumbers"
                            class="datepicker-here form-control digits"
                            formControlName="startDate"
                            name="startDate"
                            ngbDatepicker
                            placeholder="Start Date"
                        />
                    </div>
                </div>
                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="client">Client</label>
                        <!--                        <select-->
                        <!--                            formControlName="clientId"-->
                        <!--                            id="client"-->
                        <!--                            class="form-control"-->
                        <!--                            style="appearance: auto"-->
                        <!--                        >-->
                        <!--                            <option-->
                        <!--                                *ngFor="let client of clients"-->
                        <!--                                [value]="client._id"-->
                        <!--                            >-->
                        <!--                                {{ client?.name }}, {{ client?.area }}-->
                        <!--                            </option>-->
                        <!--                        </select>-->
                        <ng-multiselect-dropdown
                            (onDeSelect)="onItemDeSelect($event)"
                            (onSelect)="onItemSelect($event)"
                            (onSelectAll)="onSelectAll($event)"
                            [(ngModel)]="selectedClients"
                            [data]="clients"
                            [ngModelOptions]="{
                                standalone: true
                            }"
                            [placeholder]="'Select Client'"
                            [settings]="dropdownSettings"
                        >
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <!--                <div class="col-md-6 mb-1">-->
                <!--                    <div class="form-group">-->
                <!--                        <label for="category">Category</label>-->
                <!--                        <select-->
                <!--                                formControlName="category"-->
                <!--                                id="category"-->
                <!--                                class="form-control"-->
                <!--                                style="appearance: auto"-->
                <!--                        >-->
                <!--                            <option-->
                <!--                                    *ngFor="let category of categories"-->
                <!--                                    [value]="category._id"-->
                <!--                            >-->
                <!--                                {{ category.categoryName }}-->
                <!--                            </option>-->
                <!--                        </select>-->
                <!--                    </div>-->
                <!--                </div>-->

                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label class="form-label"
                        >Customer is Our Subscriber?</label
                        >
                        <div class="d-flex align-items-center">
                            <div
                                [ngStyle]="
                                    !(
                                        form.get('clientId').value &&
                                        assignees.length
                                    )
                                        ? {
                                              pointerEvents: 'none',
                                              opacity: '0.5',
                                              userSelect: 'none'
                                          }
                                        : {}
                                "
                                class="form-check me-3"
                            >
                                <input
                                    class="form-check-input"
                                    formControlName="isOneTime"
                                    id="isOneTimeFalse"
                                    name="isOneTime"
                                    type="radio"
                                    value="false"
                                />
                                <label
                                    class="form-check-label"
                                    for="isOneTimeFalse"
                                >
                                    Yes
                                </label>
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    formControlName="isOneTime"
                                    id="isOneTimeTrue"
                                    name="isOneTime"
                                    type="radio"
                                    value="true"
                                />
                                <label
                                    class="form-check-label"
                                    for="isOneTimeTrue"
                                >
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="ratePerHour">Rate Per Hour</label>
                        <input
                            class="form-control"
                            formControlName="ratePerHour"
                            id="ratePerHour"
                            type="number"
                        />
                    </div>
                </div>

                <div class="col-md-6 mb-1">
                    <div
                        *ngIf="form.get('isOneTime').value === 'false'"
                        class="form-group"
                    >
                        <label for="subscriptions">Subscriptions</label>
                        <select
                            (ngModelChange)="selectSubscription($event)"
                            [(ngModel)]="selectedSubscription"
                            [disabled]="!currentClientSubscriptions.length"
                            [ngModelOptions]="{ standalone: true }"
                            class="form-control"
                            id="subscriptions"
                            name="subscriptions"
                            style="appearance: auto"
                        >
                            @if (currentClientSubscriptions.length > 0) {
                                <option
                                    *ngFor="let item of currentClientSubscriptions"
                                    [value]="item._id"
                                >
                                    {{ item.subscription.subscriptionName }}
                                </option>
                            } @else {
                                <option [value]="null" selected disabled>
                                    No subscription found
                                </option>
                            }
                        </select>
                    </div>
                </div>

                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="startTime">Start Time</label>
                        <input
                            class="form-control"
                            formControlName="startTime"
                            id="startTime"
                            type="time"
                        />
                    </div>
                </div>
                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="endTime">End Time</label>
                        <input
                            class="form-control"
                            formControlName="endTime"
                            id="endTime"
                            name="endTime"
                            type="time"
                        />
                    </div>
                </div>

                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label class="form-label">Material Required?</label>
                        <div class="d-flex align-items-center">
                            <div class="form-check me-3">
                                <input
                                    class="form-check-input"
                                    formControlName="material"
                                    id="materialTrue"
                                    name="material"
                                    type="radio"
                                    value="true"
                                />
                                <label
                                    class="form-check-label"
                                    for="materialTrue"
                                >Yes</label
                                >
                            </div>
                            <div class="form-check">
                                <input
                                    class="form-check-input"
                                    formControlName="material"
                                    id="materialFalse"
                                    name="material"
                                    type="radio"
                                    value="false"
                                />
                                <label
                                    class="form-check-label"
                                    for="materialFalse"
                                >No</label
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6 mb-1">
                    <div class="form-group">
                        <label for="paymentStatus">Payment Status</label>
                        <select
                            class="form-control"
                            formControlName="paymentStatus"
                            id="paymentStatus"
                            style="appearance: auto"
                        >
                            <option value="DUE">DUE</option>
                            <option value="RECEIVED">RECEIVED</option>
                            @if (currentUserRole !== 'employee') {
                                <option value="COLLECTED BY ASIGNEE">
                                    COLLECTED BY ASSIGNEE
                                </option>
                            }
                        </select>
                    </div>
                </div>

                <div
                    *ngIf="form.get('material').value === 'true'"
                    class="col-md-6 mb-1"
                >
                    <div class="form-group">
                        <label for="materialCharges"
                        >Material Charges / Hr</label
                        >
                        <input
                            class="form-control"
                            formControlName="materialCharges"
                            id="materialCharges"
                            type="number"
                        />
                    </div>
                </div>

                <div class="col-12 mb-1">
                    <div class="form-group">
                        <label for="description">Description</label>
                        <textarea
                            class="form-control"
                            cols="10"
                            formControlName="description"
                            id="description"
                            rows="4"
                            style="resize: none"
                        ></textarea>
                    </div>
                </div>

                <h5 class="mt-3">Assign to Staff</h5>
                <div class="col-md-12 row align-items-end">
                    <div class="form-group col-md-6">
                        <label for="selectStaff">Select Staff</label>
                        <div class="input-group">
                            <select
                                class="form-control"
                                formControlName="assigneeId"
                                id="selectStaff"
                                name="selectStaff"
                                style="appearance: auto"
                            >
                                <option
                                    *ngFor="let employee of users"
                                    [value]="employee._id"
                                >
                                    {{ employee.name }}
                                </option>
                            </select>
                            <input
                                (click)="addAssignee()"
                                class="btn btn-light px-3"
                                type="button"
                                value="+"
                            />
                        </div>
                    </div>
                    <div
                        class="col-md-6 d-flex gap-2 align-items-center overflow-auto"
                    >
                        @for (assignee of assignees; track assignee._id; ) {
                            <button
                                type="button"
                                class="px-2 py-1 btn btn-light d-flex gap-2 align-items-center"
                                style="white-space: nowrap; cursor: default"
                            >
                                <span>{{ assignee.name }}</span>
                                <span
                                    class="fs-6 px-1"
                                    style="cursor: pointer"
                                    (click)="removeAssignee(assignee._id)"
                                >&times;</span
                                ></button
                            >
                        }
                    </div>
                </div>
            </div>
            <button
                [disabled]="form.invalid || bookingLoading"
                class="btn btn-primary mt-4 d-flex gap-2 align-items-center float-end"
                type="submit"
            >
                @if (bookingLoading) {
                    <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                    ></span>
                    <span role="status">Loading...</span>
                } @else {
                    <span>{{ modalTitle }}</span>
                }
            </button>
        </form>
    </div>
</ng-template>

<!-- get Client Data modal  -->
<ng-template #clientContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Client Detail</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <div *ngIf="['admin', 'manager', 'super'].includes(currentUserRole)">
            <div class="form-group d-flex justify-content-between">
                <label for="phoneNumber"><b>Phone Number:</b></label>
                <p id="phoneNumber">{{ clientData?.client?.phoneNumber }}</p>
            </div>
        </div>
        <div class="form-group d-flex justify-content-between">
            <label for="address"><b>Name:</b></label>
            <p id="address">{{ clientData?.client?.name }}</p>
        </div>
        <div class="form-group d-flex justify-content-between">
            <label for="address"><b>Address:</b></label>
            <p id="address">{{ clientData?.client?.address }}</p>
        </div>
        <div *ngIf="['admin', 'manager', 'super'].includes(currentUserRole)">
            <button
                (click)="sendBookingConfirmation(clientData)"
                class="btn btn-primary float-end"
                type="submit"
            >
                Send Booking Confirmation Message
            </button>
        </div>
    </div>
    <div *ngIf="currentUserRole == 'employee'" class="status-container p-4">
        <!-- Booking Status -->
        <div class="status-section">
            <label for="booking-status">Booking Status:</label>
            <select
                (ngModelChange)="
                    currentUserRole === 'driver'
                        ? null
                        : onFlagChange($event, selectedBooking)
                "
                [(ngModel)]="selectedBooking.flag"
                [disabled]="currentUserRole === 'driver'"
                [ngClass]="{
                    'bg-pending': selectedBooking.flag === 'PENDING',
                    'bg-completed': selectedBooking.flag === 'COMPLETED',
                    'bg-cancelled': selectedBooking.flag === 'CANCELLED',
                    'bg-progress': selectedBooking.flag === 'IN-PROGRESS'
                }"
                class="paymentDropdown form-control p-4"
                id="booking-status"
            >
                <option value="PENDING">PENDING</option>
                <option value="COMPLETED">COMPLETED</option>
                <option value="CANCELLED">CANCELLED</option>
                <option value="IN-PROGRESS">IN-PROGRESS</option>
            </select>
        </div>
        <!-- Payment Status -->
        <div class="status-section">
            <label for="payment-status">Payment Status:</label>
            <select
                (ngModelChange)="
                    currentUserRole === 'driver'
                        ? null
                        : onPaymentStatusChange($event, selectedBooking)
                "
                [(ngModel)]="selectedBooking.paymentStatus"
                [disabled]="currentUserRole === 'driver'"
                [ngClass]="{
                    'bg-pending': selectedBooking.paymentStatus === 'DUE',
                    'bg-received': selectedBooking.paymentStatus === 'RECEIVED',
                    'bg-collected':
                        selectedBooking.paymentStatus ===
                        'COLLECTED BY ASSIGNEE'
                }"
                class="paymentDropdown form-control p4"
                id="payment-status"
            >
                <option value="DUE">DUE</option>
                <option *ngIf="currentUserRole !== 'employee'" value="RECEIVED">
                    RECEIVED
                </option>
                <option
                    *ngIf="currentUserRole !== 'employee'"
                    value="COLLECTED BY ASIGNEE"
                >
                    COLLECTED BY ASSIGNEE
                </option>
                <option
                    *ngIf="currentUserRole === 'employee'"
                    value="COLLECTED BY ME"
                >
                    COLLECTED BY ME
                </option>
            </select>
        </div>
    </div>
</ng-template>

<!-- get Employee Data modal  -->
<ng-template #employeeContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Employee Detail</h4>
        <button
            (click)="modal.dismiss('Cross click')"
            aria-label="Close"
            class="btn-close"
            type="button"
        ></button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="phoneNumber">Phone Number</label>
            <p id="phoneNumber">{{ employeeData.assignee.phoneNumber }}</p>
        </div>
        <div class="form-group">
            <label for="category">Category</label>
            <p id="category">
                {{ employeeData.assignee.category.categoryName }}
            </p>
        </div>
    </div>
</ng-template>
